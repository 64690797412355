

// components/ClientsList.js

import React, { useState, useMemo } from 'react';
import { IoChevronDownOutline } from 'react-icons/io5';
import styled from 'styled-components';
import SearchBar from '../ui/SearchBar';
import FilterBar from '../ui/FilterBar';
import ClientRow from '../ui/ClientRow';
import SideDrawer from '../SideDrawer';
import { sampleClients } from '../../data/sampleClients';
import Badge from '../ui/Badge';


// src/components/ClientsList.js


const Container = styled.div`
  padding: 40px;
  margin: 0 auto;
  min-height: 100vh;
`;

const Header = styled.h2`
  margin-bottom: 40px;
  text-align: center;
  color: #333333;
  font-size: 2rem;
`;

const NoClientsMessage = styled.p`
  text-align: center;
  color: #6c757d;
  font-size: 1.1rem;
`;

// Styled Components for Controls Layout
const ControlsContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const ClientsList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filters, setFilters] = useState(['Active', 'Inactive']);
  const [expandedClientId, setExpandedClientId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Filter options
  const filterOptions = [
    { label: 'Active', value: 'Active' },
    { label: 'Inactive', value: 'Inactive' },
    { label: 'Pending', value: 'Pending' },
    { label: 'Suspended', value: 'Suspended' },
    { label: 'Admin', value: 'Admin' },
    { label: 'User', value: 'User' },
  ];

  // Derived state: filtered clients based on search and filters
  const filteredClients = useMemo(() => {
    return sampleClients.filter((client) => {
      const matchesSearch = client.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            client.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                            client.fullName.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesFilter = filters.includes(client.status) || filters.includes(client.role);
      return matchesSearch && matchesFilter;
    });
  }, [searchQuery, filters]);

  const handleExpand = (clientId) => {
    setExpandedClientId((prevId) => (prevId === clientId ? null : clientId));
  };

  const handleUsernameClick = (client) => {
    setSelectedClient(client);
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedClient(null);
  };

  return (
    <Container>
      <Header>Clients List</Header>
      <ControlsContainer>
        <SearchBar
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <FilterBar
          filters={filters}
          setFilters={setFilters}
          options={filterOptions}
        />
      </ControlsContainer>
      {filteredClients.length > 0 ? (
        filteredClients.map((client) => (
          <ClientRow
            key={client.id}
            client={client}
            isExpanded={expandedClientId === client.id}
            onExpand={handleExpand}
            onUsernameClick={handleUsernameClick}
          />
        ))
      ) : (
        <NoClientsMessage>No clients found.</NoClientsMessage>
      )}
      <SideDrawer
        isopen={isDrawerOpen}
        onClose={handleCloseDrawer}
        width="40%"
        backgroundcolor="#1e1e1e" /* Dark side drawer for contrast */
      >
        {selectedClient && (
          <ClientDetails client={selectedClient} />
        )}
      </SideDrawer>
    </Container>
  );
};

// ClientDetails Component to display detailed information in SideDrawer
const ClientDetailsContainer = styled.div`
  padding: 30px;
  color: #ffffff;
`;

const Section = styled.div`
  margin-bottom: 30px;
`;

const SectionHeader = styled.h3`
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 1.5rem;
  border-bottom: 1px solid #ffffff;
  padding-bottom: 5px;
`;

const Detail = styled.p`
  margin: 8px 0;
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

const DetailIcon = styled.span`
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: #007BFF;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 20px;
  background-color: ${({ danger }) => (danger ? '#dc3545' : '#007bff')};
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ danger }) => (danger ? '#c82333' : '#0069d9')};
  }

  svg {
    margin-top: 2px;
  }
`;

// Styled Components for Avatar in ClientDetails
const AvatarDetails = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 12px;
  margin-bottom: 15px;
  border: 2px solid #007BFF;
  object-fit: cover;
`;

const ClientDetails = ({ client }) => {
  return (
    <ClientDetailsContainer>
      <Section>
        <SectionHeader>Basic Information</SectionHeader>
        <AvatarDetails src={client.avatarUrl} alt={`${client.fullName}'s avatar`} />
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Email:</strong> {client.email}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Phone:</strong> {client.contactInfo.phone}
        </Detail>
        {client.contactInfo.secondaryPhone && (
          <Detail>
            <DetailIcon>
              <IoChevronDownOutline size={16} />
            </DetailIcon>
            <strong>Secondary Phone:</strong> {client.contactInfo.secondaryPhone}
          </Detail>
        )}
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Address:</strong> {`${client.contactInfo.address.street}, ${client.contactInfo.address.city}, ${client.contactInfo.address.state} ${client.contactInfo.address.zipCode}, ${client.contactInfo.address.country}`}
        </Detail>
        {client.contactInfo.socialMedia && (
          <Detail>
            <DetailIcon>
              <IoChevronDownOutline size={16} />
            </DetailIcon>
            <strong>Social Media:</strong>
            {client.contactInfo.socialMedia.twitter && (
              <a href={client.contactInfo.socialMedia.twitter} target="_blank" rel="noopener noreferrer" style={{ color: '#1da1f2', marginRight: '10px' }}>
                Twitter
              </a>
            )}
            {client.contactInfo.socialMedia.linkedin && (
              <a href={client.contactInfo.socialMedia.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: '#0077b5', marginRight: '10px' }}>
                LinkedIn
              </a>
            )}
            {client.contactInfo.socialMedia.facebook && (
              <a href={client.contactInfo.socialMedia.facebook} target="_blank" rel="noopener noreferrer" style={{ color: '#4267B2', marginRight: '10px' }}>
                Facebook
              </a>
            )}
            {client.contactInfo.socialMedia.instagram && (
              <a href={client.contactInfo.socialMedia.instagram} target="_blank" rel="noopener noreferrer" style={{ color: '#C13584' }}>
                Instagram
              </a>
            )}
          </Detail>
        )}
      </Section>

      <Section>
        <SectionHeader>Account Information</SectionHeader>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Account ID:</strong> {client.accountDetails.accountId}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Created At:</strong> {new Date(client.accountDetails.createdAt).toLocaleString()}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Last Login:</strong> {new Date(client.accountDetails.lastLogin).toLocaleString()}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Subscription Type:</strong> {client.accountDetails.subscriptionType}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Subscription Status:</strong> {client.accountDetails.subscriptionStatus}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Billing Cycle:</strong> {client.accountDetails.billingInfo.billingCycle}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Payment Method:</strong> {client.accountDetails.billingInfo.paymentMethod}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Next Billing Date:</strong> {new Date(client.accountDetails.billingInfo.nextBillingDate).toLocaleDateString()}
        </Detail>
      </Section>

      <Section>
        <SectionHeader>User Preferences</SectionHeader>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Language:</strong> {client.preferences.language}
        </Detail>
        <Detail>
          <DetailIcon>
            <IoChevronDownOutline size={16} />
          </DetailIcon>
          <strong>Timezone:</strong> {client.preferences.timezone}
        </Detail>
        <Detail>
          <strong>Communication Preferences:</strong>
          <ul style={{ paddingLeft: '30px', margin: '5px 0' }}>
            <li>Email: {client.preferences.communication.email ? 'On' : 'Off'}</li>
            <li>SMS: {client.preferences.communication.sms ? 'On' : 'Off'}</li>
            <li>Push Notifications: {client.preferences.communication.pushNotifications ? 'On' : 'Off'}</li>
          </ul>
        </Detail>
      </Section>

      <Section>
        <SectionHeader>Activity Logs</SectionHeader>
        <ul style={{ paddingLeft: '30px', margin: '5px 0' }}>
          {client.activityLogs.map((log, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              <strong>{new Date(log.date).toLocaleDateString()}:</strong> {log.activity} {log.details && `- ${log.details}`}
            </li>
          ))}
        </ul>
      </Section>

      <Section>
        <SectionHeader>Notes</SectionHeader>
        <ul style={{ paddingLeft: '30px', margin: '5px 0' }}>
          {client.notes.map((note, index) => (
            <li key={index} style={{ marginBottom: '10px' }}>
              <strong>{new Date(note.createdAt).toLocaleDateString()} by {note.author}:</strong> {note.content}
            </li>
          ))}
        </ul>
      </Section>

      <Section>
        <SectionHeader>Statistics</SectionHeader>
        <Detail><strong>Total Orders:</strong> {client.totalOrders}</Detail>
        <Detail><strong>Total Spent:</strong> ${client.totalSpent.toFixed(2)}</Detail>
        <Detail><strong>Last Order Date:</strong> {client.lastOrderDate ? new Date(client.lastOrderDate).toLocaleDateString() : 'N/A'}</Detail>
      </Section>

      <Section>
        <SectionHeader>Tags</SectionHeader>
        {client.tags && client.tags.map((tag, index) => (
          <Badge key={index} text={tag} color="#17a2b8" />
        ))}
      </Section>

      <Section>
        <SectionHeader>Actions</SectionHeader>
        <ActionsContainer>
          <ActionButton>
            <IoChevronDownOutline size={20} /> Edit
          </ActionButton>
          <ActionButton danger>
            <IoChevronDownOutline size={20} /> Deactivate
          </ActionButton>
          <ActionButton>
            <IoChevronDownOutline size={20} /> Send Message
          </ActionButton>
          <ActionButton danger>
            <IoChevronDownOutline size={20} /> Delete
          </ActionButton>
        </ActionsContainer>
      </Section>
    </ClientDetailsContainer>
  );
};

export default ClientsList;
