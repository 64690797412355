import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

const DrawerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 85vh; 
  background: ${props => props.gradient || 'white'};
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.2);
  border-top-left-radius: ${props => props.borderRadius || '16px'};
  border-top-right-radius: ${props => props.borderRadius || '16px'};
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(100%)'};
  transition: transform 0.3s ease;
  z-index: ${props => props.zIndex || 100};
  display: flex;
  flex-direction: column;
  
`;

const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.headerPadding || '16px'};
  background: ${props => props.gradient || 'linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%)'};
  color: white;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  h2 {
    color: white;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;



const DrawerContent = styled.div`
  position: relative;
  height: calc(100vh - 80px);  // Adjust for header height
  overflow: hidden;  // Prevent drawer itself from scrolling
  background: ${props => props.backgroundColor || '#1a1a1a'};
  
  // Remove default padding to give ProgramInterface full control
  padding: 0;
  
  // Remove scrolling behavior from the drawer content
  overflow-x: hidden;
  overflow-y: hidden;
  
  // Override any inherited scroll behavior
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;
`;



const BottomDrawer = ({
    isOpen,
    onClose,
    children,
    header,
    backgroundColor,
    borderRadius,
    zIndex,
    headerPadding,
    contentPadding,
    maxHeight,
    className,
    gradient,
    disableClickOutside
}) => {
    const drawerRef = useRef(null);
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (disableClickOutside) return; // Don't handle clicks if disabled
            if (drawerRef.current && !drawerRef.current.contains(event.target) && isOpen) {
                onClose();
            }
        };
    
        let timeoutId;
        if (isOpen) {
            // Delay adding the event listener until next tick
            timeoutId = setTimeout(() => {
                document.addEventListener('mousedown', handleClickOutside);
            }, 0);
        }
    
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            if (timeoutId) clearTimeout(timeoutId);
        };
    }, [isOpen, onClose, disableClickOutside ]);

    return (
        <DrawerContainer
            ref={drawerRef}
            isOpen={isOpen}
           
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            zIndex={zIndex}
            className={className}
        >
            <DrawerHeader 
            headerPadding={headerPadding}
            gradient={gradient}
            >
                {header}
            </DrawerHeader>
            <DrawerContent
                contentPadding={contentPadding}
                maxHeight={maxHeight}
            >
                {children}
            </DrawerContent>
        </DrawerContainer>
    );
};

export default BottomDrawer;



