import React, { useState } from "react";
import styled from "styled-components";
import Sidebar from "./Sidebar";
import PopupMenu from "./PopupMenu";
import CollapseButton from "./CollapseButton ";



const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh; /* Ensures full-height layout */
  overflow: hidden;
`;



const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative; // Add this for absolute positioning of sidebar on mobile

  @media (max-width: 768px) {
    ${props => props.collapsed && `
      .sidebar {
        transform: translateX(-100%);
      }
    `}
  }
`;

const MainContent = styled.main`
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: #f5f5f5;
  padding-top: 4rem; // Add space for the collapse button

  @media (max-width: 768px) {
    width: 100%;
  }
`;


const Layout = ({ links, children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const handleFooterClick = () => {
        setShowPopup(!showPopup);
    };

    const toggleSidebar = () => {
        setCollapsed(!collapsed);
      };

      const handleContentClick = () => {
        if (window.innerWidth <= 768 && !collapsed) {
            setCollapsed(true);
        }
    };

      return (
        <LayoutWrapper>
        <ContentWrapper collapsed={collapsed}>
            <Sidebar
                className="sidebar"
                links={links}
                onFooterClick={handleFooterClick}
                collapsed={collapsed}
                onSidebarCollapse={setCollapsed}
            />
            <CollapseButton 
                collapsed={collapsed} 
                onClick={toggleSidebar}
                sidebarWidth={collapsed ? "80px" : "250px"}
            />
            <MainContent onClick={handleContentClick}>
                {children}
            </MainContent>
        </ContentWrapper>
        {showPopup && (
            <PopupMenu collapsed={false} setShowPopup={setShowPopup} />
        )}
    </LayoutWrapper>
);
};

export default Layout;
