// src/components/TrainerPortal/wizard-steps/ReviewStep.js
import React from 'react';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
`;

const Section = styled.div`
  margin-bottom: 2rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 8px;

  h3 {
    color: #333;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
  }
`;

const DetailRow = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  align-items: ${props => props.alignTop ? 'flex-start' : 'center'};

  strong {
    width: 200px;
    color: #666;
  }
`;

const Tag = styled.span`
  background: #e8f5e9;
  color: #2e7d32;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  margin-right: 0.5rem;
  font-size: 0.9rem;
`;

const PriceTag = styled.div`
  display: inline-flex;
  align-items: center;
  background: #e3f2fd;
  color: #1976d2;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  font-weight: 500;
`;

const PriceNote = styled.span`
  font-size: 0.875rem;
  color: #666;
  margin-left: 0.5rem;
  font-style: italic;
`;

const ReviewStep = ({ data }) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(price || 0);
  };

  const formatTimeCommitment = (timeCommitment) => {
    if (!timeCommitment) return 'Not specified';
    return `${timeCommitment.sessionsPerWeekMin}-${timeCommitment.sessionsPerWeekMax} sessions/week, ` +
           `${timeCommitment.minutesPerSessionMin}-${timeCommitment.minutesPerSessionMax} minutes/session`;
  };

  return (
    <StepContainer>
      <Title>Review Program</Title>

      <Section>
        <h3>Basic Information</h3>
        <DetailRow>
          <strong>Program Name:</strong>
          <span>{data?.name || 'Not specified'}</span>
        </DetailRow>
        <DetailRow>
          <strong>Environment:</strong>
          <span>{data?.environment || 'Not specified'}</span>
        </DetailRow>
        <DetailRow>
          <strong>Type:</strong>
          <span>{data?.type || 'Not specified'}</span>
        </DetailRow>
        <DetailRow alignTop>
          <strong>Description:</strong>
          <span>{data?.description || 'No description provided'}</span>
        </DetailRow>
      </Section>

      <Section>
        <h3>Program Requirements & Focus</h3>
        <DetailRow>
          <strong>Difficulty Level:</strong>
          <span style={{ textTransform: 'capitalize' }}>{data?.metadata?.difficulty || 'Not specified'}</span>
        </DetailRow>
        <DetailRow>
          <strong>Time Commitment:</strong>
          <span>{formatTimeCommitment(data?.metadata?.timeCommitment)}</span>
        </DetailRow>
        <DetailRow>
          <strong>Equipment Required:</strong>
          <div>
            {data?.metadata?.equipment?.length > 0 ? (
              data.metadata.equipment.map((item, index) => (
                <Tag key={index}>{item}</Tag>
              ))
            ) : (
              <span>None specified</span>
            )}
          </div>
        </DetailRow>
        <DetailRow>
          <strong>Experience Requirements:</strong>
          <div>
            {data?.metadata?.experienceRequirements?.length > 0 ? (
              data.metadata.experienceRequirements.map((req, index) => (
                <Tag key={index}>{req}</Tag>
              ))
            ) : (
              <span>None specified</span>
            )}
          </div>
        </DetailRow>
      </Section>

      <Section>
        <h3>Program Focus & Target Audience</h3>
        <DetailRow>
          <strong>Program Focus:</strong>
          <div>
            {data?.metadata?.programFocus?.length > 0 ? (
              data.metadata.programFocus.map((focus, index) => (
                <Tag key={index}>{focus}</Tag>
              ))
            ) : (
              <span>Not specified</span>
            )}
          </div>
        </DetailRow>
        <DetailRow>
          <strong>Target Audience:</strong>
          <div>
            {data?.metadata?.targetAudience?.length > 0 ? (
              data.metadata.targetAudience.map((audience, index) => (
                <Tag key={index}>{audience}</Tag>
              ))
            ) : (
              <span>None specified</span>
            )}
          </div>
        </DetailRow>
      </Section>

      <Section>
        <h3>Program Structure & Pricing</h3>
        <DetailRow>
          <strong>Duration Type:</strong>
          <span style={{ textTransform: 'capitalize' }}>{data?.durationType || 'Not specified'}</span>
        </DetailRow>
        <DetailRow>
          <strong>Price:</strong>
          <div>
            <PriceTag>{formatPrice(data?.price)}</PriceTag>
            <PriceNote>
              {data?.durationType === 'fixed' 
                ? 'One-time payment for full program access'
                : 'Monthly subscription fee'}
            </PriceNote>
          </div>
        </DetailRow>
        {data?.durationType === 'fixed' && (
          <DetailRow>
            <strong>Total Cycles:</strong>
            <span>{data?.totalCycles || 0}</span>
          </DetailRow>
        )}
        <DetailRow>
          <strong>Days per Cycle:</strong>
          <span>{data?.defaultCycleLengthDays || 0}</span>
        </DetailRow>
      </Section>
    </StepContainer>
  );
};

export default ReviewStep;