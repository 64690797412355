import React, { useState } from 'react';
import styled from 'styled-components';

const FormContainer = styled.div`
  color: white;
  padding: 20px 0;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  color: #ccc;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px 12px;
  background: #3a3a3a;
  border: 1px solid #4a4a4a;
  border-radius: 4px;
  color: white;
  margin-bottom: 8px;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const Button = styled.button`
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  background: ${props => props.primary ? '#4CAF50' : '#3a3a3a'};
  color: white;

  &:hover {
    background: ${props => props.primary ? '#45a049' : '#4a4a4a'};
  }
`;

const ExerciseAttributeForm = ({ type, mode, onComplete, onBack }) => {
  const [attributes, setAttributes] = useState({
    value: '', // exercise name
    sets: [getInitialSet(mode)]
  });

  function getInitialSet(mode) {
    switch (mode) {
      case 'weights':
        return { reps: '', weight: '', weightUnit: 'lbs' };
      case 'cardio':
        return { duration: '', distance: '', intensity: '' };
      case 'calisthenics':
        return { reps: '', duration: '', intensity: '' };
      default:
        return {};
    }
  }

  const renderModeSpecificFields = (setIndex) => {
    switch (mode) {
      case 'weights':
        return (
          <>
            <InputGroup>
              <Label>Reps</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].reps}
                onChange={(e) => handleSetChange(setIndex, 'reps', e.target.value)}
                placeholder="Number of reps"
              />
            </InputGroup>
            <InputGroup>
              <Label>Weight</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].weight}
                onChange={(e) => handleSetChange(setIndex, 'weight', e.target.value)}
                placeholder="Weight amount"
              />
            </InputGroup>
          </>
        );

      case 'cardio':
        return (
          <>
            <InputGroup>
              <Label>Duration (minutes)</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].duration}
                onChange={(e) => handleSetChange(setIndex, 'duration', e.target.value)}
                placeholder="Duration in minutes"
              />
            </InputGroup>
            <InputGroup>
              <Label>Distance</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].distance}
                onChange={(e) => handleSetChange(setIndex, 'distance', e.target.value)}
                placeholder="Distance"
              />
            </InputGroup>
            <InputGroup>
              <Label>Intensity (%)</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].intensity}
                onChange={(e) => handleSetChange(setIndex, 'intensity', e.target.value)}
                placeholder="Intensity percentage"
                min="0"
                max="100"
              />
            </InputGroup>
          </>
        );

      case 'calisthenics':
        return (
          <>
            <InputGroup>
              <Label>Reps</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].reps}
                onChange={(e) => handleSetChange(setIndex, 'reps', e.target.value)}
                placeholder="Number of reps"
              />
            </InputGroup>
            <InputGroup>
              <Label>Duration (seconds)</Label>
              <Input
                type="number"
                value={attributes.sets[setIndex].duration}
                onChange={(e) => handleSetChange(setIndex, 'duration', e.target.value)}
                placeholder="Duration in seconds"
              />
            </InputGroup>
          </>
        );

      default:
        return null;
    }
  };

  const handleSetChange = (setIndex, field, value) => {
    const newSets = [...attributes.sets];
    newSets[setIndex] = { ...newSets[setIndex], [field]: value };
    setAttributes({ ...attributes, sets: newSets });
  };

  const handleSubmit = () => {
    onComplete(attributes);
  };

  return (
    <FormContainer>
      <InputGroup>
        <Label>Exercise Name</Label>
        <Input
          type="text"
          value={attributes.value}
          onChange={(e) => setAttributes({ ...attributes, value: e.target.value })}
          placeholder="Enter exercise name"
        />
      </InputGroup>

      {attributes.sets.map((set, index) => (
        <div key={index}>
          <h4>Set {index + 1}</h4>
          {renderModeSpecificFields(index)}
        </div>
      ))}

      <Button
        onClick={() => setAttributes({
          ...attributes,
          sets: [...attributes.sets, getInitialSet(mode)]
        })}
      >
        Add Set
      </Button>

      <ButtonGroup>
        <Button onClick={onBack}>Back</Button>
        <Button primary onClick={handleSubmit}>Complete</Button>
      </ButtonGroup>
    </FormContainer>
  );
};

export default ExerciseAttributeForm;