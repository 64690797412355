// src/components/AdvertiserPortal/Campaigns.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaPlus, FaSearch, FaFilter, FaPause, FaPlay, 
         FaCopy, FaTrash, FaEllipsisV, FaTag } from 'react-icons/fa';

const CampaignsContainer = styled.div`
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  background: ${props => props.variant === 'outlined' ? 'transparent' : '#4caf50'};
  color: ${props => props.variant === 'outlined' ? '#4caf50' : 'white'};
  border: ${props => props.variant === 'outlined' ? '1px solid #4caf50' : 'none'};
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.variant === 'outlined' ? '#e8f5e9' : '#43a047'};
  }
`;

const FilterBar = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const SearchInput = styled.div`
  flex: 1;
  max-width: 400px;
  position: relative;

  input {
    width: 100%;
    padding: 0.75rem 1rem 0.75rem 2.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;
  }

  svg {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const CampaignsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

const CampaignCard = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;

  .campaign-header {
    padding: 1.5rem;
    position: relative;
  }

  .campaign-status {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    padding: 0.25rem 0.75rem;
    border-radius: 999px;
    font-size: 0.75rem;
    background: ${props => props.status === 'active' ? '#e8f5e9' : '#f5f5f5'};
    color: ${props => props.status === 'active' ? '#4caf50' : '#666'};
  }

  .campaign-title {
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .campaign-dates {
    font-size: 0.875rem;
    color: #666;
  }

  .campaign-metrics {
    padding: 1rem 1.5rem;
    background: #f8f9fa;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    text-align: center;

    .metric {
      .value {
        font-weight: 600;
        color: #333;
      }
      .label {
        font-size: 0.75rem;
        color: #666;
        margin-top: 0.25rem;
      }
    }
  }

  .campaign-actions {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action-buttons {
      display: flex;
      gap: 0.5rem;
    }
  }
`;

const ActionButton = styled.button`
  padding: 0.5rem;
  border: none;
  background: none;
  color: #666;
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: ${props => props.danger ? '#f44336' : '#4caf50'};
  }
`;

const Campaigns = () => {
  const [filterStatus, setFilterStatus] = useState('all');

  const mockCampaigns = [
    {
      id: 1,
      title: 'Summer Fitness Challenge',
      status: 'active',
      dateRange: 'Jun 1 - Jul 31',
      metrics: {
        impressions: '28.5K',
        clicks: '1.2K',
        conversions: '45'
      }
    },
    // Add more mock campaigns...
  ];

  return (
    <CampaignsContainer>
      <Header>
        <h2>Campaigns</h2>
        <Button>
          <FaPlus />
          Create Campaign
        </Button>
      </Header>

      <FilterBar>
        <SearchInput>
          <FaSearch />
          <input type="text" placeholder="Search campaigns..." />
        </SearchInput>
        
        <Button variant="outlined">
          <FaFilter />
          Filter
        </Button>
        
        <Button variant="outlined">
          <FaTag />
          Status: All
        </Button>
      </FilterBar>

      <CampaignsGrid>
        {mockCampaigns.map(campaign => (
          <CampaignCard key={campaign.id} status={campaign.status}>
            <div className="campaign-header">
              <div className="campaign-status">
                {campaign.status === 'active' ? 'Active' : 'Paused'}
              </div>
              <h3 className="campaign-title">{campaign.title}</h3>
              <div className="campaign-dates">{campaign.dateRange}</div>
            </div>

            <div className="campaign-metrics">
              <div className="metric">
                <div className="value">{campaign.metrics.impressions}</div>
                <div className="label">Impressions</div>
              </div>
              <div className="metric">
                <div className="value">{campaign.metrics.clicks}</div>
                <div className="label">Clicks</div>
              </div>
              <div className="metric">
                <div className="value">{campaign.metrics.conversions}</div>
                <div className="label">Conversions</div>
              </div>
            </div>

            <div className="campaign-actions">
              <div className="action-buttons">
                <ActionButton>
                  {campaign.status === 'active' ? <FaPause /> : <FaPlay />}
                </ActionButton>
                <ActionButton>
                  <FaCopy />
                </ActionButton>
                <ActionButton danger>
                  <FaTrash />
                </ActionButton>
              </div>
              <ActionButton>
                <FaEllipsisV />
              </ActionButton>
            </div>
          </CampaignCard>
        ))}
      </CampaignsGrid>
    </CampaignsContainer>
  );
};

export default Campaigns;