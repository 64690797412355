// src/components/TrainerPortal/wizard-steps/ProgramEnvironmentStep.js
import React from 'react';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

const OptionCard = styled.div`
  border: 2px solid ${props => props.selected ? '#4caf50' : '#eee'};
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  text-align: center;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.selected ? '#4caf50' : '#ddd'};
    background: ${props => props.selected ? '#f5f5f5' : 'white'};
  }

  h3 {
    margin-bottom: 0.5rem;
    color: #333;
  }

  p {
    color: #666;
    font-size: 0.9rem;
  }
`;

const ProgramEnvironmentStep = ({ data, onNext }) => {
  const handleSelect = (environment) => {
    onNext({ environment });
  };

  return (
    <StepContainer>
      <Title>Select Program Environment</Title>
      <OptionsGrid>
        <OptionCard 
          selected={data.environment === 'online'}
          onClick={() => handleSelect('online')}
        >
          <h3>Online</h3>
          <p>Program delivered entirely through the platform</p>
        </OptionCard>

        <OptionCard 
          selected={data.environment === 'in_person'}
          onClick={() => handleSelect('in_person')}
        >
          <h3>In-Person</h3>
          <p>Face-to-face training sessions required</p>
        </OptionCard>

        <OptionCard 
          selected={data.environment === 'hybrid'}
          onClick={() => handleSelect('hybrid')}
        >
          <h3>Hybrid</h3>
          <p>Mix of online and in-person sessions</p>
        </OptionCard>
      </OptionsGrid>
    </StepContainer>
  );
};

export default ProgramEnvironmentStep;