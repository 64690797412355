import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaPlus } from 'react-icons/fa';
import ProgramCard from './ProgramCard';
import ProgramCreationWizard from '../ProgramCreationWizard';
import { 
  fetchUserPrograms, 
  deleteProgram,
  createProgram,
  selectAllPrograms 
} from '../../../store/features/programs/programsSlice';

const ProgramsContainer = styled.div`
  padding: 2rem;
  max-width: 1440px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

const SearchAndFilters = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SearchInput = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  font-size: 0.875rem;

  &:focus {
    outline: none;
    border-color: #4CAF50;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 2rem;
`;

const CreateButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background: #4CAF50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;

  &:hover {
    background: #388E3C;
  }

  &:disabled {
    background: #9e9e9e;
    cursor: not-allowed;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 3rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  h3 {
    margin-bottom: 1rem;
    color: #333;
  }

  p {
    color: #666;
    margin-bottom: 2rem;
  }
`;

const ProgramsList = () => {
  const dispatch = useDispatch();
  const programs = useSelector(selectAllPrograms) || [];
  const isLoading = useSelector(state => state.programs.isUpdating);
  const [searchTerm, setSearchTerm] = useState('');
  const [isWizardOpen, setIsWizardOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchUserPrograms());
  }, [dispatch]);

  const handleCreateProgram = () => {
    setIsWizardOpen(true);
  };

  const handleWizardClose = () => {
    setIsWizardOpen(false);
  };

  const handleProgramCreate = async (programData) => {
    try {
      await dispatch(createProgram(programData)).unwrap();
      setIsWizardOpen(false);
      dispatch(fetchUserPrograms());
    } catch (error) {
      console.error('Failed to create program:', error);
    }
  };

  const handleEditProgram = (program) => {
    // Navigate to edit page or open edit modal
  };

  const handleDeleteProgram = async (program) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      await dispatch(deleteProgram(program.id));
    }
  };

  const handleViewProgram = (program) => {
    // Navigate to program details page
  };

  const filteredPrograms = programs.filter(program =>
    program?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    program?.description?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <ProgramsContainer>
      <Header>
        <h1>My Programs</h1>
        <CreateButton onClick={handleCreateProgram} disabled={isLoading}>
          <FaPlus />
          Create New Program
        </CreateButton>
      </Header>

      <SearchAndFilters>
        <SearchInput
          type="text"
          placeholder="Search programs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </SearchAndFilters>

      {programs.length === 0 && !searchTerm ? (
        <EmptyState>
          <h3>No Programs Yet</h3>
          <p>Start by creating your first training program</p>
          <CreateButton onClick={handleCreateProgram} disabled={isLoading}>
            <FaPlus />
            Create New Program
          </CreateButton>
        </EmptyState>
      ) : (
        <ProgramsGrid>
          {filteredPrograms.map(program => (
            <ProgramCard
              key={program.id}
              program={program}
              onEdit={handleEditProgram}
              onDelete={handleDeleteProgram}
              onView={handleViewProgram}
            />
          ))}
          {filteredPrograms.length === 0 && searchTerm && (
            <EmptyState>
              <h3>No matching programs found</h3>
              <p>Try adjusting your search terms</p>
            </EmptyState>
          )}
        </ProgramsGrid>
      )}

      <ProgramCreationWizard
        open={isWizardOpen}
        onClose={handleWizardClose}
        onSubmit={handleProgramCreate}
      />
    </ProgramsContainer>
  );
};

export default ProgramsList;