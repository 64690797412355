import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
    sendOTP,
    verifyOTP,
    signupUser,
    clearError,
    checkUsername,
    resetPassword,
} from "../../../store/features/auth/authSlice";

// Reuse your existing styled components pattern
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e2130;
  padding: 2rem;
  border-radius: 10px;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 12px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #3a3f5c;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2a2d3e;
  color: #ffffff;
  outline: none;

  &:focus {
    border-color: #bb00ff;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  width: 100%;
  max-width: 350px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #bb00ff;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    background-color: #d400ff;
  }

  &:disabled {
    background-color: #666;
    cursor: not-allowed;
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
  
  &:before {
    content: "←";
    margin-right: 8px;
  }

  &:hover {
    color: #fff;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4d4d;
  font-size: 14px;
  margin-bottom: 10px;
`;

const InfoText = styled.p`
  margin-bottom: 10px;
  color: #a0a0a0;
`;

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #bb00ff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SuccessMessage = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  animation: fadeIn 0.3s ease-in;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const PasswordStrengthIndicator = styled.div`
  width: 100%;
  max-width: 350px;
  height: 4px;
  background-color: ${props => {
        if (props.strength === 'strong') return '#4CAF50';
        if (props.strength === 'medium') return '#FFC107';
        return '#FF5252';
    }};
  transition: background-color 0.3s ease;
  margin-top: 5px;
`;

const ResetPassword = ({ onClose, onBack }) => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [localLoading, setLocalLoading] = useState(false);
    const [localError, setLocalError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const dispatch = useDispatch();
    const { error } = useSelector(state => state.auth);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };



    useEffect(() => {
        dispatch(clearError());
        setLocalError("");
    }, [step, dispatch]);

    const validatePassword = (password) => {
        const checks = {
            length: password.length >= 8,
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            number: /\d/.test(password)
        };

        const isValid = Object.values(checks).every(Boolean);
        const checksCount = Object.values(checks).filter(Boolean).length;

        let strength;
        if (checksCount === 4) strength = 'strong';
        else if (checksCount >= 3) strength = 'medium';
        else strength = 'weak';

        const errors = [];
        if (!checks.length) errors.push('At least 8 characters');
        if (!checks.uppercase) errors.push('One uppercase letter');
        if (!checks.lowercase) errors.push('One lowercase letter');
        if (!checks.number) errors.push('One number');

        return {
            isValid,
            checks,
            strength,
            errors
        };
    };



    useEffect(() => {
        if (!newPassword) {
            setLocalError("");
            return;
        }

        const validation = validatePassword(newPassword);

        if (!validation.isValid) {
            setLocalError(`Password needs: ${validation.errors.join(', ')}`);
        } else if (confirmPassword && newPassword !== confirmPassword) {
            setLocalError("Passwords don't match");
        } else {
            setLocalError("");
        }
    }, [newPassword, confirmPassword]);

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setLocalError("Please enter a valid email address");
            return;
        }

        try {
            setLocalLoading(true);
            setLocalError("");
            await dispatch(sendOTP(email)).unwrap();
            setSuccessMessage("Verification code sent successfully!");
            setTimeout(() => {
                setSuccessMessage("");
                setStep(2);
            }, 1500);
        } catch (error) {
            setLocalError(error.message || "Failed to send verification code");
        } finally {
            setLocalLoading(false);
        }
    };

    const handleVerifyCode = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(
                verifyOTP({
                    email,
                    code: verificationCode
                })
            ).unwrap();

            if (result.verified) {
                setSuccessMessage("Code verified successfully!");
                setTimeout(() => {
                    setSuccessMessage("");
                    setStep(3);
                }, 1500);
            }
        } catch (error) {
            setLocalError(error.message || "Failed to verify code");
        }
    };

    const handleResendOTP = async () => {
        try {
            setLocalLoading(true);
            await dispatch(sendOTP(email)).unwrap();
            setSuccessMessage("New code sent successfully!");
            setTimeout(() => setSuccessMessage(""), 1500);
        } catch (error) {
            setLocalError(error.message || "Failed to resend code");
        } finally {
            setLocalLoading(false);
        }
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
    
        if (newPassword !== confirmPassword) {
            setLocalError("Passwords don't match");
            return;
        }
    
        const validation = validatePassword(newPassword);
        if (!validation.isValid) return;
    
        try {
            setLocalLoading(true);
            
            // Actually dispatch the reset password action
            await dispatch(resetPassword({
                email,
                code: verificationCode,
                newPassword
            })).unwrap();
    
            setSuccessMessage("Password reset successfully!");
            setTimeout(() => onBack(), 1500);
        } catch (error) {
            setLocalError(error.message || "Failed to reset password");
        } finally {
            setLocalLoading(false);
        }
    };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                        <Title>Reset Password</Title>
                        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                        <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={localLoading}
                            required
                        />
                        <Button onClick={handleEmailSubmit} disabled={localLoading}>
                            {localLoading ? (
                                <>
                                    Sending... <LoadingSpinner />
                                </>
                            ) : "Send Reset Code"}
                        </Button>
                    </>
                );

            case 2:
                return (
                    <>
                        <Title>Verify Code</Title>
                        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                        <InfoText>Please enter the verification code sent to {email}</InfoText>
                        <Input
                            type="text"
                            placeholder="Enter verification code"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
                            maxLength="6"
                            disabled={localLoading}
                            required
                        />
                        <Button onClick={handleVerifyCode} disabled={localLoading || verificationCode.length !== 6}>
                            {localLoading ? (
                                <>
                                    Verifying... <LoadingSpinner />
                                </>
                            ) : "Verify Code"}
                        </Button>
                        <Button
                            onClick={handleResendOTP}
                            disabled={localLoading}
                            style={{ marginTop: '10px', backgroundColor: '#4A5568' }}
                        >
                            Resend Code
                        </Button>
                    </>
                );

            case 3:
                return (
                    <>
                        <Title>New Password</Title>
                        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
                        <Input
                            type="password"
                            placeholder="New Password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            disabled={localLoading}
                            required
                        />
                        {newPassword && (
                            <PasswordStrengthIndicator
                                strength={validatePassword(newPassword).strength}
                            />
                        )}
                        <Input
                            type="password"
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            disabled={localLoading}
                            required
                        />
                        <Button
                            onClick={handlePasswordReset}
                            disabled={localLoading || !newPassword || !confirmPassword || localError}
                        >
                            {localLoading ? (
                                <>
                                    Updating... <LoadingSpinner />
                                </>
                            ) : "Update Password"}
                        </Button>
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <StyledForm onSubmit={(e) => e.preventDefault()}>
            {error || localError ? (
                <ErrorMessage>{error || localError}</ErrorMessage>
            ) : null}
            {renderStepContent()}
        </StyledForm>
    );
};

export default ResetPassword;