import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { 
  cancelSubscription, 
  upgradeSubscription, 
} from '../../store/features/subscriptions/subscriptionSlice';
import { 
    FaCrown, 
    FaDumbbell, 
    FaClock, 
    FaArrowRight, 
    FaRegTimesCircle,
    FaGift,
    FaChartLine,
    FaStore,
    FaUsers,
    FaCheckCircle,
    FaBrain,
    FaRunning,
    FaShareAlt,
    FaUserFriends,
    FaBullseye,
    FaTrophy,
    FaChartBar,
    FaToolbox,
    FaRegBuilding
  } from 'react-icons/fa';
import Modal from '../ui/Modal';
import Card from '../ui/Card';










const FeatureItem = ({ icon: Icon, title, description }) => (
    <div className="flex items-start gap-4 p-4 rounded-lg hover:bg-gray-50 transition-colors">
      <div className="flex-shrink-0 mt-1">
        <Icon className="text-orange-500 text-xl" />
      </div>
      <div>
        <h4 className="font-semibold text-gray-900 mb-1">{title}</h4>
        <p className="text-gray-600 text-sm">{description}</p>
      </div>
    </div>
  );

const SubscriptionManagement = ({ subscription }) => {
  const dispatch = useDispatch();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const isMemberTier = subscription.tier === 'member';
  const nextBillingDate = new Date(subscription.nextBillingDate).toLocaleDateString();
  const referralCode = 'ROSHI' + Math.random().toString(36).substr(2, 6).toUpperCase();

  const handleUpgrade = async () => {
    try {
      setLoading(true);
      await dispatch(upgradeSubscription('trainer')).unwrap();
      setShowUpgradeModal(false);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const memberFeatures = [
    {
      icon: FaRunning,
      title: "Unlimited Access",
      description: "Access all training programs and features without restrictions"
    },
    {
      icon: FaDumbbell,
      title: "Personalized Training",
      description: "Build and customize your own training programs"
    },
    {
      icon: FaShareAlt,
      title: "Share with Friends",
      description: "Share your workouts and progress with your network"
    },
    {
      icon: FaChartLine,
      title: "Progress Tracking",
      description: "Track your fitness journey with detailed metrics"
    },
    {
      icon: FaChartBar,
      title: "Analytics Dashboard",
      description: "View detailed analytics of your performance"
    },
    {
      icon: FaUserFriends,
      title: "Community Engagement",
      description: "Connect and engage with fellow fitness enthusiasts"
    },
    {
      icon: FaBullseye,
      title: "Goal Setting",
      description: "Set and track your fitness goals"
    },
    {
      icon: FaTrophy,
      title: "Achievements",
      description: "Post and celebrate your fitness milestones"
    },
    {
      icon: FaStore,
      title: "Marketplace Access",
      description: "Purchase premium training programs from top trainers"
    },
    {
      icon: FaBrain,
      title: "AI Nutritional Analysis",
      description: "Get personalized nutritional insights powered by AI"
    }
  ];

  const trainerFeatures = [
    {
      icon: FaCrown,
      title: "Member Features",
      description: "Access to all features included in the member subscription"
    },
    {
      icon: FaToolbox,
      title: "Program Builder",
      description: "Create and sell professional training programs"
    },
    {
      icon: FaRegBuilding,
      title: "Trainer Portal",
      description: "Dedicated portal to manage your training business"
    },
    {
      icon: FaUsers,
      title: "Client Management",
      description: "Comprehensive tools to manage and track your clients"
    },
    {
      icon: FaChartLine,
      title: "Business Analytics",
      description: "Advanced analytics to grow your training business"
    },
    {
      icon: FaStore,
      title: "Marketplace Seller",
      description: "Sell your programs in our marketplace"
    }
  ];

  return (
    <div className="max-w-6xl mx-auto p-8">
      {/* Welcome Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-orange-500 to-orange-600 text-transparent bg-clip-text">
          Welcome to Your {isMemberTier ? 'Member' : 'Trainer'} Journey!
        </h1>
        <p className="text-gray-600 max-w-2xl mx-auto">
          {isMemberTier 
            ? "You're now part of an elite community of fitness enthusiasts. Let's achieve your goals together!"
            : "You're now equipped with the tools to transform lives. Let's build your fitness business together!"}
        </p>
      </div>




      {/* Current Plan Card */}
      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8 border-2 border-orange-500">
        <div className="flex items-center gap-6 mb-6">
          {isMemberTier ? (
            <FaCrown className="text-5xl text-orange-500" />
          ) : (
            <FaDumbbell className="text-5xl text-orange-500" />
          )}
          <div>
            <h2 className="text-3xl font-bold mb-2">
              {isMemberTier ? 'Member Plan' : 'Trainer Plan'}
            </h2>
            <p className="text-2xl font-semibold text-orange-500">
              ${isMemberTier ? '8.99' : '29.99'}/month
            </p>
          </div>
        </div>

        <div className="flex items-center gap-3 text-gray-600 mb-6">
          <FaClock className="text-xl" />
          <span>Next billing date: {nextBillingDate}</span>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-8">
          {isMemberTier ? (
            <>
              <div className="flex items-center gap-3">
                <FaChartLine className="text-orange-500" />
                <span>Advanced Analytics Active</span>
              </div>
              <div className="flex items-center gap-3">
                <FaStore className="text-orange-500" />
                <span>Marketplace Access Enabled</span>
              </div>
            </>
          ) : (
            <>
              <div className="flex items-center gap-3">
                <FaStore className="text-orange-500" />
                <span>Program Sales Enabled</span>
              </div>
              <div className="flex items-center gap-3">
                <FaUsers className="text-orange-500" />
                <span>Client Management Active</span>
              </div>
            </>
          )}
        </div>
      </div>
      

      <div className="bg-white rounded-2xl shadow-xl p-8 mb-8">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-bold">Your Subscription Includes</h3>
          <p className="text-gray-600 mt-2">
            {isMemberTier 
              ? "Everything you need for your fitness journey"
              : "All the tools you need to grow your training business"}
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-6">
          {(isMemberTier ? memberFeatures : trainerFeatures).map((feature, index) => (
            <FeatureItem
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>

      {/* Upsell or Referral Section */}
      <div className="bg-gray-900 rounded-2xl p-8 text-white">
        {isMemberTier ? (
          <>
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold mb-4">Ready to Take Your Fitness Journey Further?</h3>
              <p className="text-gray-300">Upgrade to our Trainer plan and start earning while helping others achieve their fitness goals.</p>
            </div>
            <div className="grid grid-cols-3 gap-6 mb-8">
              <div className="text-center p-4 bg-gray-800 rounded-lg">
                <FaStore className="text-3xl text-orange-500 mx-auto mb-3" />
                <p>Sell Your Programs</p>
              </div>
              <div className="text-center p-4 bg-gray-800 rounded-lg">
                <FaUsers className="text-3xl text-orange-500 mx-auto mb-3" />
                <p>Manage Clients</p>
              </div>
              <div className="text-center p-4 bg-gray-800 rounded-lg">
                <FaChartLine className="text-3xl text-orange-500 mx-auto mb-3" />
                <p>Business Analytics</p>
              </div>
            </div>
            <button
              onClick={() => setShowUpgradeModal(true)}
              className="w-full bg-orange-500 text-white py-4 rounded-xl font-semibold flex items-center justify-center gap-2 hover:bg-orange-600 transition-all"
            >
              Upgrade to Trainer
              <FaArrowRight />
            </button>
          </>
        ) : (
          <>
            <div className="text-center mb-8">
              <h3 className="text-2xl font-bold mb-4">Share Your Success</h3>
              <p className="text-gray-300">Help other trainers discover Roshi and earn rewards for each referral.</p>
            </div>
            <div className="bg-gray-800 p-6 rounded-xl text-center mb-6">
              <div className="flex items-center justify-center gap-3 mb-4">
                <FaGift className="text-3xl text-orange-500" />
                <span className="text-2xl font-mono">{referralCode}</span>
              </div>
              <p className="text-gray-400">Share this code with other fitness professionals</p>
            </div>
          </>
        )}
      </div>

      {isMemberTier && (
        <div className="bg-gradient-to-r from-orange-500 to-orange-600 rounded-2xl p-8 text-white mt-8">
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">Ready to Take the Next Step?</h3>
            <p className="mb-6">
              Upgrade to our Trainer plan and get access to:
            </p>
            <div className="grid grid-cols-3 gap-4">
              {trainerFeatures.slice(1, 4).map((feature, index) => (
                <div key={index} className="bg-white/10 rounded-lg p-4 backdrop-blur-sm">
                  <feature.icon className="text-2xl mb-2 mx-auto" />
                  <h4 className="font-semibold">{feature.title}</h4>
                </div>
              ))}
            </div>
            <button
              onClick={() => setShowUpgradeModal(true)}
              className="mt-6 bg-white text-orange-600 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      )}

      {/* Subscription Management */}
      <div className="mt-8 text-center">
        <button
          onClick={() => setShowCancelModal(true)}
          className="text-red-500 hover:text-red-600 transition-colors"
        >
          <span className="flex items-center justify-center gap-2">
            <FaRegTimesCircle />
            Cancel Subscription
          </span>
        </button>
      </div>

      {/* Modals */}
      <Modal isOpen={showUpgradeModal} onClose={() => setShowUpgradeModal(false)}>
        {/* Upgrade Modal Content */}
      </Modal>

      <Modal isOpen={showCancelModal} onClose={() => setShowCancelModal(false)}>
        {/* Cancel Modal Content */}
      </Modal>
    </div>
  );
};

export default SubscriptionManagement;