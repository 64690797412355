



import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  FaChevronLeft,
  FaTimes
} from "react-icons/fa";
import WorkoutHeader from "./WorkoutHeader";
import {
  updateWorkout,
  deleteWorkout,
  duplicateWorkout,
} from "../../store/features/workouts/workoutsSlice";
import WorkoutCategorySelector from "./WorkoutCategorySelector";
import ExerciseList from "./ExerciseList";
import ExerciseCreationModal from "./ExerciseCreationModal";

const WorkoutContainer = styled.div`
  padding: 20px;
  margin: 0px 20px;
  border-radius: 8px;
  height: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #333; /* The inner background color */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  /* Gradient Border */
  // border: 5px solid transparent;
  // border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;

  /* Ensuring border-radius is applied correctly */
  mask-image: radial-gradient(circle, white 99%, transparent 100%);
  -webkit-mask-image: radial-gradient(circle, white 99%, transparent 100%);

  animation: animateBorder 5s infinite;

  @keyframes animateBorder {
    0% {
      border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;
    }
    50% {
      border-image: radial-gradient(circle, #ffc800, #ff00ec, #9100ff) 1;
    }
    100% {
      border-image: radial-gradient(circle, #9100ff, #ff00ec, #ffc800) 1;
    }
  }

  @media (max-width: 768px) {
    /* Add your responsive styles here */
  }
`;

const MainSection = styled.div`
  flex: 1;
  position: relative;
  overflow-y: auto;
  margin-top: 20px;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  scrollbar-width: none; /* Firefox */
`;

const Section = styled.div`
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  padding-bottom: 30px;

  // background: linear-gradient(135deg, #2c2c2c, #3a3a3a);
  border-radius: 8px;
  padding: 1px 10px 33px;
  transition: background-color 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #545454;

  // background: radial-gradient(circle, #9100ff, #ff00ec, #ffc800);
  // background: linear-gradient(43deg, #2c2c2c, #3a3a3a);
  margin: 15px 0px 5px 0px;
  // padding: 0px 10px;
  border-radius: 7px;
  font-size: 25px;
  width: 60%;

  color: ${({ type }) =>
    type === "single"
      ? "#4caf50"
      : type === "super"
        ? "#2196f3"
        : type === "circuit"
          ? "#ffeb3b"
          : "#545454"};
`;


const CloseButton = styled.button`
  position: fixed;
  top: 42px;
  left: 25px;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  z-index: 10;

  &:hover {
    color: #ccc;
    background: none;
  }
  @media (max-width: 768px) {
    top: 27px;
  }

  // Hide on desktop
  @media (min-width: 769px) {
    display: none;
  }
`;


const SectionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`;

const RemoveCategoryButton = styled.button`
    background: none;
    border: none;
    color: #666;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
        color: #ff4444;
        background: rgba(255, 68, 68, 0.1);
    }
`;

const AddCategorySection = styled.div`
    margin-top: 20px;
    // padding: 16px;
    border-radius: 8px;
    // background: rgba(255, 255, 255, 0.05);
    transition: background-color 0.2s ease;

    &:hover {
        // background: rgba(255, 255, 255, 0.08);
    }
`;

const AddExerciseButton = styled.button`
  background: #3a3a3a;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 16px;

  &:hover {
    background: #4a4a4a;
  }
`;


// ########################################################################

const WorkoutForm = ({
  workout: initialWorkout,
  programId,
  onDelete,
  onClose,
  onUpdate,
  onFormActive,
}) => {
  const dispatch = useDispatch();
  const workout = useSelector((state) => state.workouts.byId[initialWorkout.id]);
  const [currentWorkout, setCurrentWorkout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showValidation, setShowValidation] = useState(false);
  const [activeCategories, setActiveCategories] = useState([]);
  const [creatingExercise, setCreatingExercise] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [showExerciseModal, setShowExerciseModal] = useState(false);

  useEffect(() => {
    if (workout) {
      const existingCategories = Object.keys(workout.categories || {}).filter(
        category => workout.categories[category]?.length > 0
      );
      setActiveCategories(existingCategories);
      setCurrentWorkout(workout);
      setLoading(false);
    }
  }, [workout]);

  useEffect(() => {
    onFormActive(true);
    return () => onFormActive(false);
  }, [onFormActive]);

  useEffect(() => {
    if (error) {
      // You might want to add a toast or notification system here
      const timer = setTimeout(() => setError(null), 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleFormInteraction = useCallback((e) => {
    e.stopPropagation();
    onFormActive(true);
  }, [onFormActive]);

  const handleAddCategory = (categoryId) => {
    setCurrentWorkout(prev => ({
      ...prev,
      categories: {
        ...prev.categories,
        [categoryId]: []
      }
    }));
    setActiveCategories(prev => [...prev, categoryId]);
  };

  const handleRemoveCategory = (categoryId) => {
    setCurrentWorkout(prev => {
      const updatedCategories = { ...prev.categories };
      delete updatedCategories[categoryId];
      return {
        ...prev,
        categories: updatedCategories
      };
    });
    setActiveCategories(prev => prev.filter(cat => cat !== categoryId));
  };

  const toggleStatus = async (workoutId) => {
    if (!workout) return null;

    const newStatus = workout.status === "published" ? "unpublished" : "published";
    const updatedWorkout = { ...workout, status: newStatus };

    const resultAction = await dispatch(
      updateWorkout({
        programId,
        workoutId,
        workoutData: updatedWorkout,
      })
    );

    return updateWorkout.fulfilled.match(resultAction) ? resultAction.payload : null;
  };

  const handlePublish = useCallback(async () => {
    if (!currentWorkout) return;

    if (!workout.mainMovement?.trim() || !workout.intensity?.trim()) {
      setShowValidation(true);
      return;
    }

    try {
      const updatedWorkout = await toggleStatus(currentWorkout.id);
      if (updatedWorkout) {
        setCurrentWorkout(updatedWorkout);
        setShowValidation(false);
      }
    } catch (err) {
      console.error("Error publishing workout:", err);
      setError("Failed to publish workout");
    }
  }, [currentWorkout, toggleStatus, workout]);

  const handleDelete = useCallback(async () => {
    if (!currentWorkout) return;
    try {
      await dispatch(deleteWorkout({ programId, workoutId: currentWorkout.id }));
      onDelete(currentWorkout.id);
    } catch (err) {
      setError("Failed to delete workout");
    }
  }, [currentWorkout, dispatch, programId, onDelete]);

  const handleDuplicate = useCallback(async () => {
    if (!currentWorkout) return;
    try {
      const resultAction = await dispatch(
        duplicateWorkout({
          programId,
          workoutId: currentWorkout.id,
          targetWeek: currentWorkout.week,
        })
      );
      if (!duplicateWorkout.fulfilled.match(resultAction)) {
        setError("Failed to duplicate workout");
      }
    } catch (err) {
      setError("Failed to duplicate workout");
    }
  }, [currentWorkout, dispatch, programId]);



  // Exercise related logic#####################################################


  const handleExerciseComplete = useCallback(({ type, mode }) => {
    if (!currentWorkout || !activeCategory) return;

    // Create a new exercise based on the selected type and mode
    const newExercise = {
      id: Date.now().toString(), // We'll use UUID in production
      type,
      mode,
      value: '',
      attributes: {
        // Base attributes all exercises have
        sets: [{
          reps: '',
          weight: ''
        }],
        weightUnit: 'lbs',
        // We'll add mode-specific attributes in the next configuration step
      }
    };

    // Add type-specific properties
    if (type === 'superset') {
      newExercise.supersetId = Date.now().toString();
    } else if (type === 'circuit') {
      newExercise.circuitId = Date.now().toString();
    } else if (type === 'pyramid') {
      newExercise.attributes.pyramidType = 'ascending';
      newExercise.attributes.pyramidSteps = [];
    } else if (type === 'drop_set') {
      newExercise.attributes.dropSetCount = 0;
      newExercise.attributes.decrementPercentage = 20;
    }

    // Add mode-specific properties
    if (mode === 'cardio') {
      newExercise.attributes = {
        ...newExercise.attributes,
        duration: '',
        distance: '',
        intensity: '',
        sets: [{
          duration: '',
          distance: '',
          intensity: ''
        }]
      };
    } else if (mode === 'calisthenics') {
      newExercise.attributes = {
        ...newExercise.attributes,
        duration: '',
        intensity: '',
        volume: ''
      };
    }

    // Update the workout state with the new exercise
    const updatedCategories = {
      ...currentWorkout.categories,
      [activeCategory]: [...currentWorkout.categories[activeCategory], newExercise]
    };

    const updatedWorkout = {
      ...currentWorkout,
      categories: updatedCategories
    };

    // Update local state
    setCurrentWorkout(updatedWorkout);

    // Dispatch the update to Redux
    dispatch(
      updateWorkout({
        programId,
        workoutId: currentWorkout.id,
        workoutData: updatedWorkout
      })
    );

    // Reset the exercise creation state
    setCreatingExercise(false);
    setActiveCategory(null);
  }, [currentWorkout, activeCategory, programId, dispatch]);

  // #########################################################################

  if (loading) return <div>Loading workout...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!currentWorkout) return <div>Workout not found</div>;

  return (
    <>
      <WorkoutContainer
        onClick={handleFormInteraction}
        onFocus={handleFormInteraction}
        onMouseDown={handleFormInteraction}
      >
        <CloseButton onClick={onClose}>
          <FaChevronLeft />
        </CloseButton>
        <MainSection>
          <WorkoutHeader
            currentWorkout={currentWorkout}
            handlePublish={handlePublish}
            handleDelete={handleDelete}
            handleDuplicate={handleDuplicate}
            updateWorkout={(workout) =>
              dispatch(
                updateWorkout({
                  programId,
                  workoutId: workout.id,
                  workoutData: workout,
                })
              )
            }
            programId={programId}
            showValidation={showValidation}
            setShowValidation={setShowValidation}
            onWorkoutUpdate={(updatedWorkout) => {
              setCurrentWorkout(updatedWorkout);
              if (onUpdate) {
                onUpdate(updatedWorkout);
              }
            }}
          />
  
          {activeCategories.map(category => (
            <Section key={category}>
              <SectionHeader>
                <SectionTitle>
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </SectionTitle>
                <RemoveCategoryButton
                  onClick={() => handleRemoveCategory(category)}
                  title="Remove category"
                >
                  <FaTimes />
                </RemoveCategoryButton>
              </SectionHeader>
  
              <ExerciseList
                exercises={currentWorkout.categories[category]}
                category={category}
              />
  
              {currentWorkout.status !== "published" && (
                <AddExerciseButton
                  onClick={() => {
                    setShowExerciseModal(true);
                    setActiveCategory(category);
                  }}
                >
                  + Exercise 
                </AddExerciseButton>
              )}
            </Section>
          ))}
  
          {activeCategories.length < 4 && (
            <AddCategorySection>
              <WorkoutCategorySelector
                currentCategories={activeCategories}
                onAddCategory={handleAddCategory}
              />
            </AddCategorySection>
          )}
        </MainSection>
      </WorkoutContainer>
  
      {/* Modal rendered outside the WorkoutContainer */}
      {showExerciseModal && (
        <ExerciseCreationModal
          onClose={() => {
            setShowExerciseModal(false);
            setActiveCategory(null);
          }}
          onSave={handleExerciseComplete}
          category={activeCategory}
        />
      )}
    </>
  );
}
export default React.memo(WorkoutForm);