// src/components/ui/Badge.js

import React from 'react';
import styled from 'styled-components';

const StyledBadge = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background-color: ${({ color }) => color || '#6c757d'};
  color: #fff;
  border-radius: 12px;
  font-size: 12px;
`;

const Badge = ({ text, color }) => {
  return <StyledBadge color={color}>{text}</StyledBadge>;
};

export default Badge;
