import React from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
  background: ${(props) => props.background || "#1a1a1a"};
  color: ${(props) => props.color || "#fff"};
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    transform: translateY(-8px);
    background: ${(props) => props.hoverBackground || "#333"};
    cursor: pointer;
  }
`;

const CardLabel = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 0.5rem;
`;

const CardIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 0.5rem;
`;

const Card = ({ icon, label, onClick, ...props }) => {
  return (
    <CardWrapper onClick={onClick} {...props}>
      {icon && <CardIcon>{icon}</CardIcon>}
      {label && <CardLabel>{label}</CardLabel>}
    </CardWrapper>
  );
};

export default Card;
