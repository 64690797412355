import React, { useState, useEffect } from 'react';

const withStepValidation = (WrappedComponent) => {
  return function ValidatedStep({ data, onNext, ...props }) {
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const validateEnvironmentStep = () => {
      if (!data.environment) {
        setErrors({ environment: 'Please select a program environment' });
        return false;
      }
      return true;
    };

    const validateLengthStep = () => {
      const newErrors = {};
      
      if (!data.durationType) {
        newErrors.durationType = 'Please select a duration type';
      }
      
      if (data.durationType === 'fixed') {
        if (!data.totalCycles || data.totalCycles <= 0) {
          newErrors.totalCycles = 'Please enter a valid number of cycles';
        }
        if (!data.defaultCycleLengthDays || data.defaultCycleLengthDays <= 0) {
          newErrors.defaultCycleLengthDays = 'Please enter valid days per cycle';
        }
      } else if (data.durationType === 'ongoing') {
        if (!data.defaultCycleLengthDays || data.defaultCycleLengthDays <= 0) {
          newErrors.defaultCycleLengthDays = 'Please enter valid days per cycle';
        }
      }

      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const validateProgramTypeStep = () => {
      if (!data.type) {
        setErrors({ type: 'Please select a program type' });
        return false;
      }
      return true;
    };

    const validateMetadataStep = () => {
      const newErrors = {};
      
      if (!data.name || !data.name.trim()) {
        newErrors.name = 'Program name is required';
      }
      
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
    };

    const handleNext = (updatedData) => {
      setIsDirty(true);
      let isStepValid = false;

      switch (WrappedComponent.name) {
        case 'ProgramEnvironmentStep':
          isStepValid = validateEnvironmentStep();
          break;
        case 'ProgramLengthStep':
          isStepValid = validateLengthStep();
          break;
        case 'ProgramTypeStep':
          isStepValid = validateProgramTypeStep();
          break;
        case 'ProgramMetadataStep':
          isStepValid = validateMetadataStep();
          break;
        default:
          isStepValid = true;
      }

      if (isStepValid) {
        onNext(updatedData);
      }
    };

    useEffect(() => {
      if (isDirty) {
        switch (WrappedComponent.name) {
          case 'ProgramEnvironmentStep':
            setIsValid(validateEnvironmentStep());
            break;
          case 'ProgramLengthStep':
            setIsValid(validateLengthStep());
            break;
          case 'ProgramTypeStep':
            setIsValid(validateProgramTypeStep());
            break;
          case 'ProgramMetadataStep':
            setIsValid(validateMetadataStep());
            break;
          default:
            setIsValid(true);
        }
      }
    }, [data, isDirty]);

    return (
      <WrappedComponent
        {...props}
        data={data}
        onNext={handleNext}
        errors={isDirty ? errors : {}}
        isValid={isValid}
        setIsDirty={setIsDirty}
      />
    );
  };
};

export default withStepValidation;