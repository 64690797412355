

import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { FaEllipsisV, FaCalendarAlt, FaCheck, FaTimes, FaEdit, FaClone } from "react-icons/fa";
import { useDispatch } from "react-redux";
import WorkoutCalendar from "./WorkoutCalendar";

import { updateWorkout } from "../../store/features/workouts/workoutsSlice";


const Header = styled.div`
  padding: 20px;
  background-color: #2c2c2c;
  color: white;
  border-radius: 8px 8px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8em;
  // min-width: 320px;
`;

const EditableMainMovement = styled.input.withConfig({
  shouldForwardProp: (prop) => !['isEditing', 'isEditable'].includes(prop),
})`
border: ${({ isEditing, isEditable, showValidation, value }) => {
    if (showValidation && !value?.trim()) return "1px solid #ff4444";
    if (isEditing && isEditable) return "1px solid white";
    return "none";
  }};
    background: transparent;
  color: white;
  font-size: 1.3em;
  padding: ${({ isEditing, isEditable }) => (isEditing && isEditable ? "5px" : "0")};
  padding-left: 2px;
  margin: 0;
  outline: none;
  width: 100%;
  max-width: 100%;
  cursor: ${({ isEditable }) => (isEditable ? "text" : "default")};
  pointer-events: ${({ isEditable }) => (isEditable ? "auto" : "none")};
  // opacity: ${({ isEditable }) => (isEditable ? 1 : 0.7)};
  &::placeholder {
    color: #aaa;
    font-style: italic;
    font-size: 1em;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    font-weight: bold;
    &::placeholder {
      font-size: 0.8em;
    }
  }
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IntensitySelector = styled.select`
 border: ${({ showValidation, value }) => 
    showValidation && !value ? "1px solid #ff4444" : "none"};
  background: transparent;
  color: ${({ $intensity }) =>
    $intensity === "Easy/Light"
      ? "#4caf50"
      : $intensity === "Moderate"
      ? "#ffeb3b"
      : $intensity === "Hard/Heavy"
      ? "#f44336"
      : "#fff"};
  font-size: 1em;
  outline: none;
  cursor: pointer;
  padding-bottom: 1px;
  font-size: 0.8em;

  option {
    background-color: #1c1c1e;
    color: white;
  }
`;



const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
  }
`;

const MeatballMenu = styled.div`
  position: relative;
  display: inline-block;

  & .menu-content {
    display: none;
    position: absolute;
    right: 0;
    background-color: #3a3a3a;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;

    & button {
      display: block;
      width: 100%;
      padding: 12px 16px;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;
      color: #ffffff;
      font-size: 0.8em;

      &:hover {
        background-color: #4e4e4e;
      }
    }
  }

  &:hover .menu-content {
    display: block;
  }
`;

const DateSelector = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const CalendarIcon = styled(FaCalendarAlt)`
  margin-right: 5px;
`;

const CalendarModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(45deg, #333, #545454, #333);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  width: 100%;
  max-width: 600px;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;









const IconButton = styled.button`
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#4caf50" : "#3A3A3A")};
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${(props) => (props.primary ? "#45a049" : "#bbb")};
  }

  svg {
    width: 10px;
    height: 10px;
  }
`;









const WorkoutHeader = ({
  currentWorkout,
  handlePublish,
  programId,
  showValidation,
  handleDelete,
  setShowValidation,
  onWorkoutUpdate,
}) => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [mainMovement, setMainMovement] = useState(
    currentWorkout.mainMovement || ""
  );
  const [workoutName, setWorkoutName] = useState(currentWorkout.name || "");
  const [intensity, setIntensity] = useState(currentWorkout.intensity || "");
  const [assignedDate, setAssignedDate] = useState(
    currentWorkout.assignedDate ? new Date(currentWorkout.assignedDate) : null
  );
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const isWorkoutEditable = currentWorkout.status !== "published";

  

   useEffect(() => {
    setWorkoutName(currentWorkout.name || "");
    setIntensity(currentWorkout.intensity || "");
  }, [currentWorkout]);


  const handleWorkoutNameChange = useCallback(
    (e) => {
      if (isWorkoutEditable) {
        const newValue = e.target.value.slice(0, 25);
        setWorkoutName(newValue);
      }
    },
    [isWorkoutEditable]
  );

  const handleWorkoutNameBlur = useCallback(() => {
    if (isWorkoutEditable) {
      setIsEditing(false);
      if (workoutName !== currentWorkout.name) {
        const updatedWorkout = {
          ...currentWorkout,
          name: workoutName,
        };
        dispatch(
          updateWorkout({
            programId,
            workoutId: updatedWorkout.id,
            workoutData: updatedWorkout,
          })
        ).then((resultAction) => {
          if (updateWorkout.fulfilled.match(resultAction)) {
            if (workoutName.trim() && currentWorkout.intensity?.trim()) {
              setShowValidation(false);
            }
            if (onWorkoutUpdate) {
              onWorkoutUpdate(resultAction.payload);
            }
          }
        });
      }
    }
  }, [
    currentWorkout,
    workoutName,
    dispatch,
    programId,
    isWorkoutEditable,
    setShowValidation,
    onWorkoutUpdate,
  ]);



const handleIntensityChange = useCallback(
    (e) => {
      if (isWorkoutEditable) {
        const newIntensity = e.target.value;
        setIntensity(newIntensity);
        const updatedWorkout = {
          ...currentWorkout,
          intensity: newIntensity,
          name: `${currentWorkout.mainMovement} - ${newIntensity}`,
        };
        dispatch(
          updateWorkout({
            programId,
            workoutId: updatedWorkout.id,
            workoutData: updatedWorkout,
          })
        ).then((resultAction) => {
          if (updateWorkout.fulfilled.match(resultAction)) {
            if (
              currentWorkout.mainMovement?.trim() &&
              newIntensity.trim()
            ) {
              setShowValidation(false);
            }
            if (onWorkoutUpdate) {
              onWorkoutUpdate(resultAction.payload);
            }
          } else {
            console.error("Error updating workout:", resultAction.error);
          }
        });
      }
    },
    [
      currentWorkout,
      dispatch,
      programId,
      isWorkoutEditable,
      setShowValidation,
      onWorkoutUpdate,
    ]
  );

  const handleDateChange = useCallback(
    (date) => {
      if (isWorkoutEditable) {
        setAssignedDate(date);
        const updatedWorkout = {
          ...currentWorkout,
          assignedDate: date.toISOString(),
        };
        dispatch(
          updateWorkout({
            programId,
            workoutId: updatedWorkout.id,
            workoutData: updatedWorkout,
          })
        ).then((resultAction) => {
          if (updateWorkout.fulfilled.match(resultAction)) {
            if (onWorkoutUpdate) {
              onWorkoutUpdate(resultAction.payload);
            }
            setIsCalendarOpen(false);
          } else {
            console.error("Error updating assigned date:", resultAction.error);
          }
        });
      }
    },
    [currentWorkout, dispatch, programId, isWorkoutEditable, onWorkoutUpdate]
  );

  const toggleCalendar = () => {
    if (isWorkoutEditable) {
      setIsCalendarOpen(!isCalendarOpen);
    }
  };

  const formatTimestamp = (date) => {
    if (!date) {
      console.log("No date provided to formatTimestamp");
      return "N/A";
    }
    if (!(date instanceof Date)) {
      console.error("Invalid date object provided to formatTimestamp:", date);
      return "Invalid Date";
    }
  
    return date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

 

  return (
    <>
      <Header>
        <HeaderSection>
          <div>
            {/* <WorkoutNumber>Workout: {currentWorkout.order}</WorkoutNumber> */}
            <EditableMainMovement
              isEditing={isEditing}
              isEditable={isWorkoutEditable}
              value={workoutName}
              showValidation={showValidation}
              placeholder="Workout Name.."
              onClick={() => isWorkoutEditable && setIsEditing(true)}
              onChange={handleWorkoutNameChange}
              onBlur={handleWorkoutNameBlur}
              onFocus={() => isWorkoutEditable && setIsEditing(true)}
              readOnly={!isWorkoutEditable}
              autoFocus={isEditing} // Auto-focus when editing starts
              tabIndex={isWorkoutEditable ? 0 : -1}
              maxLength={25} 
           />
            <IntensitySelector
              value={intensity}
              onChange={handleIntensityChange}
              $intensity={intensity}
              disabled={!isWorkoutEditable}
              showValidation={showValidation}
            >
              <option value="" disabled>
                Intensity
              </option>
              <option value="Easy/Light">Easy/Light</option>
              <option value="Moderate">Moderate</option>
              <option value="Hard/Heavy">Hard/Heavy</option>
            </IntensitySelector>
            <DateSelector onClick={toggleCalendar}>
            <CalendarIcon />
            <div>
              {assignedDate ? assignedDate.toLocaleDateString() : "Select Date"}
            </div>
          </DateSelector>
          </div>
        
        
        </HeaderSection>
        
        <MeatballMenu>
          <Button>
            <FaEllipsisV />
          </Button>
          <div className="menu-content">
          {isWorkoutEditable && (
            <button onClick={handlePublish}>
              {currentWorkout.status === "unpublished"
                ? "Publish"
                : "Unpublish"}
            </button>
             )}
            <button onClick={handleDelete}>Delete Workout</button>
            <button>Share Workout</button>
          </div>
        </MeatballMenu>
       
      </Header>


      {isCalendarOpen && (
        <>
          <Backdrop onClick={toggleCalendar} />
          <CalendarModal>
            <WorkoutCalendar
              selectedDate={assignedDate}
              onSelectDate={handleDateChange}
            />

          </CalendarModal>
        </>
      )}
    </>
  );
};

export default React.memo(WorkoutHeader);
