import React from "react";
import styled from "styled-components";

const Button = styled.button`
  background-color: transparent;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
`;


const AddWorkoutButton = ({ onAddWorkout, weekNumber }) => {
  const handleClick = () => {
    console.log("AddWorkoutButton clicked for week:", weekNumber);
    if (typeof onAddWorkout === 'function') {
      onAddWorkout(weekNumber); // Pass weekNumber to the handler
    } else {
      console.error("onAddWorkout is not a function", onAddWorkout);
    }
  };

  return <Button onClick={handleClick}>+ Add Workout</Button>;
};

export default AddWorkoutButton;
