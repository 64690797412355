// store/features/subscriptions/subscriptionSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (process.env.NODE_ENV === 'development') {
      config.headers['Access-Control-Allow-Credentials'] = true;
    }
    console.log('Full request URL:', `${config.baseURL}${config.url}`); // Debug log
    console.log('Request data:', config.data); // Debug log
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

// Response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Subscription API Response:', response);
    return response;
  },
  (error) => {
    console.error('Subscription API Error:', error);

    if (error.response?.status === 429) {
      return Promise.reject(new Error('Please wait before trying again'));
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
    }

    if (error.message.includes('Network Error')) {
      console.error('CORS or Network Error:', error);
      return Promise.reject(new Error('Unable to connect to the server. Please try again later.'));
    }

    return Promise.reject(
      error.response?.data?.message ||
      error.message ||
      'An unexpected error occurred'
    );
  }
);

export const createSubscription = createAsyncThunk(
    "subscriptions/create",
    async ({ tier, paymentMethodId }, { rejectWithValue }) => {
      try {
        console.log('Creating subscription request:', { tier, paymentMethodId });
        const response = await api.post('/api/subscriptions', { 
          tier, 
          paymentMethodId 
        });
        console.log('Subscription created:', response.data);
        return response.data;
      } catch (error) {
        console.error('Full error object:', error);
        console.error('Error response:', error.response);
        return rejectWithValue(
          error.response?.data?.message || 
          error.message || 
          'Failed to create subscription'
        );
      }
    }
  );

export const getSubscriptionDetails = createAsyncThunk(
  "subscriptions/getDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/subscriptions');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscriptions/cancel",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.delete('/api/subscriptions');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const upgradeSubscription = createAsyncThunk(
  "subscriptions/upgrade",
  async (newTier, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/subscriptions/upgrade', { tier: newTier });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const downgradeSubscription = createAsyncThunk(
  "subscriptions/downgrade",
  async (newTier, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/subscriptions/downgrade', { tier: newTier });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  currentSubscription: null,
  subscriptionDetails: null,
  loading: false,
  error: null,
};

const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    resetState: (state) => {
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Subscription
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.currentSubscription = action.payload;
        state.loading = false;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get Subscription Details
      .addCase(getSubscriptionDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSubscriptionDetails.fulfilled, (state, action) => {
        state.subscriptionDetails = action.payload;
        state.loading = false;
      })
      .addCase(getSubscriptionDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Cancel Subscription
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state, action) => {
        state.currentSubscription = action.payload;
        state.loading = false;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Upgrade Subscription
      .addCase(upgradeSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(upgradeSubscription.fulfilled, (state, action) => {
        state.currentSubscription = action.payload;
        state.loading = false;
      })
      .addCase(upgradeSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Downgrade Subscription
      .addCase(downgradeSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downgradeSubscription.fulfilled, (state, action) => {
        state.currentSubscription = action.payload;
        state.loading = false;
      })
      .addCase(downgradeSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearError, resetState } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;