



// src/components/trainer/ProgramBuilder.js
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FaPlus, FaFilter, FaEye } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import {
  createProgram,
  selectAllPrograms,
  fetchUserPrograms,
  deleteProgram,
  fetchProgramPhases,
  selectProgramPhases,
  setCurrentPhase
} from '../../../store/features/programs/programsSlice';
import { store } from '../../../store/index';  // Adjust path as needed
import ProgramCard from './ProgramCard';
import ProgramCreationWizard from '../ProgramCreationWizard';
import { useNavigate } from 'react-router-dom';
import BottomDrawer from './BottomDrawer';
import ProgramPhaseCarousel from './ProgramPhaseCarousel';
import ProgramInterface from './ProgramInterface';

const BuilderContainer = styled.div`
  padding: 1rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .actions {
    display: flex;
    gap: 1rem;
  }
`;

const Button = styled.button`
  background: ${props => props.primary ? 'white' : '#f5f5f5'};
  color: ${props => props.primary ? '#ff4800' : '#333'};
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;

  &:hover {
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.0);
  font-weight: 650;
  }
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  color: #a0a0a0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  h3 {
    font-size: 1.5rem;
    color: white;
    margin: 0;
  }

  p {
    margin: 0;
    font-size: 1.1rem;
  }
`;

const TabBar = styled.div`
  display: flex;
  gap: 1rem;
  // background: #2c2c2c;
  padding: 0.5rem;
  border-radius: 12px;
`;

const TabBarContainer = styled.div`
  width: 100%;

  overflow-x: auto;
  margin-bottom: 2rem;
  scrollbar-width: none;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  background: ${props => props.active ? '#3d3d3d' : 'transparent'};
  border: none;
  color: ${props => props.active ? '#ffffff' : '#a0a0a0'};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: ${props => props.active ? '600' : '500'};

  &:hover {
    background: ${props => props.active ? '#3d3d3d' : '#333333'};
    color: white;
  }
`;

const ProgramsContainer = styled.div`
  // background: linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%);
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  height: 600px;
  overflow-y: auto;
  padding: 1rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #404040;
    border-radius: 4px;

    &:hover {
      background: #4a4a4a;
    }
  }

  scrollbar-width: none;
  scrollbar-color: #404040 #1a1a1a;
`;

const ProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;


const FilterSection = styled.div`
  background: #2c2c2c;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const FilterRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FilterSelect = styled.select`
  background: #3d3d3d;
  color: white;
  border: 1px solid #4d4d4d;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    border-color: #5d5d5d;
  }

  option {
    background: #3d3d3d;
  }
`;




const FilterGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-left: auto;
  padding-left: 1rem;
  border-left: 1px solid #404040;
`;

const FilterTab = styled(Tab)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  min-width: max-content;
  font-size: 0.9rem;
  padding: 0.75rem 1.25rem;
`;





const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const PhaseInfo = styled.div`
  font-size: 14px;
  color: #a0a0a0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PhaseBadge = styled.span`
  color: #fff;
  background-color: #2c5282;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;

  &:hover {
  cursor: pointer;
  }
`;

const ProgramBuilder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('all');
  const isLoading = useSelector(state => state.programs.isUpdating);
  const programs = useSelector(selectAllPrograms);

  const [selectedProgram, setSelectedProgram] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [carouselProgram, setCarouselProgram] = useState(null);

  const [filters, setFilters] = useState({
    programType: 'all',
    durationType: 'all',
    status: 'all',
    marketplaceStatus: 'all'
  });
  const [showFilters, setShowFilters] = useState(false);


  useEffect(() => {
    dispatch(fetchUserPrograms());
  }, [dispatch]);


  useEffect(() => {
    if (selectedProgram && selectedProgram.type === 'big_picture') {
      dispatch(fetchProgramPhases(selectedProgram.id));
    }
  }, [selectedProgram?.currentPhaseId, dispatch]);

  const handleCreateProgram = () => {
    setIsWizardOpen(true);
  };

  const handleWizardClose = () => {
    setIsWizardOpen(false);
  };

  


  const handleProgramSelect = async (program) => {
    if (program.type === 'big_picture') {
      try {
        await dispatch(fetchProgramPhases(program.id));
        const updatedProgram = {
          ...program,
          phases: selectProgramPhases(store.getState(), program.id)
        };
        setSelectedProgram(updatedProgram);
      } catch (error) {
        console.error('Failed to fetch program phases:', error);
        setSelectedProgram(program);
      }
    } else {
      setSelectedProgram(program);
    }
    setIsDrawerOpen(true);
  };


  const handlePhaseChange = async (selectedPhase) => {
    if (!selectedProgram) return;
    
    try {
        await dispatch(setCurrentPhase({
            programId: selectedProgram.id,
            phaseId: selectedPhase.id
        })).unwrap();
        
        await dispatch(fetchProgramPhases(selectedProgram.id)).unwrap();
        
        const updatedProgram = {
            ...selectedProgram,
            currentPhaseId: selectedPhase.id,
            phases: selectProgramPhases(store.getState(), selectedProgram.id)
        };
        
        setSelectedProgram(updatedProgram);
    } catch (error) {
        console.error('Error changing phase:', error);
    }
};



  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
    setSelectedProgram(null);
  };


  const handleOpenModal = useCallback(() => {
    if (selectedProgram && selectedProgram.type === 'big_picture') {
      // Use the selectedProgram directly since we already have it
      setCarouselProgram(selectedProgram);
      setIsModalOpen(true);
    }
  }, [selectedProgram]);

  // Update the modal close handler
  const handleCloseModal = useCallback(() => {
    // Close modal first
    setIsModalOpen(false);
    // Clear carousel program state after a short delay to ensure proper unmounting
    setTimeout(() => {
      setCarouselProgram(null);
    }, 100);
  }, []);


  const handleProgramCreate = async (programData) => {
    try {
      await dispatch(createProgram(programData)).unwrap();
      setIsWizardOpen(false);
      dispatch(fetchUserPrograms());
    } catch (error) {
      console.error('Failed to create program:', error);
    }
  };

  const handleDeleteProgram = async (programId) => {
    if (window.confirm('Are you sure you want to delete this program?')) {
      try {
        await dispatch(deleteProgram(programId)).unwrap();
      } catch (error) {
        console.error('Failed to delete program:', error);
      }
    }
  };

  const filteredPrograms = programs.filter(program => {
    // Type filter
    if (filters.programType !== 'all' && program.type !== filters.programType) {
      return false;
    }

    // Duration filter
    if (filters.durationType !== 'all' && program.durationType !== filters.durationType) {
      return false;
    }

    // Status filter
    if (filters.status !== 'all' && program.status !== filters.status) {
      return false;
    }

    // Marketplace filter (for future implementation)
    if (filters.marketplaceStatus !== 'all') {
      if (filters.marketplaceStatus === 'listed' && !program.isListed) {
        return false;
      }
      if (filters.marketplaceStatus === 'unlisted' && program.isListed) {
        return false;
      }
    }

    return true;
  });

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
  };


  const renderDrawerHeader = useCallback((program) => {
    console.log('Render Header Debug:', {
      programType: program.type,
      currentPhaseId: program.currentPhaseId,
      phases: program.phases,
      currentPhase: program.phases?.find(phase => phase.id === program.currentPhaseId)
    });

    const currentPhase = program.type === 'big_picture' && program.phases?.find(
      phase => phase.id === program.currentPhaseId
    );
    console.log('Found current phase:', currentPhase);

    return (
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}>
        <HeaderContent>
          <h2 style={{ margin: 0 }}>{program.name}</h2>
          {/* {program.type === 'big_picture' && currentPhase && (
            <PhaseInfo>
              <FaEye />
              <PhaseBadge onClick={handleOpenModal}>
              {`Phase ${currentPhase.phaseNumber}`}
              </PhaseBadge>
            </PhaseInfo>
          )} */}
        </HeaderContent>
      </div>
    );
  }, [handleOpenModal]);

  return (
    <BuilderContainer>
      <Header>
        <div className="actions">
          <Button primary onClick={handleCreateProgram}>
            <FaPlus />
            Create New Program
          </Button>
        </div>
      </Header>







      {programs.length === 0 ? (
        <EmptyState>
          <h3>No Programs Yet</h3>
          <p>Start by creating your first training program</p>
        </EmptyState>
      ) : (
        <ProgramsContainer>
          <TabBarContainer>
            <TabBar>
              <Tab
                active={filters.programType === 'all'}
                onClick={() => handleFilterChange('programType', 'all')}
              >
                All Programs
              </Tab>
              <Tab
                active={filters.programType === 'simple'}
                onClick={() => handleFilterChange('programType', 'simple')}
              >
                Simple
              </Tab>
              <Tab
                active={filters.programType === 'progressive'}
                onClick={() => handleFilterChange('programType', 'progressive')}
              >
                Progressive
              </Tab>
              <Tab
                active={filters.programType === 'big_picture'}
                onClick={() => handleFilterChange('programType', 'big_picture')}
              >
                Big Picture
              </Tab>

              <FilterGroup>
                <FilterTab
                  active={filters.durationType === 'fixed'}
                  onClick={() => handleFilterChange('durationType', filters.durationType === 'fixed' ? 'all' : 'fixed')}
                >
                  Fixed Duration
                </FilterTab>
                <FilterTab
                  active={filters.durationType === 'ongoing'}
                  onClick={() => handleFilterChange('durationType', filters.durationType === 'ongoing' ? 'all' : 'ongoing')}
                >
                  Subscription
                </FilterTab>
                <FilterTab
                  active={filters.status === 'active'}
                  onClick={() => handleFilterChange('status', filters.status === 'active' ? 'all' : 'active')}
                >
                  Active
                </FilterTab>
                <FilterTab
                  active={filters.marketplaceStatus === 'listed'}
                  onClick={() => handleFilterChange('marketplaceStatus', filters.marketplaceStatus === 'listed' ? 'all' : 'listed')}
                >
                  Listed
                </FilterTab>
              </FilterGroup>
            </TabBar>
          </TabBarContainer>

          {showFilters && (
            <FilterSection>
              <FilterRow>
                <FilterSelect
                  value={filters.programType}
                  onChange={(e) => handleFilterChange('programType', e.target.value)}
                >
                  <option value="all">All Types</option>
                  <option value="simple">Simple</option>
                  <option value="progressive">Progressive</option>
                  <option value="big_picture">Big Picture</option>
                </FilterSelect>

                <FilterSelect
                  value={filters.durationType}
                  onChange={(e) => handleFilterChange('durationType', e.target.value)}
                >
                  <option value="all">All Durations</option>
                  <option value="fixed">Fixed Duration</option>
                  <option value="ongoing">Subscription Based</option>
                </FilterSelect>

                <FilterSelect
                  value={filters.status}
                  onChange={(e) => handleFilterChange('status', e.target.value)}
                >
                  <option value="all">All Statuses</option>
                  <option value="draft">Drafts</option>
                  <option value="active">Active</option>
                  <option value="paused">Paused</option>
                  <option value="archived">Archived</option>
                </FilterSelect>

                <FilterSelect
                  value={filters.marketplaceStatus}
                  onChange={(e) => handleFilterChange('marketplaceStatus', e.target.value)}
                >
                  <option value="all">All Marketplace</option>
                  <option value="listed">Listed</option>
                  <option value="unlisted">Unlisted</option>
                </FilterSelect>
              </FilterRow>
            </FilterSection>
          )}



          <ProgramsGrid>
            {filteredPrograms.map(program => (
              <ProgramCard
                key={program.id}
                program={program}
                onDelete={() => handleDeleteProgram(program.id)}
                // onView={() => navigate(`/programs/${program.id}`)}
                onClick={() => handleProgramSelect(program)}
              />
            ))}
          </ProgramsGrid>
        </ProgramsContainer>
      )}




      <BottomDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        gradient={selectedProgram?.themeColors?.primaryGradient}
        header={selectedProgram && renderDrawerHeader(selectedProgram)}
        disableClickOutside={isModalOpen}
      >
        {selectedProgram && (
          <div style={{ height: '100%', overflow: 'hidden' }}>
            <ProgramInterface
              program={selectedProgram}
              programId={selectedProgram.id}
              currentPhaseId={selectedProgram.currentPhaseId}
              isBigPicture={selectedProgram.type === 'big_picture'}
              onPhaseChange={handlePhaseChange}
            />
          </div>
        )}
      </BottomDrawer>


      {/* {isModalOpen && carouselProgram && (
        <ProgramPhaseCarousel
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          program={carouselProgram}
          programId={carouselProgram.id}
          onTabChange={(tab, selectedPhase) => {
            if (selectedPhase) {
              dispatch(setCurrentPhase({
                programId: carouselProgram.id,
                phaseId: selectedPhase.id
              })).unwrap()
                .then(() => {
                  dispatch(fetchProgramPhases(carouselProgram.id)).unwrap()
                    .then(() => {
                      const updatedProgram = {
                        ...carouselProgram,
                        currentPhaseId: selectedPhase.id,
                        phases: selectProgramPhases(store.getState(), carouselProgram.id)
                      };
                      setSelectedProgram(updatedProgram);
                      setIsModalOpen(false);
                    });
                });
            }
          }}
        />
      )} */}


      <ProgramCreationWizard
        open={isWizardOpen}
        onClose={handleWizardClose}
        onSubmit={handleProgramCreate}
      />
    </BuilderContainer>
  );
};

export default ProgramBuilder;







