import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';

const ExerciseCard = styled.div`
//   background: #2a2a2a;
// background: linear-gradient(132deg, #2a2a2a, #333333);
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
border-top: 0.5px solid #989fab;
border-left: 0.5px solid #989fab;
  border-radius: 8px;
  padding: 5px 10px;
  margin-bottom: 1px;
  color: white;
  transition: background-color 0.2s ease;

  &:hover {
    // background: #2a2a2a;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
`;

const ExerciseName = styled.input`
  background: transparent;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  margin-right: 1px;
  padding: 4px 0;
  border-top: 1px solid transparent;

  &:hover, &:focus {
    // border-bottom: 1px solid #4CAF50;
    outline: 1px solid #444;
  }
`;

const OptionsButton = styled.button`
  background: transparent;
  border: none;
  color: #666;
  padding: 4px;
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: white;
  }
`;

const AttributesGrid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
        props.mode === 'weights' ? '120px 120px 120px' :
            props.mode === 'cardio' ? '120px 140px 120px' :
                '120px 120px 120px'};
  gap: 12px;
  align-items: center;
`;

const AttributeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AttributeLabel = styled.div`
  font-size: 0.8rem;
  color: #888;
  white-space: nowrap;
`;

const AttributeInput = styled.input`
  width: 60px;
  background: transparent;
  border: none;
  border-top: 1px solid #444;
  border-left: 1px solid #444;
  border-radius: 4px;
  color: white;
  padding: 4px 0;
  font-size: 0.9rem;
  text-align: center;

  &:hover, &:focus {
    border-bottom: none;
    outline: 1px solid #444;
  }

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const SetRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  opacity: ${props => props.isGhost ? 0.5 : 1};
`;

const SetNumber = styled.span`
  width: 40px;
  font-size: 0.8rem;
  color: #888;
`;


const getEmptySet = (mode) => {
    switch (mode) {
        case 'weights':
            return {
                sets: '',
                reps: '',
                weight: '',
                weightUnit: 'lbs'
            };
        case 'cardio':
            return {
                duration: '',
                distance: '',
                intensity: '',
                distanceUnit: 'miles'
            };
        case 'calisthenics':
            return {
                sets: '',
                reps: '',
                duration: '',
                intensity: ''
            };
        default:
            return {};
    }
};

const isSetEmpty = (set) => {
    return Object.values(set).every(value =>
        value === '' || value === null || value === undefined ||
        (typeof value === 'object' && Object.keys(value).length === 0)
    );
};

const validateSetValue = (value, field) => {
    if (field === 'intensity') {
        return Math.min(Math.max(0, parseInt(value) || 0), 100).toString();
    }
    return value.replace(/^0+/, '') || '0';
};


const EditableExerciseCard = ({ exercise, onUpdate }) => {
    const [sets, setSets] = useState(exercise.attributes.sets);

    const handleSetChange = (index, field, value) => {
        const validatedValue = validateSetValue(value, field);
        const newSets = [...sets];

        if (index === sets.length - 1 && validatedValue !== '') {
            newSets.push(getEmptySet(exercise.mode));
        }

        newSets[index] = { ...newSets[index], [field]: validatedValue };

        const filteredSets = newSets.filter((set, i) => {
            if (i === newSets.length - 1) return true;
            return !isSetEmpty(set);
        });

        setSets(filteredSets);
        onUpdate({
            ...exercise,
            attributes: {
                ...exercise.attributes,
                sets: filteredSets
            }
        });
    };




    const renderAttributeFields = (set, index) => {
        switch (exercise.mode) {
            case 'weights':
                return (
                    <AttributesGrid mode="weights">
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.sets}
                                onChange={(e) => handleSetChange(index, 'sets', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Sets</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.reps}
                                onChange={(e) => handleSetChange(index, 'reps', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Reps</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.weight}
                                onChange={(e) => handleSetChange(index, 'weight', e.target.value)}
                                // placeholder="-"
                                min="0"
                                step="0.5"
                            />
                            <AttributeLabel>Weight</AttributeLabel>
                        </AttributeWrapper>
                    </AttributesGrid>
                );
            case 'cardio':
                return (
                    <AttributesGrid mode="cardio">
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.duration}
                                onChange={(e) => handleSetChange(index, 'duration', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Duration</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.distance}
                                onChange={(e) => handleSetChange(index, 'distance', e.target.value)}
                                // placeholder="-"
                                min="0"
                                step="0.1"
                            />
                            <AttributeLabel>Distance</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.intensity}
                                onChange={(e) => handleSetChange(index, 'intensity', e.target.value)}
                                // placeholder="-"
                                min="0"
                                max="100"
                            />
                            <AttributeLabel>Intensity %</AttributeLabel>
                        </AttributeWrapper>
                    </AttributesGrid>
                );
            case 'calisthenics':
                return (
                    <AttributesGrid mode="calisthenics">
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.sets}
                                onChange={(e) => handleSetChange(index, 'sets', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Sets</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.reps}
                                onChange={(e) => handleSetChange(index, 'reps', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Reps</AttributeLabel>
                        </AttributeWrapper>
                        <AttributeWrapper>
                            <AttributeInput
                                type="number"
                                value={set.duration}
                                onChange={(e) => handleSetChange(index, 'duration', e.target.value)}
                                // placeholder="-"
                                min="0"
                            />
                            <AttributeLabel>Duration</AttributeLabel>
                        </AttributeWrapper>
                    </AttributesGrid>
                );
            default:
                return null;
        }
    };

    return (
        <ExerciseCard>
            <CardHeader>
                <ExerciseName
                    value={exercise.value}
                    onChange={(e) => onUpdate({ ...exercise, value: e.target.value })}
                    placeholder=" Exercise name"
                />
                <OptionsButton>
                    <FaEllipsisV />
                </OptionsButton>
            </CardHeader>

            {sets.map((set, index) => (
                <SetRow
                    key={index}
                    isGhost={index === sets.length - 1 && isSetEmpty(set)}
                >
                    <SetNumber>#{index + 1}</SetNumber>
                    {renderAttributeFields(set, index)}
                </SetRow>
            ))}
        </ExerciseCard>
    );
};

export default EditableExerciseCard;