import React from 'react';
import styled from 'styled-components';
import EditableExerciseCard from './EditableExerciseCard';

const ExerciseListContainer = styled.div`
  margin-top: 16px;
`;

const ExerciseCard = styled.div`
  background: #3a3a3a;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 12px;
  color: white;
`;

const ExerciseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const ExerciseName = styled.h4`
  margin: 0;
  font-size: 1rem;
`;

const ExerciseType = styled.span`
  font-size: 0.8rem;
  color: #888;
  text-transform: capitalize;
`;

const ExerciseAttributes = styled.div`
  font-size: 0.9rem;
  color: #ccc;
`;

const ExerciseList = ({ exercises, category, onUpdateExercise }) => {
    if (!exercises || exercises.length === 0) {
      return null;
    }
  
    return (
      <ExerciseListContainer>
        {exercises.map((exercise) => (
          <EditableExerciseCard
            key={exercise.id}
            exercise={exercise}
            onUpdate={(updatedExercise) => onUpdateExercise(category, updatedExercise)}
          />
        ))}
      </ExerciseListContainer>
    );
  };

export default ExerciseList;