// src/pages/AccountSettings.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from "../components/ui/Layout";
import { FaUser, FaLock, FaBell, FaGlobe, FaCamera } from 'react-icons/fa';

const SettingsContainer = styled.div`
  padding: 1.5rem;
  max-width: 800px;
  margin: 0 auto;
`;

const Header = styled.div`
  margin-bottom: 2rem;
  
  h1 {
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #666;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #eee;
`;

const Tab = styled.button`
  padding: 1rem 1.5rem;
  background: none;
  border: none;
  font-size: 0.875rem;
  color: ${props => props.active ? '#ff4800' : '#666'};
  border-bottom: 2px solid ${props => props.active ? '#ff4800' : 'transparent'};
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &:hover {
    color: #ff4800;
  }
`;

const Section = styled.div`
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 1.5rem;
`;

const ProfileSection = styled(Section)`
  display: flex;
  gap: 2rem;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Avatar = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #f5f5f5;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.5);
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: rgba(0,0,0,0.7);
    }
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.875rem;

    &:focus {
      outline: none;
      border-color: #ff4800;
    }
  }
`;

const Button = styled.button`
  background: ${props => props.primary ? '#ff4800' : 'white'};
  color: ${props => props.primary ? 'white' : '#666'};
  border: 1px solid ${props => props.primary ? '#ff4800' : '#ddd'};
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.primary ? '#e64100' : '#f5f5f5'};
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
`;

const AccountSettings = () => {
  const [activeTab, setActiveTab] = useState('profile');
  const user = { name: "John Doe", email: "john@example.com" };

  // Placeholder data
  const [formData, setFormData] = useState({
    firstName: 'John',
    lastName: 'Doe',
    email: 'john@example.com',
    phone: '+1 234 567 8900'
  });

  return (
    <Layout user={user}>
      <SettingsContainer>
        <Header>
          <h1>Account Settings</h1>
          <p>Manage your account preferences and settings</p>
        </Header>

        <TabsContainer>
          <Tab 
            active={activeTab === 'profile'} 
            onClick={() => setActiveTab('profile')}
          >
            <FaUser />
            Profile
          </Tab>
          <Tab 
            active={activeTab === 'security'} 
            onClick={() => setActiveTab('security')}
          >
            <FaLock />
            Security
          </Tab>
          <Tab 
            active={activeTab === 'notifications'} 
            onClick={() => setActiveTab('notifications')}
          >
            <FaBell />
            Notifications
          </Tab>
        </TabsContainer>

        {activeTab === 'profile' && (
          <>
            <ProfileSection>
              <Avatar>
                <img src="https://via.placeholder.com/100" alt="Profile" />
                <div className="upload-overlay">
                  <FaCamera color="white" />
                </div>
              </Avatar>
              <div style={{ flex: 1 }}>
                <h3>Profile Picture</h3>
                <p style={{ color: '#666', marginTop: '0.5rem' }}>
                  Upload a new profile picture or avatar
                </p>
              </div>
            </ProfileSection>

            <Section>
              <h3>Personal Information</h3>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', marginTop: '1rem' }}>
                <FormGroup>
                  <label>First Name</label>
                  <input 
                    type="text" 
                    value={formData.firstName}
                    onChange={e => setFormData({...formData, firstName: e.target.value})}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Last Name</label>
                  <input 
                    type="text" 
                    value={formData.lastName}
                    onChange={e => setFormData({...formData, lastName: e.target.value})}
                  />
                </FormGroup>
              </div>
              <FormGroup>
                <label>Email</label>
                <input 
                  type="email" 
                  value={formData.email}
                  onChange={e => setFormData({...formData, email: e.target.value})}
                />
              </FormGroup>
              <FormGroup>
                <label>Phone Number</label>
                <input 
                  type="tel" 
                  value={formData.phone}
                  onChange={e => setFormData({...formData, phone: e.target.value})}
                />
              </FormGroup>
              <ButtonGroup>
                <Button>Cancel</Button>
                <Button primary>Save Changes</Button>
              </ButtonGroup>
            </Section>
          </>
        )}

        {activeTab === 'security' && (
          <Section>
            <h3>Security Settings</h3>
            {/* Add security settings content */}
          </Section>
        )}

        {activeTab === 'notifications' && (
          <Section>
            <h3>Notification Preferences</h3>
            {/* Add notification settings content */}
          </Section>
        )}
      </SettingsContainer>
    </Layout>
  );
};

export default AccountSettings;