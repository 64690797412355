import React, { useState } from 'react';
import styled from 'styled-components';
import withStepValidation from './withStepValidation';

const StepContainer = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
`;

const DurationSelector = styled.div`
  margin-bottom: 2rem;
`;

const CardRow = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const OptionCard = styled.div`
  flex: 1;
  border: 2px solid ${props => props.selected ? '#4caf50' : '#eee'};
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.selected ? '#4caf50' : '#ddd'};
  }

  h3 {
    margin: 0 0 0.5rem 0;
  }

  p {
    margin: 0;
    color: #666;
  }
`;

const FormGroup = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 1.5rem;
  margin-top: 1rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${props => props.error ? '#d32f2f' : '#ddd'};
  border-radius: 4px;
  margin-bottom: 0.5rem;

  &:focus {
    outline: none;
    border-color: ${props => props.error ? '#d32f2f' : '#4caf50'};
  }
`;

const PriceGroup = styled.div`
  margin-top: 1rem;

  label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    color: #666;
  }
`;

const PriceInput = styled.div`
  position: relative;

  input {
    width: 100%;
    padding: 0.75rem;
    padding-left: 1.5rem;
    border: 1px solid ${props => props.error ? '#d32f2f' : '#ddd'};
    border-radius: 4px;
  }

  &::before {
    content: '$';
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
  }
`;

const PriceNote = styled.div`
  font-size: 0.875rem;
  color: #666;
  margin-top: 0.25rem;
  font-style: italic;
`;

const ProgramLengthStep = ({ data, onNext, errors, updateData, setIsDirty  }) => {
  const [durationType, setDurationType] = useState(data.durationType || '');
  const [lengthDetails, setLengthDetails] = useState({
    totalCycles: data.totalCycles || '',
    defaultCycleLengthDays: data.defaultCycleLengthDays || 7,
    price: data.price || '',
  });

  const handleDurationTypeChange = (type) => {
    setIsDirty(true);
    const newData = {
      durationType: type,
      ...lengthDetails
    };
    setDurationType(type);
    updateData(newData);
  };

  const handleDetailsChange = (newDetails) => {
    setIsDirty(true);
    const newData = {
      durationType,
      ...newDetails
    };
    setLengthDetails(newDetails);
    updateData(newData);
  };

  return (
    <StepContainer>
      <Title>Define Program Length</Title>
      
      {errors.durationType && (
        <ErrorMessage>{errors.durationType}</ErrorMessage>
      )}
      
      <DurationSelector>
        <CardRow>
          <OptionCard 
            selected={durationType === 'fixed'}
            onClick={() => handleDurationTypeChange('fixed')}
          >
            <h3>Fixed Duration</h3>
            <p>Set program with defined start and end</p>
          </OptionCard>

          <OptionCard 
            selected={durationType === 'ongoing'}
            onClick={() => handleDurationTypeChange('ongoing')}
          >
            <h3>Ongoing Subscription</h3>
            <p>Continuous program with regular updates</p>
          </OptionCard>
        </CardRow>

        {durationType === 'fixed' && (
          <FormGroup>
            <label>Number of Cycles</label>
            <Input
              type="number"
              min="1"
              value={lengthDetails.totalCycles}
              error={errors.totalCycles}
              onChange={(e) => handleDetailsChange({
                ...lengthDetails,
                totalCycles: parseInt(e.target.value) || ''
              })}
            />
            {errors.totalCycles && (
              <ErrorMessage>{errors.totalCycles}</ErrorMessage>
            )}
            
            <label>Days per Cycle</label>
            <Input
              type="number"
              min="1"
              value={lengthDetails.defaultCycleLengthDays}
              error={errors.defaultCycleLengthDays}
              onChange={(e) => handleDetailsChange({
                ...lengthDetails,
                defaultCycleLengthDays: parseInt(e.target.value) || 7
              })}
            />
            {errors.defaultCycleLengthDays && (
              <ErrorMessage>{errors.defaultCycleLengthDays}</ErrorMessage>
            )}

            <PriceGroup>
              <label>Program Price</label>
              <PriceInput error={errors.price}>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={lengthDetails.price}
                  onChange={(e) => handleDetailsChange({
                    ...lengthDetails,
                    price: parseFloat(e.target.value) || ''
                  })}
                  placeholder="0.00"
                />
              </PriceInput>
              <PriceNote>One-time payment for full program access</PriceNote>
            </PriceGroup>
          </FormGroup>
        )}

        {durationType === 'ongoing' && (
          <FormGroup>
            <label>Default Cycle Length (days)</label>
            <Input
              type="number"
              min="1"
              value={lengthDetails.defaultCycleLengthDays}
              error={errors.defaultCycleLengthDays}
              onChange={(e) => handleDetailsChange({
                ...lengthDetails,
                defaultCycleLengthDays: parseInt(e.target.value) || 7
              })}
            />
            {errors.defaultCycleLengthDays && (
              <ErrorMessage>{errors.defaultCycleLengthDays}</ErrorMessage>
            )}

            <PriceGroup>
              <label>Monthly Subscription Price</label>
              <PriceInput error={errors.price}>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={lengthDetails.price}
                  onChange={(e) => handleDetailsChange({
                    ...lengthDetails,
                    price: parseFloat(e.target.value) || ''
                  })}
                  placeholder="0.00"
                />
              </PriceInput>
              <PriceNote>Recurring monthly payment for continued access</PriceNote>
            </PriceGroup>
          </FormGroup>
        )}
      </DurationSelector>
    </StepContainer>
  );
};

export default withStepValidation(ProgramLengthStep);