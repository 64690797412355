import React, { useState } from 'react';
import styled from 'styled-components';
import { EXERCISE_TYPES, EXERCISE_MODES, getTypeConfiguration } from '../../data/exerciseData';

const SelectorContainer = styled.div`
  background: #2a2a2a;
  border-radius: 8px;
  padding: 20px;
  margin-top: 16px;
`;

const StepIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const StepNumber = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: ${props => props.active ? '#4CAF50' : '#3a3a3a'};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  margin-right: 8px;
`;

const StepTitle = styled.h3`
  color: ${props => props.active ? '#ffffff' : '#808080'};
  font-size: 1rem;
  margin: 0;
`;

const SelectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 12px;
  margin: 20px 0;
`;

const SelectionButton = styled.button`
  background: ${props => props.selected ? '#4CAF50' : '#3a3a3a'};
  color: white;
  border: none;
  border-radius: 6px;
  padding: 16px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  &:hover {
    background: ${props => props.selected ? '#45a049' : '#404040'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const NavButton = styled.button`
  background: ${props => props.primary ? '#4CAF50' : '#3a3a3a'};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: ${props => props.primary ? '#45a049' : '#404040'};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ExerciseTypeSelector = ({ onTypeSelect, onModeSelect, onCancel, onComplete }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedMode, setSelectedMode] = useState(null);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
    onTypeSelect(type);
  };

  const handleModeSelect = (mode) => {
    setSelectedMode(mode);
    onModeSelect(mode);
  };

  const getAllowedModes = () => {
    if (!selectedType) return [];
    const config = getTypeConfiguration(selectedType);
    return config ? config.allowedModes : [];
  };

  const formatLabel = (str) => {
    return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleNext = () => {
    if (currentStep === 1 && selectedType) {
      setCurrentStep(2);
    } else if (currentStep === 2 && selectedMode) {
      onComplete({ type: selectedType, mode: selectedMode });
    }
  };

  const handleBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
      setSelectedMode(null);
    } else {
      onCancel();
    }
  };

  return (
    <SelectorContainer>
      <StepIndicator>
        <StepNumber active={currentStep === 1}>1</StepNumber>
        <StepTitle active={currentStep === 1}>Select Exercise Type</StepTitle>
      </StepIndicator>

      {currentStep === 1 && (
        <SelectionGrid>
          {Object.values(EXERCISE_TYPES).map(type => (
            <SelectionButton
              key={type}
              selected={selectedType === type}
              onClick={() => handleTypeSelect(type)}
            >
              {formatLabel(type)}
            </SelectionButton>
          ))}
        </SelectionGrid>
      )}

      <StepIndicator>
        <StepNumber active={currentStep === 2}>2</StepNumber>
        <StepTitle active={currentStep === 2}>Select Exercise Mode</StepTitle>
      </StepIndicator>

      {currentStep === 2 && (
        <SelectionGrid>
          {Object.values(EXERCISE_MODES).map(mode => (
            <SelectionButton
              key={mode}
              selected={selectedMode === mode}
              onClick={() => handleModeSelect(mode)}
              disabled={!getAllowedModes().includes(mode)}
            >
              {formatLabel(mode)}
            </SelectionButton>
          ))}
        </SelectionGrid>
      )}

      <NavigationButtons>
        <NavButton onClick={handleBack}>
          {currentStep === 1 ? 'Cancel' : 'Back'}
        </NavButton>
        <NavButton 
          primary 
          onClick={handleNext}
          disabled={(currentStep === 1 && !selectedType) || (currentStep === 2 && !selectedMode)}
        >
          {currentStep === 1 ? 'Next' : 'Continue'}
        </NavButton>
      </NavigationButtons>
    </SelectorContainer>
  );
};

export default ExerciseTypeSelector;