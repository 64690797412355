// src/components/TrainerPortal/wizard-steps/ProgramTypeStep.js
import React from 'react';
import styled from 'styled-components';

const StepContainer = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

const OptionCard = styled.div`
  border: 2px solid ${props => props.selected ? '#4caf50' : '#eee'};
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: ${props => props.selected ? '#4caf50' : '#ddd'};
    background: ${props => props.selected ? '#f5f5f5' : 'white'};
  }

  h3 {
    margin-bottom: 0.5rem;
    color: #333;
  }

  p {
    color: #666;
    font-size: 0.9rem;
  }
`;

const ProgramTypeStep = ({ data, onNext }) => {
  const handleSelect = (type) => {
    onNext({ type });
  };

  return (
    <StepContainer>
      <Title>Choose Program Type</Title>
      <OptionsGrid>
        <OptionCard 
          selected={data.type === 'simple'}
          onClick={() => handleSelect('simple')}
        >
          <h3>Simple</h3>
          <p>No goals just train and have fun</p>
        </OptionCard>

        <OptionCard 
          selected={data.type === 'progressive'}
          onClick={() => handleSelect('progressive')}
        >
          <h3>Progressive</h3>
          <p>Goal oriented programming</p>
        </OptionCard>

        <OptionCard 
          selected={data.type === 'big_picture'}
          onClick={() => handleSelect('big_picture')}
        >
          <h3>Big Picture</h3>
          <p>Chain training cycles for complex goals</p>
        </OptionCard>
      </OptionsGrid>
    </StepContainer>
  );
};

export default ProgramTypeStep;