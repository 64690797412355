// src/pages/AdvertiserPortal.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/ui/Layout";
import WelcomeBanner from "../components/ui/WelcomeBanner";
import { FaAd, FaChartBar, FaBullseye, FaUsers, 
         FaArrowRight, FaChartLine, FaDollarSign, 
         FaEye } from "react-icons/fa";
import styled from "styled-components";

// Import advertiser portal components
import Campaigns from "../components/AdvertiserPortal/Campaigns";
import Analytics from "../components/AdvertiserPortal/Analytics";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MetricCard = styled.div`
  background: white;
  padding: 1.25rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .metric-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .metric-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }

  .metric-trend {
    font-size: 0.875rem;
    color: ${props => props.trend === 'up' ? '#4caf50' : '#f44336'};
  }
`;

const DashboardCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .card-header {
    padding: 1.5rem;
    background: ${props => props.headerBg || '#f8f9fa'};
    color: ${props => props.headerColor || '#333'};
  }

  .card-content {
    padding: 1.5rem;
  }

  .card-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;

    &:hover {
      color: #4caf50;
      .arrow {
        transform: translateX(4px);
      }
    }

    .arrow {
      transition: transform 0.2s;
    }
  }
`;

const CampaignItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  .campaign-info {
    .name {
      font-weight: 500;
    }
    .status {
      font-size: 0.875rem;
      color: ${props => props.status === 'active' ? '#4caf50' : '#666'};
    }
  }

  .campaign-metrics {
    text-align: right;
    font-size: 0.875rem;
    color: #666;
  }
`;

const AdvertiserPortal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = { name: "John Doe" };

  const advertiserLinks = [
    { path: "/advertiser#campaigns", label: "Campaigns", icon: FaAd },
    { path: "/advertiser#analytics", label: "Analytics", icon: FaChartBar },
  ];

  const renderDashboard = () => {
    return (
      <div>
        <WelcomeBanner
          userName={user.name}
          message="Welcome to your Advertising Portal"
        />
        
        <MetricsGrid>
          <MetricCard trend="up">
            <div className="metric-header">
              <FaEye />
              Total Impressions
            </div>
            <div className="metric-value">125.4K</div>
            <div className="metric-trend">↑ 15% this month</div>
          </MetricCard>

          <MetricCard trend="up">
            <div className="metric-header">
              <FaUsers />
              Click-through Rate
            </div>
            <div className="metric-value">3.2%</div>
            <div className="metric-trend">↑ 0.5% vs last month</div>
          </MetricCard>

          <MetricCard trend="up">
            <div className="metric-header">
              <FaDollarSign />
              Ad Spend
            </div>
            <div className="metric-value">$2,480</div>
            <div className="metric-trend">↑ 12% this month</div>
          </MetricCard>

          <MetricCard trend="up">
            <div className="metric-header">
              <FaBullseye />
              Conversion Rate
            </div>
            <div className="metric-value">2.8%</div>
            <div className="metric-trend">↑ 0.3% this week</div>
          </MetricCard>
        </MetricsGrid>

        <DashboardGrid>
          <DashboardCard 
            headerBg="#2196f3" 
            headerColor="white"
            onClick={() => navigate('/advertiser#campaigns')}
          >
            <div className="card-header">
              <h3>Active Campaigns</h3>
            </div>
            <div className="card-content">
              <CampaignItem status="active">
                <div className="campaign-info">
                  <div className="name">Summer Fitness Challenge</div>
                  <div className="status">Active • 12 days remaining</div>
                </div>
                <div className="campaign-metrics">
                  28.5K impressions
                </div>
              </CampaignItem>
              <CampaignItem status="active">
                <div className="campaign-info">
                  <div className="name">Personal Training Promo</div>
                  <div className="status">Active • 5 days remaining</div>
                </div>
                <div className="campaign-metrics">
                  15.2K impressions
                </div>
              </CampaignItem>
            </div>
            <div className="card-footer">
              View All Campaigns
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>

          <DashboardCard
            headerBg="#4caf50"
            headerColor="white"
            onClick={() => navigate('/advertiser#analytics')}
          >
            <div className="card-header">
              <h3>Performance Overview</h3>
            </div>
            <div className="card-content">
              {/* Add mini performance chart here */}
            </div>
            <div className="card-footer">
              View Detailed Analytics
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>

          <DashboardCard>
            <div className="card-header">
              <h3>Top Performing Ads</h3>
            </div>
            <div className="card-content">
              {/* Add top performing ads list */}
            </div>
            <div className="card-footer">
              View All Ads
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>
        </DashboardGrid>
      </div>
    );
  };

  const renderAdvertiserContent = () => {
    const hash = location.hash.replace('#', '');
    
    switch (hash) {
      case 'campaigns':
        return <Campaigns />;
      case 'analytics':
        return <Analytics />;
      default:
        return renderDashboard();
    }
  };

  return (
    <Layout user={user} links={advertiserLinks}>
      {renderAdvertiserContent()}
    </Layout>
  );
};

export default AdvertiserPortal;