// src/components/dashboard/Subscription.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSubscription } from '../../store/features/subscriptions/subscriptionSlice';
import { getPaymentMethods } from '../../store/features/payments/paymentsSlice';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaCrown, FaCheck, FaDumbbell, FaBrain, FaChartLine, FaUsers, FaStore, FaLock, FaArrowRight, FaCreditCard } from 'react-icons/fa';
import Modal from '../ui/Modal';
import SubscriptionManagement from './SubscriptionManagement';

const SubscriptionContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  color: #1a1a1a;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    background: linear-gradient(135deg, #ff4800 0%, #ff6b00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 1.1rem;
    color: #666;
    max-width: 600px;
    margin: 0 auto;
  }
`;

const PlansGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin: 2rem 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PlanCard = styled.div`
  background: white;
  border-radius: 16px;
  padding: 2rem;
  position: relative;
  transition: all 0.3s ease;
  border: 2px solid ${props => props.isPopular ? '#ff4800' : '#eee'};
  box-shadow: ${props => props.isPopular ? '0 8px 24px rgba(255, 72, 0, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.1)'};

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${props => props.isPopular ? '0 12px 32px rgba(255, 72, 0, 0.2)' : '0 8px 24px rgba(0, 0, 0, 0.15)'};
  }

  .popular-badge {
    position: absolute;
    top: -12px;
    right: 2rem;
    background: #ff4800;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    font-weight: 500;
    font-size: 0.875rem;
  }

  .plan-header {
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eee;

    .plan-icon {
      font-size: 2.5rem;
      margin-bottom: 1rem;
      color: ${props => props.isPopular ? '#ff4800' : '#1a1a1a'};
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    .price {
      font-size: 3rem;
      font-weight: 700;
      color: ${props => props.isPopular ? '#ff4800' : '#1a1a1a'};
      margin-bottom: 0.5rem;

      .period {
        font-size: 1rem;
        color: #666;
      }
    }

    .subtitle {
      color: #666;
    }
  }
`;

const FeaturesList = styled.div`
  margin-bottom: 2rem;

  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0.75rem 0;
    color: #444;

    svg {
      color: ${props => props.isPopular ? '#ff4800' : '#4caf50'};
      flex-shrink: 0;
    }
  }
`;

const CTAButton = styled.button`
  width: 100%;
  background: ${props => props.isPopular ? '#ff4800' : '#1a1a1a'};
  color: white;
  border: none;
  padding: 1rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.isPopular ? '#e64100' : '#333'};
    transform: translateY(-2px);
  }

  svg {
    transition: transform 0.2s;
  }

  &:hover svg {
    transform: translateX(4px);
  }
`;

const ConfirmationModal = styled.div`
  text-align: center;
  color: white;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  p {
    color: #e0e0e0;
    margin-bottom: 2rem;
  }

  .actions {
    display: flex;
    gap: 1rem;
    
    @media (max-width: 480px) {
      flex-direction: column;
    }

    button {
      flex: 1;
      padding: 0.75rem;
      border-radius: 6px;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s;

      &.confirm {
        background: #ff4800;
        color: white;
        border: none;

        &:hover {
          background: #e64100;
        }
      }

      &.cancel {
        background: transparent;
        border: 1px solid #666;
        color: #666;

        &:hover {
          border-color: #999;
          color: #999;
        }
      }
    }
  }
`;

export const ErrorMessage = styled.div`
  background: #fff2f0;
  color: #ff4d4f;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  border: 1px solid #ffccc7;
  font-size: 0.875rem;
`;

const Subscription = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [error, setError] = useState(null);

    const payments = useSelector(state => state.payments || {});
    const subscriptions = useSelector(state => state.subscriptions || {});
    
    const paymentMethods = payments.paymentMethods || [];
    const paymentLoading = payments.loading || false;
    const subscriptionLoading = subscriptions.loading || false;
    
    const hasPaymentMethod = paymentMethods.length > 0;

    const currentSubscription = useSelector(state => state.subscriptions.currentSubscription);


    // Fetch payment methods on component mount
    useEffect(() => {
        dispatch(getPaymentMethods());
    }, [dispatch]);


    const handlePlanSelect = (plan) => {
        setSelectedPlan(plan);
        setShowConfirmation(true);
    };

    const handleSubscribe = async () => {
        if (!hasPaymentMethod) {
            navigate(`/dashboard/billing?return=/dashboard/subscription&plan=${selectedPlan}`);
            return;
        }
    
        try {
            console.log('Submitting subscription with:', {
                tier: selectedPlan,
                paymentMethodId: paymentMethods[0].stripePaymentMethodId
            });
    
            await dispatch(createSubscription({ 
                tier: selectedPlan,
                paymentMethodId: paymentMethods[0].stripePaymentMethodId
            })).unwrap();
            
            setShowConfirmation(false);
        } catch (err) {
            console.error('Subscription failed:', err);
            setError(err.message || 'Failed to create subscription');
        }
    };

    // Add loading and error states to UI
    if (paymentLoading || subscriptionLoading) {
        return <div>Loading standby...</div>;
    }

    if (currentSubscription?.status === 'active') {
      return <SubscriptionManagement subscription={currentSubscription} />;
  }

    return (
        <SubscriptionContainer>
            <Header>
                <h1>Choose Your Path to Success</h1>
                <p>
                    Unlock your full potential with our premium features and take your fitness journey to the next level
                </p>
            </Header>

            <PlansGrid>
                {/* Member Plan - Now Popular */}
                <PlanCard isPopular>
                    <div className="popular-badge">Most Popular</div>
                    <div className="plan-header">
                        <FaCrown className="plan-icon" />
                        <h2>Member</h2>
                        <div className="price">
                            $8.99<span className="period">/mo</span>
                        </div>
                        <p className="subtitle">Perfect for fitness enthusiasts</p>
                    </div>

                    <FeaturesList isPopular>
                        <div className="feature-item">
                            <FaCheck />
                            <span>Unlimited Access to Programs</span>
                        </div>
                        <div className="feature-item">
                            <FaStore />
                            <span>Access to Program Marketplace</span>
                        </div>
                        <div className="feature-item">
                            <FaBrain />
                            <span>AI Nutritional Analysis</span>
                        </div>
                        <div className="feature-item">
                            <FaChartLine />
                            <span>Advanced Analytics</span>
                        </div>
                        <div className="feature-item">
                            <FaLock />
                            <span>Locked-in Pricing</span>
                        </div>
                    </FeaturesList>

                    <CTAButton isPopular onClick={() => handlePlanSelect('member')}>
                        Get Started
                        <FaArrowRight />
                    </CTAButton>
                </PlanCard>

                {/* Trainer Plan - Now Regular */}
                <PlanCard>
                    <div className="plan-header">
                        <FaDumbbell className="plan-icon" />
                        <h2>Trainer</h2>
                        <div className="price">
                            $29.99<span className="period">/mo</span>
                        </div>
                        <p className="subtitle">For fitness professionals</p>
                    </div>

                    <FeaturesList>
                        <div className="feature-item">
                            <FaCheck />
                            <span>All Member Features</span>
                        </div>
                        <div className="feature-item">
                            <FaStore />
                            <span>Sell Programs in Marketplace</span>
                        </div>
                        <div className="feature-item">
                            <FaDumbbell />
                            <span>Custom Program Builder</span>
                        </div>
                        <div className="feature-item">
                            <FaChartLine />
                            <span>Business Analytics</span>
                        </div>
                        <div className="feature-item">
                            <FaUsers />
                            <span>Client Management Tools</span>
                        </div>
                    </FeaturesList>

                    <CTAButton onClick={() => handlePlanSelect('trainer')}>
                        Become a Trainer
                        <FaArrowRight />
                    </CTAButton>
                </PlanCard>
            </PlansGrid>

            <Modal isOpen={showConfirmation} onClose={() => setShowConfirmation(false)}>
                <ConfirmationModal>
                    <h2>Ready to Get Started?</h2>
                    {error && (
                        <ErrorMessage>
                            {error}
                        </ErrorMessage>
                    )}
                    {!hasPaymentMethod ? (
                        <>
                            <p>You'll need to add a payment method to continue.</p>
                            <div className="actions">
                                <button 
                                    className="cancel" 
                                    onClick={() => setShowConfirmation(false)}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="confirm" 
                                    onClick={handleSubscribe}
                                    disabled={subscriptionLoading}
                                >
                                    <FaCreditCard style={{ marginRight: '0.5rem' }} />
                                    {subscriptionLoading ? 'Processing...' : 'Add Payment Method'}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>You've selected the {selectedPlan} plan. Your card ending in {paymentMethods[0].last4} will be charged ${selectedPlan === 'member' ? '8.99' : '29.99'} monthly.</p>
                            <div className="actions">
                                <button 
                                    className="cancel" 
                                    onClick={() => setShowConfirmation(false)}
                                >
                                    Cancel
                                </button>
                                <button 
                                    className="confirm" 
                                    onClick={handleSubscribe}
                                    disabled={subscriptionLoading}
                                >
                                    {subscriptionLoading ? 'Processing...' : 'Confirm Subscription'}
                                </button>
                            </div>
                        </>
                    )}
                </ConfirmationModal>
            </Modal>
        </SubscriptionContainer>
    );
};

export default Subscription;