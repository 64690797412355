

// src/components/dashboard/Billing.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
    getPaymentMethods,
    addPaymentMethod,
    removePaymentMethod,
    makeDefaultPaymentMethod,
} from '../../store/features/payments/paymentsSlice';
import styled from 'styled-components';
import { FaCreditCard, FaHistory, FaFileInvoice, FaTrash, FaClock, FaCheckCircle, FaPlus } from 'react-icons/fa';
import Modal from '../ui/Modal';

const ErrorContainer = styled.div`
  color: #ff4d4f;
  padding: 0.75rem;
  margin-bottom: 1rem;
  background: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 6px;
  font-size: 0.875rem;
`;

const BillingContainer = styled.div`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const SectionHeader = styled.div`
  margin-bottom: 2rem;
  
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #1a1a1a;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

const PaymentMethodsSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const PaymentMethodGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin: 1.5rem 0;

   @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const CardItem = styled.div`
  position: relative;
  background: ${props => props.isDefault 
    ? 'linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%)' 
    : 'linear-gradient(135deg, #2e3745 0%, #486259 100%)'};
  border-radius: 12px;
  padding: 1.5rem;
  color: white; // Change this from conditional to always white
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }

  .card-type {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: white; // Ensure icon is always white
  }

  .card-number {
    font-size: 1.1rem;
    margin: 1rem 0;
  }

  .card-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    font-size: 0.875rem;
    color: #e0e0e0; // Change from conditional to a consistent light gray
  }

  .default-badge {
    position: absolute;
    top: -0.5rem;
    left: 1rem;
    background: #ff4800;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .actions {
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  @media (max-width: 768px) {
    .actions {
      flex-direction: column;
      gap: 0.75rem;

      button {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

const ActionButton = styled.button`
  background: ${props => props.danger ? '#fff2f0' : '#f0f0f0'};
  color: ${props => props.danger ? '#ff4d4f' : '#333'};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.danger ? '#fff1f0' : '#e6e6e6'};
  }
`;

const AddCardButton = styled.button`
  background: #ff4800;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    background: #e64100;
  }
`;

// Credit Card Form in Modal
const CreditCardForm = styled.div`
  .card-preview {
    background: linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    aspect-ratio: 1.586/1;
    position: relative;
    color: white;

    .chip {
      width: 45px;
      height: 35px;
      background: #ffd700;
      border-radius: 6px;
      margin-bottom: 2rem;
    }

    .card-number-preview {
      font-size: 1.4rem;
      letter-spacing: 0.2em;
      margin-bottom: 2rem;
      font-family: monospace;
    }

    .card-details-preview {
      display: flex;
      justify-content: space-between;
      font-size: 0.875rem;
      text-transform: uppercase;
    }
  }

  .form-grid {
    display: grid;
    gap: 1rem;
    margin-top: 2rem;
  }
`;

const FormGroup = styled.div`
  position: relative;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #e0e0e0;
    font-size: 0.875rem;
  }

  input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #333;
    border-radius: 6px;
    background: #262626;
    color: white;
    font-size: 0.875rem;

    &:focus {
      outline: none;
      border-color: #ff4800;
    }
  }
`;

const InlineFormGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
`;

const ExpiryGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;

  button {
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s;

    &.cancel {
      background: transparent;
      color: #666;
      border: 1px solid #333;

      &:hover {
        background: #262626;
      }
    }

    &.save {
      background: #ff4800;
      color: white;
      border: none;

      &:hover {
        background: #e64100;
      }
    }
  }
`;

const BillingHistorySection = styled(PaymentMethodsSection)`
  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #eee;
    }

    th {
      color: #666;
      font-weight: 500;
    }

    td {
      .status {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.25rem 0.75rem;
        border-radius: 12px;
        font-size: 0.875rem;
        
        &.success {
          background: #f6ffed;
          color: #52c41a;
        }

        &.pending {
          background: #fffbe6;
          color: #faad14;
        }
      }

      .invoice-link {
        color: #1890ff;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 0.5rem;

        &:hover {
          color: #40a9ff;
        }
      }
    }
  }


   @media (max-width: 768px) {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    table {
      min-width: 650px; // Ensure table can be scrolled on mobile

      td, th {
        padding: 0.75rem;
        font-size: 0.875rem;
      }

      .status {
        white-space: nowrap;
      }

      .invoice-link {
        white-space: nowrap;
      }
    }
  }
`;

const getStripePromise = () => {
    const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
    if (!key) {
        console.error('Stripe public key is missing. Check your .env file');
        return null;
    }
    return loadStripe(key);
};

const stripePromise = getStripePromise();


const BillingWithStripe = () => {
    const [stripeError, setStripeError] = useState('');

    useEffect(() => {
        if (!process.env.REACT_APP_STRIPE_PUBLIC_KEY) {
            setStripeError('Stripe configuration is missing');
            console.error('Missing Stripe public key. Please check your environment variables.');
        }
    }, []);

    if (stripeError) {
        return <div className="error-message">{stripeError}</div>;
    }

    if (!stripePromise) {
        return <div>Loading payment system...</div>;
    }

    return (
        <Elements stripe={stripePromise}>
            <Billing />
        </Elements>
    );
};

const Billing = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardName, setCardName] = useState('');
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryYear, setExpiryYear] = useState('');
    const [cvv, setCvv] = useState('');
    const [formError, setFormError] = useState('');

    const stripe = useStripe();
    const elements = useElements();

    const dispatch = useDispatch();
    const { paymentMethods, loading, error } = useSelector(state => state.payments);

    useEffect(() => {
        dispatch(getPaymentMethods());
    }, [dispatch]);

    const resetForm = () => {
        setCardNumber('');
        setCardName('');
        setExpiryMonth('');
        setExpiryYear('');
        setCvv('');
        setFormError('');
    };



    const handleModalClose = () => {
        setIsModalOpen(false);
        resetForm();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormError('');

        if (!stripe || !elements) {
            setFormError('Payment system not initialized');
            return;
        }

        try {
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: cardName,
                },
            });

            if (error) {
                console.error('Stripe error:', error);
                setFormError(error.message);
                return;
            }

            // Then add to your backend
            console.log('Created Stripe payment method:', paymentMethod.id);
            await dispatch(addPaymentMethod(paymentMethod.id)).unwrap();
            handleModalClose();
        } catch (err) {
            console.error('Payment error:', err);
            setFormError(err.message || 'Failed to add payment method');
        }
    };

    const handleRemovePaymentMethod = async (paymentMethodId) => {
        try {
            await dispatch(removePaymentMethod(paymentMethodId)).unwrap();
        } catch (err) {
            console.error(err);
        }
    };

    const handleMakeDefault = async (paymentMethodId) => {
        try {
          console.log('Attempting to make default:', paymentMethodId); // Debug log
          await dispatch(makeDefaultPaymentMethod(paymentMethodId)).unwrap();
        } catch (err) {
          console.error('Failed to make payment method default:', err);
          // Add user feedback here if needed
        }
      };


    // ###################################################################



    return (
        <BillingContainer>
            <SectionHeader>
                <h1>Billing & Payments</h1>
                <p>Manage your payment methods and view billing history</p>
            </SectionHeader>

            {error && (
                <ErrorContainer>
                    {error}
                </ErrorContainer>
            )}

            <PaymentMethodsSection>
                <h3>Payment Methods</h3>
                <PaymentMethodGrid>
                    {paymentMethods.map(method => (
                        <CardItem key={method.id} isDefault={method.isDefault}>
                            {/* Show default badge only if card is default */}
                            {method.isDefault && <div className="default-badge">Default</div>}

                            <FaCreditCard className="card-type" />
                            <div className="card-number">•••• •••• •••• {method.last4}</div>
                            <div className="card-details">
                                <span>Expires {method.expiryMonth}/{method.expiryYear}</span>
                                <span>{method.brand}</span>
                            </div>
                            <div className="actions">
                                {/* Show "Make Default" button only if:
         1. Card is not default
         2. There is more than one card */}
                                {!method.isDefault && paymentMethods.length > 1 && (
                                     <ActionButton onClick={() => handleMakeDefault(method.id)}>
                                     Make Default
                                   </ActionButton>
                                )}
                                <ActionButton danger onClick={() => handleRemovePaymentMethod(method.id)}>
                                    <FaTrash />
                                    Remove
                                </ActionButton>
                            </div>
                        </CardItem>
                    ))}
                </PaymentMethodGrid>

                <AddCardButton onClick={() => setIsModalOpen(true)}>
                    <FaPlus />
                    Add Payment Method
                </AddCardButton>
            </PaymentMethodsSection>

            <BillingHistorySection>
                <h3>Billing History</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Jan 1, 2024</td>
                            <td>Monthly Subscription</td>
                            <td>$29.99</td>
                            <td>
                                <span className="status success">
                                    <FaCheckCircle />
                                    Paid
                                </span>
                            </td>
                            <td>
                                <span className="invoice-link">
                                    <FaFileInvoice />
                                    Download
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>Dec 1, 2023</td>
                            <td>Monthly Subscription</td>
                            <td>$29.99</td>
                            <td>
                                <span className="status pending">
                                    <FaClock />
                                    Pending
                                </span>
                            </td>
                            <td>
                                <span className="invoice-link">
                                    <FaFileInvoice />
                                    Download
                                </span>
                            </td>
                        </tr>
                        {/* Add more rows */}
                    </tbody>
                </table>
            </BillingHistorySection>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <CreditCardForm>
                    <h2>Add Payment Method</h2>
                    {formError && (
                        <ErrorContainer>
                            {formError}
                        </ErrorContainer>
                    )}
                    <form onSubmit={handleSubmit}>
                        <FormGroup>
                            <label>Cardholder Name</label>
                            <input
                                type="text"
                                value={cardName}
                                onChange={(e) => setCardName(e.target.value.toUpperCase())}
                                placeholder="JOHN DOE"
                            />
                        </FormGroup>

                        <FormGroup>
                            <label>Card Details</label>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: '16px',
                                            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                            color: 'white',
                                            '::placeholder': {
                                                color: '#aab7c4'
                                            },
                                            padding: '12px',
                                            backgroundColor: '#262626',
                                            border: '1px solid #333',
                                            borderRadius: '6px',
                                            ':-webkit-autofill': {
                                                color: 'white',
                                                backgroundColor: '#262626'
                                            },
                                            '::selection': {
                                                backgroundColor: '#ff4800'
                                            }
                                        },
                                        invalid: {
                                            color: '#ff4d4f',
                                            ':focus': {
                                                color: '#ff4d4f',
                                            },
                                        }
                                    },
                                    hidePostalCode: true
                                }}
                                onChange={(e) => {
                                    if (e.error) {
                                        setFormError(e.error.message);
                                    } else {
                                        setFormError('');
                                    }
                                }}
                            />
                        </FormGroup>

                        <ModalActions>
                            <button type="button" className="cancel" onClick={handleModalClose}>
                                Cancel
                            </button>
                            <button type="submit" className="save" disabled={!stripe || !elements || loading}>
                                {loading ? 'Adding...' : 'Add Card'}
                            </button>
                        </ModalActions>
                    </form>
                </CreditCardForm>
            </Modal>
        </BillingContainer>
    );
};

export default BillingWithStripe;
