// store/features/payments/paymentsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Updated request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // Add CORS headers for development
  if (process.env.NODE_ENV === 'development') {
    config.headers['Access-Control-Allow-Credentials'] = true;
  }
  console.log('Payment API Request:', config); // Debug log
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Enhanced response interceptor
api.interceptors.response.use(
  (response) => {
    console.log('Payment API Response:', response); // Debug log
    return response;
  },
  (error) => {
    console.error('Payment API Error:', error); // Debug log

    if (error.response?.status === 429) {
      return Promise.reject(new Error('Please wait before trying again'));
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('token');
    }

    // Handle CORS errors specifically
    if (error.message.includes('Network Error')) {
      console.error('CORS or Network Error:', error);
      return Promise.reject(new Error('Unable to connect to the server. Please try again later.'));
    }

    return Promise.reject(
      error.response?.data?.message ||
      error.message ||
      'An unexpected error occurred'
    );
  }
);

export const addPaymentMethod = createAsyncThunk(
  "payments/addPaymentMethod",
  async (paymentMethodId, { rejectWithValue }) => {
    try {
      console.log('Adding payment method:', paymentMethodId); // Debug log
      const response = await api.post('/api/payments/methods', { paymentMethodId });
      console.log('Payment method added:', response.data); // Debug log
      return response.data;
    } catch (error) {
      console.error('Add payment method error:', error); // Debug log
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const getPaymentMethods = createAsyncThunk(
  "payments/getPaymentMethods",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/api/payments/methods');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const removePaymentMethod = createAsyncThunk(
  "payments/removePaymentMethod",
  async (paymentMethodId, { rejectWithValue }) => {
    try {
      await api.delete(`/api/payments/methods/${paymentMethodId}`);
      return paymentMethodId;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "payments/createSubscription",
  async (priceId, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/payments/subscriptions', { priceId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "payments/cancelSubscription",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.delete('/api/payments/subscriptions');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const makeDefaultPaymentMethod = createAsyncThunk(
  "payments/makeDefault",
  async (paymentMethodId, { rejectWithValue }) => {
    try {
      const response = await api.patch(`/api/payments/methods/${paymentMethodId}/default`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const initialState = {
  paymentMethods: [],
  subscription: null,
  billingHistory: [],
  loading: false,
  error: null
};

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Add Payment Method
      .addCase(addPaymentMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethods.push(action.payload);
        state.loading = false;
      })
      .addCase(addPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Get Payment Methods
      .addCase(getPaymentMethods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPaymentMethods.fulfilled, (state, action) => {
        state.paymentMethods = action.payload;
        state.loading = false;
      })
      .addCase(getPaymentMethods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Remove Payment Method
      .addCase(removePaymentMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(removePaymentMethod.fulfilled, (state, action) => {
        state.paymentMethods = state.paymentMethods.filter(
          method => method.id !== action.payload
        );
        state.loading = false;
      })
      .addCase(removePaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Make Default Payment Method
      .addCase(makeDefaultPaymentMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(makeDefaultPaymentMethod.fulfilled, (state, action) => {
        state.paymentMethods = state.paymentMethods.map(method => ({
          ...method,
          isDefault: method.id === action.payload.id
        }));
        state.loading = false;
      })
      .addCase(makeDefaultPaymentMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Subscription cases
      .addCase(createSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.subscription = action.payload;
        state.loading = false;
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.subscription = null;
        state.loading = false;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  }
});

export const { clearError } = paymentsSlice.actions;
export default paymentsSlice.reducer;