

import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { IoChevronBackOutline } from "react-icons/io5";

const DrawerContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  height: 100%;
  width: ${({ width }) => width || '50%'};
  max-width: ${({ maxwidth }) => maxwidth || '600px'};
  background-color: ${({ backgroundcolor }) => backgroundcolor || '#1c1c1e'};
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${({ isopen }) => (isopen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isopen }) => (isopen ? "1" : "0")};
  pointer-events: ${({ isopen }) => (isopen ? "auto" : "none")};
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 1002;
`;

const DrawerContent = styled.div`
  padding: 20px;
  padding-top: 60px; // Increased top padding to accommodate the close icon
`;

const SideDrawer = ({ isopen, onClose, children, width, maxwidth, backgroundcolor }) => {
  const drawerRef = useRef();

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isopen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isopen]);

  return (
    <>
      <Overlay isopen={isopen} onClick={onClose} />
      <DrawerContainer 
        ref={drawerRef} 
        isopen={isopen} 
        width={width}
        maxwidth={maxwidth}
        backgroundcolor={backgroundcolor}
      >
        <CloseIconWrapper onClick={onClose}>
          <IoChevronBackOutline size={24} color="#ffffff" />
        </CloseIconWrapper>
        <DrawerContent>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { onClose })
          )}
        </DrawerContent>
      </DrawerContainer>
    </>
  );
};

export default SideDrawer;