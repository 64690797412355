import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectProgramPhases } from '../../../store/features/programs/programsSlice';
import { IoChevronBackOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { FaPlus, FaChevronUp, FaEye } from 'react-icons/fa';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import AddPhaseButton from './AddPhaseButton';

const FooterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2c2c2c;
  border-top: 1px solid #2d2d2d;
  height: ${props => props.isExpanded ? '160px' : '56px'};
  transition: all 0.3s ease-in-out;
  z-index: 50;
  box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1);
`;

const HeaderSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  height: 56px;
`;

const BlockInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BlockLabel = styled.span`
  color: #9ca3af;
`;

const BlockTitle = styled.span`
  color: white;
  font-weight: 500;
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #2d2d2d;
  color: white;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background: #3d3d3d;
  }
`;

const ChevronIcon = styled(FaChevronUp)`
  color: #9ca3af;
  transition: transform 0.3s;
  transform: rotate(${props => props.isExpanded ? '0' : '180deg'});
`;

const PhaseCardsSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  opacity: ${props => props.isExpanded ? '1' : '0'};
  height: ${props => props.isExpanded ? '96px' : '0'};
`;

const ScrollButton = styled.button`
  padding: 8px;
  border-radius: 50%;
  background: rgba(45, 45, 45, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 10;

  &:hover {
    background: rgba(45, 45, 45, 0.7);
  }
`;

const PhaseCardsContainer = styled.div`
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding: 0 16px;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    // background: linear-gradient(to right, transparent, rgba(17, 17, 17, 0.95));
    pointer-events: none;
  }
`;

const PhaseCard = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 6px;
  padding: 12px;
  width: 192px;
  background: ${props => props.isActive ? '#2563eb' : '#2d2d2d'};
  transition: background-color 0.2s;

  &:hover {
    background: ${props => props.isActive ? '#2563eb' : '#3b82f6'};
  }
`;

const PhaseNumber = styled.div`
  color: #9ca3af;
  font-size: 12px;
  margin-bottom: 4px;
`;

const PhaseStats = styled.div`
  color: #d1d5db;
  font-size: 12px;
  margin-top: 4px;
`;


const AddPhaseCard = styled.div`
  flex-shrink: 0;
  cursor: pointer;
  border-radius: 6px;
  padding: 12px;
  width: 192px;
  background: transparent;
  border: 2px dashed #404040;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease-in-out;
  color: #9ca3af;

  &:hover {
    border-color: #2563eb;
    color: #2563eb;
    background: rgba(37, 99, 235, 0.1);
  }

  svg {
    transition: transform 0.2s ease-in-out;
  }

  &:hover svg {
    transform: scale(1.2);
  }
`;


const DraggablePhaseCard = styled(PhaseCard)`
  opacity: ${props => props.isDragging ? 0.5 : 1};
  cursor: move;
`;

const PhaseCardDropZone = styled.div`
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: ${props => props.isOver ? '#2563eb' : 'transparent'};
  }
`;



const PhaseItem = ({ phase, index, currentPhaseId, onPhaseSelect, onPhaseReorder }) => {
  const ref = React.useRef(null);
  
  const [{ isDragging }, drag] = useDrag({
    type: 'PHASE',
    item: { id: phase.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  const [{ isOver }, drop] = useDrop({
    accept: 'PHASE',
    hover(item, monitor) {
      if (!ref.current) return;
      
      const dragIndex = item.index;
      const hoverIndex = index;
      
      if (dragIndex === hoverIndex) return;
      
      onPhaseReorder(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    collect: monitor => ({
      isOver: monitor.isOver()
    })
  });

  drag(drop(ref));

  return (
    <PhaseCardDropZone isOver={isOver}>
      <DraggablePhaseCard
        ref={ref}
        isActive={phase.id === currentPhaseId}
        isDragging={isDragging}
        onClick={() => onPhaseSelect(phase)}
      >
        <PhaseNumber>Phase {phase.phaseNumber}</PhaseNumber>
        <PhaseStats>
          {phase.weekCycles?.length || 0} weeks
        </PhaseStats>
      </DraggablePhaseCard>
    </PhaseCardDropZone>
  );
};

const PhaseFooter = ({ program, onPhaseSelect, onPhaseReorder, onAddPhase }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  
  const phases = useSelector(state => selectProgramPhases(state, program?.id));
  const currentPhase = phases?.find(phase => phase.id === program.currentPhaseId);

  useEffect(() => {
    let timeout;
    if (isHovering) {
      timeout = setTimeout(() => setIsExpanded(true), 100);
    } else {
      timeout = setTimeout(() => setIsExpanded(false), 100);
    }
    return () => clearTimeout(timeout);
  }, [isHovering]);

  const scrollToPhase = (direction) => {
    const container = document.getElementById('phase-cards-container');
    if (container) {
      const scrollAmount = direction === 'next' ? 200 : -200;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  if (!program?.type === 'big_picture') return null;



  const handlePhaseReorder = (dragIndex, hoverIndex) => {
    const reorderedPhases = [...phases];
    const [draggedPhase] = reorderedPhases.splice(dragIndex, 1);
    reorderedPhases.splice(hoverIndex, 0, draggedPhase);
    
    const phaseIds = reorderedPhases.map(phase => phase.id);
    onPhaseReorder(phaseIds);
  };

  return (
    <FooterContainer
      isExpanded={isExpanded}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <HeaderSection>
        <BlockInfo>
          <BlockLabel>
            <FaEye />
            </BlockLabel>
          <BlockTitle>
            {currentPhase ? `Phase ${currentPhase.phaseNumber}` : 'No Phase Selected'}
          </BlockTitle>
        </BlockInfo>
        <HeaderActions>
          <AddButton onClick={onAddPhase}>
            <FaPlus size={12} />
            Add Phase
          </AddButton>
          <ChevronIcon isExpanded={isExpanded} size={14} />
        </HeaderActions>
      </HeaderSection>

      <PhaseCardsSection isExpanded={isExpanded}>
        <ScrollButton onClick={() => scrollToPhase('prev')}>
          <IoChevronBackOutline size={16} />
        </ScrollButton>

        <PhaseCardsContainer id="phase-cards-container">
          {phases?.map((phase, index) => (
            <PhaseItem
              key={phase.id}
              phase={phase}
              index={index}
              currentPhaseId={currentPhase?.id}
              onPhaseSelect={onPhaseSelect}
              onPhaseReorder={handlePhaseReorder}
            />
          ))}
        
        <AddPhaseCard onClick={onAddPhase}>
          <FaPlus size={20} />
          <span>Add New Phase</span>
        </AddPhaseCard>
      </PhaseCardsContainer>

        <ScrollButton onClick={() => scrollToPhase('next')}>
          <IoChevronForwardOutline size={16} />
        </ScrollButton>
      </PhaseCardsSection>
    </FooterContainer>
  );
};

export default PhaseFooter;