// src/components/auth/AuthStateListener.js
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuthStatus, logout } from '../../store/features/auth/authSlice';

export const AuthStateListener = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.auth);

  // Check auth status on mount and token changes
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && !isAuthenticated) {
      dispatch(checkAuthStatus());
    }
  }, [dispatch, isAuthenticated]);

  // Listen for storage events (for multi-tab support)
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === 'token') {
        if (!event.newValue) {
          // Token was removed in another tab
          dispatch(logout());
        } else if (!isAuthenticated) {
          // Token was added in another tab
          dispatch(checkAuthStatus());
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [dispatch, isAuthenticated]);

  // Component doesn't render anything
  return null;
};