import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  sendOTP,
  verifyOTP,
  signupUser,
  clearError,
  checkUsername,
} from "../../../store/features/auth/authSlice";
import styled from "styled-components";

// Styled Components (using your existing styles)
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e2130;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h2`
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 1.8rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Input = styled.input`
  margin: 10px 0;
  padding: 12px;
  width: 100%;
  max-width: 350px;
  border: 1px solid #3a3f5c;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2a2d3e;
  color: #ffffff;
  outline: none;

  &:focus {
    border-color: #bb00ff;
  }
`;

const Button = styled.button`
  margin-top: 20px;
  padding: 12px 20px;
  width: 100%;
  max-width: 350px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #bb00ff;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #d400ff;
  }
`;

const ErrorMessage = styled.p`
  color: #ff4d4d;
  font-size: 14px;
  margin-bottom: 10px;
`;

const InfoText = styled.p`
  margin-bottom: 10px;
  color: #a0a0a0;
`;

const LoadingSpinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #bb00ff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-left: 10px;
  display: inline-block;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SuccessMessage = styled.div`
  background-color: #4CAF50;
  color: white;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  animation: fadeIn 0.3s ease-in;

  @keyframes fadeIn {
    from { opacity: 0; transform: translateY(-10px); }
    to { opacity: 1; transform: translateY(0); }
  }
`;

const PasswordStrengthIndicator = styled.div`
  width: 100%;
  max-width: 350px;
  height: 4px;
  background-color: ${props => {
    if (props.strength === 'strong') return '#4CAF50';
    if (props.strength === 'medium') return '#FFC107';
    return '#FF5252';
  }};
  transition: background-color 0.3s ease;
  margin-top: 5px;
`;

const ForgotPasswordLink = styled(Link)`
  color: #bb00ff;
  text-decoration: none;
  margin-top: 15px;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SignUpPrompt = styled.div`
  margin-top: 20px;
  color: #a0a0a0;
  font-size: 14px;
  
  a {
    color: #bb00ff;
    text-decoration: none;
    margin-left: 5px;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const EmailSignUp = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [localError, setLocalError] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, error } = useSelector((state) => state.auth);
  const [successMessage, setSuccessMessage] = useState("");

  // Password validation constants
  const PASSWORD_REQUIREMENTS = {
    minLength: 8,
    needsUppercase: true,
    needsNumber: true,
  };

  useEffect(() => {
    dispatch(clearError());
    setLocalError("");
  }, [step, dispatch]);

  // Username validation effect
  useEffect(() => {
    if (!username) {
      setUsernameError("");
      return;
    }

    if (!validateUsername(username)) {
      setUsernameError(
        "Username must be 3-20 characters and contain only letters, numbers, and underscores"
      );
      return;
    }

    const checkUsernameAvailability = async () => {
      try {
        setIsCheckingUsername(true);
        const result = await dispatch(checkUsername(username)).unwrap();
        setUsernameError(result.available ? "" : "Username already taken");
      } catch (error) {
        setUsernameError("Error checking username");
      } finally {
        setIsCheckingUsername(false);
      }
    };

    const timeoutId = setTimeout(checkUsernameAvailability, 500);
    return () => clearTimeout(timeoutId);
  }, [username, dispatch]);

  // Password validation effect
  useEffect(() => {
    if (!password) {
      setPasswordError("");
      return;
    }

    const { isValid, checks } = getPasswordStrength(password);

    if (!isValid) {
      const errors = [];
      if (!checks.length) errors.push(`At least ${PASSWORD_REQUIREMENTS.minLength} characters`);
      if (!checks.uppercase) errors.push('One uppercase letter');
      if (!checks.number) errors.push('One number');

      setPasswordError(`Password needs: ${errors.join(', ')}`);
    } else {
      setPasswordError("");
    }
  }, [password]);

  const getPasswordStrength = (password) => {
    const checks = {
      length: password.length >= PASSWORD_REQUIREMENTS.minLength,
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
    };

    const strength =
      Object.values(checks).filter(Boolean).length === 3 ? 'strong' :
        Object.values(checks).filter(Boolean).length >= 2 ? 'medium' : 'weak';

    return {
      isValid: Object.values(checks).every(Boolean),
      checks,
      strength,
    };
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const validateUsername = (username) => {
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    return usernameRegex.test(username);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setLocalError("Please enter a valid email address");
      return;
    }

    try {
      setLocalLoading(true);
      setLocalError("");
      await dispatch(sendOTP(email)).unwrap();
      setSuccessMessage("Verification code sent successfully!");
      setTimeout(() => {
        setSuccessMessage("");
        setStep(2);
      }, 1500);
    } catch (error) {
      setLocalError(error.message || "Failed to send verification code");
    } finally {
      setLocalLoading(false);
    }
  };

  const handleResendOTP = async () => {
    try {
      setLocalLoading(true);
      await dispatch(sendOTP(email)).unwrap();
    } catch (error) {
      setLocalError(error.message || "Failed to resend code");
    } finally {
      setLocalLoading(false);
    }
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await dispatch(
        verifyOTP({
          email,
          code: verificationCode
        })
      ).unwrap();

      if (result.verified) {
        setStep(3);
      }
    } catch (error) {
      setLocalError(error.message || "Failed to verify code");
    }
  };



  const handleFinalSubmit = async (e) => {
    e.preventDefault();
  
    if (usernameError || passwordError) {
      return;
    }
  
    try {
      console.log('Submitting signup form...');
      const result = await dispatch(
        signupUser({
          email,
          password,
          username,
          verificationCode,
        })
      ).unwrap();
  
      console.log('Signup result:', result);
      navigate("/dashboard");
    } catch (error) {
      console.error('Signup submission error:', error);
      setLocalError(error.message || "Failed to create account");
    }
  };


  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <>
            
            <Title>Sign Up with Email</Title>
            {(error || localError) && (
              <ErrorMessage>{error || localError}</ErrorMessage>
            )}
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading || localLoading}
            />
            <Button
              onClick={handleEmailSubmit}
              disabled={loading || localLoading}
            >
              {loading || localLoading ? (
                <>
                  Sending... <LoadingSpinner />
                </>
              ) : (
                "Send Verification Code"
              )}
            </Button>

          </>
        );

      case 2:
        return (
          <>
            <Title>Verify Email</Title>
            {(error || localError) && (
              <ErrorMessage>{error || localError}</ErrorMessage>
            )}
            <InfoText>Please enter the verification code sent to {email}</InfoText>
            <Input
              type="text"
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value.replace(/\D/g, '').slice(0, 6))}
              pattern="\d{6}"
              maxLength="6"
              required
              disabled={loading}
            />
            <Button type="submit" onClick={handleOtpSubmit} disabled={loading || verificationCode.length !== 6}>
              {loading ? (
                <>
                  Verifying... <LoadingSpinner />
                </>
              ) : (
                "Verify Code"
              )}
            </Button>
            <Button
              type="button"
              onClick={handleResendOTP}
              disabled={loading || localLoading}
              style={{ marginTop: '10px', backgroundColor: '#4A5568' }}
            >
              Resend Code
            </Button>
          </>
        );

      case 3:
        return (
          <>
            <Title>Create Account</Title>
            {(error || localError) && (
              <ErrorMessage>{error || localError}</ErrorMessage>
            )}
            <Input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              disabled={loading}
            />
            {isCheckingUsername && <InfoText>Checking username...</InfoText>}
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {usernameError && <ErrorMessage>{usernameError}</ErrorMessage>}

            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={loading}
            />
            {password && (
              <PasswordStrengthIndicator
                strength={getPasswordStrength(password).strength}
              />
            )}
            {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
            {passwordError && <ErrorMessage>{passwordError}</ErrorMessage>}

            <Button
              type="submit"
              onClick={handleFinalSubmit}
              disabled={loading || isCheckingUsername || usernameError || passwordError}
            >
              {loading ? (
                <>
                  Creating Account... <LoadingSpinner />
                </>
              ) : (
                "Create Account"
              )}
            </Button>
          </>
        );

      default:
        return null;
    }
  };

  return <StyledForm onSubmit={(e) => e.preventDefault()}>{renderStepContent()}</StyledForm>;
};

export default EmailSignUp;