import React, { useState } from 'react';
import styled from 'styled-components';
import ExerciseTypeSelector from './ExerciseTypeSelector';
import ExerciseAttributeForm from './ExerciseAttributeForm';

const ModalOverlay = styled.div`
  position: fixed;
  top: 35px;
  left: 0;
  right: 0;
  bottom: 0;
//   background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #2a2a2a;
  border-radius: 8px;
  width: 90%;
  height: 85%;
//   max-width: 600px;
//   max-height: 80vh;
  overflow-y: auto;
  position: relative;
`;

const ModalHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid #3a3a3a;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h2`
  margin: 0;
  color: white;
  font-size: 1.2rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #808080;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: white;
  }
`;

const ModalBody = styled.div`
  padding: 20px;
`;

const ProgressIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ProgressStep = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: ${props => props.active ? '#4CAF50' : '#3a3a3a'};
  margin: 0 4px;
  transition: background-color 0.3s ease;
`;

const ExerciseCreationModal = ({ onClose, onSave, category }) => {
  const [step, setStep] = useState(1);
  const [exerciseType, setExerciseType] = useState(null);
  const [exerciseMode, setExerciseMode] = useState(null);
  const [exerciseAttributes, setExerciseAttributes] = useState(null);

  const handleTypeSelect = (type) => {
    setExerciseType(type);
    setStep(2);
  };

  const handleModeSelect = (mode) => {
    setExerciseMode(mode);
    setStep(3);
  };

  const handleAttributesComplete = (attributes) => {
    setExerciseAttributes(attributes);
    const exercise = {
      type: exerciseType,
      mode: exerciseMode,
      ...attributes
    };
    onSave(exercise);
    onClose();
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <ExerciseTypeSelector
            onTypeSelect={handleTypeSelect}
            onCancel={onClose}
          />
        );
      case 2:
        return (
          <ExerciseTypeSelector
            selectedType={exerciseType}
            onModeSelect={handleModeSelect}
            onBack={() => setStep(1)}
          />
        );
      case 3:
        return (
          <ExerciseAttributeForm
            type={exerciseType}
            mode={exerciseMode}
            onComplete={handleAttributesComplete}
            onBack={() => setStep(2)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>
            {step === 1 && 'Select Exercise Type'}
            {step === 2 && 'Select Exercise Mode'}
            {step === 3 && 'Configure Exercise'}
          </ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          <ProgressIndicator>
            <ProgressStep active={step >= 1} />
            <ProgressStep active={step >= 2} />
            <ProgressStep active={step >= 3} />
          </ProgressIndicator>
          {renderStepContent()}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default ExerciseCreationModal;