import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../../store/features/auth/authSlice";

const LoginFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  margin: 10px 0;
  color: black;
  padding: 12px;
  width: 80%;
  max-width: 350px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  margin-top: 10px;
  padding: 12px 20px;
  width: 80%;
  max-width: 350px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #4285f4;
  color: white;

  &:hover {
    background-color: #357ae8;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
`;

const ForgotPassword = styled.p`
  margin-top: 10px;
  cursor: pointer;
  color: #007bff;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = ({ onClose, onForgotPassword  }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const { error, loading } = useSelector((state) => state.auth);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(loginUser({ email, password })).unwrap();
      console.log("Login result:", resultAction);
      onClose();
      navigate("/dashboard");
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  return (
    <LoginFormContainer onSubmit={handleLogin}>
      <h2>Login</h2>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        disabled={loading}
        required
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={loading}
        required
      />
      <SubmitButton type="submit" disabled={loading}>
        {loading ? "Logging in..." : "Login"}
      </SubmitButton>
      <ForgotPassword onClick={onForgotPassword}>
        Forgot Password?
      </ForgotPassword>
    </LoginFormContainer>
  );
};

export default LoginForm;