import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// Create configured axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    }
});

// Reuse the same interceptor configuration from authSlice
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    if (process.env.NODE_ENV === 'development') {
      config.headers['Access-Control-Allow-Credentials'] = true;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return Promise.reject(
            error.response?.data?.message || 
            error.message || 
            'An unexpected error occurred'
        );
    }
);

// Fetch current user profile
export const fetchCurrentUserProfile = createAsyncThunk(
    "users/fetchCurrentProfile",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/api/users/me');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Fetch specific user profile
export const fetchUserProfile = createAsyncThunk(
    "users/fetchUserProfile",
    async (userId, { rejectWithValue }) => {
        console.log('Fetching user profile for ID:', userId);
        try {
            const response = await api.get(`/api/users/${userId}`);
            console.log('User profile API response:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching user profile:', error);
            console.error('Error details:', {
                status: error.response?.status,
                message: error.response?.data?.message || error.message,
                userId
            });
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Update user profile
export const updateUserProfile = createAsyncThunk(
    "users/updateProfile",
    async (updateData, { rejectWithValue }) => {
        try {
            const response = await api.patch('/api/users/me', updateData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);


export const fetchUserPrograms = createAsyncThunk(
    "programs/fetchUserPrograms",
    async (_, { rejectWithValue, getState }) => {
      try {
        const userId = getState().auth.user?.id;
        
        if (!userId) {
          throw new Error('User ID not found. Please ensure you are logged in.');
        }
  
        console.log('Fetching programs for user:', userId);
        const response = await api.get(`/api/users/${userId}/programs`);
        console.log('Received programs data:', response.data);
        
        return response.data;
      } catch (error) {
        console.error('Failed to fetch programs:', error);
        return rejectWithValue(error.response?.data?.message || error.message);
      }
    }
  );

// Search users
export const searchUsers = createAsyncThunk(
    "users/search",
    async (query, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/users/search?q=${query}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

const usersSlice = createSlice({
    name: "users",
    initialState: {
        profiles: {},
        currentProfile: null,
        searchResults: [],
        loading: false,
        error: null,
        isUpdating: false
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        clearSearchResults: (state) => {
            state.searchResults = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // Current Profile Cases
            .addCase(fetchCurrentUserProfile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCurrentUserProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.currentProfile = action.payload;
                state.profiles[action.payload.id] = action.payload;
                state.error = null;
            })
            .addCase(fetchCurrentUserProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // User Profile Cases
            builder
            .addCase(fetchUserProfile.pending, (state, action) => {
                console.log('Profile fetch pending for:', action.meta.arg);
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                console.log('Profile fetch fulfilled:', action.payload);
                state.loading = false;
                state.profiles[action.payload.id] = action.payload;
                state.error = null;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                console.log('Profile fetch rejected:', action.payload);
                state.loading = false;
                state.error = action.payload;
            })
            // Update Profile Cases
            .addCase(updateUserProfile.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.isUpdating = false;
                state.currentProfile = action.payload;
                state.profiles[action.payload.id] = action.payload;
                state.error = null;
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })
            // Search Cases
            .addCase(searchUsers.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload;
                // Also add users to profiles cache
                action.payload.forEach(user => {
                    state.profiles[user.id] = user;
                });
                state.error = null;
            })
            .addCase(searchUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});


// Selectors
export const selectCurrentProfile = (state) => state.users.currentProfile;
export const selectSearchResults = (state) => state.users.searchResults;
export const selectIsLoading = (state) => state.users.loading;
export const selectIsUpdating = (state) => state.users.isUpdating;
export const selectError = (state) => state.users.error;
export const selectUserProfile = (state, userId) => {
    console.log('Selecting user profile:', {
        userId,
        availableProfiles: Object.keys(state.users?.profiles || {}),
        selectedProfile: state.users?.profiles[userId]
    });
    return state.users?.profiles[userId];
};

export const { clearError } = usersSlice.actions;
export default usersSlice.reducer;