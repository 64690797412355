// src/store/features/routeSlice.js
import { createSlice } from '@reduxjs/toolkit';

const routeSlice = createSlice({
  name: 'route',
  initialState: {
    activeRoute: '/'
  },
  reducers: {
    setActiveRoute: (state, action) => {
      state.activeRoute = action.payload;
    }
  }
});

// Make sure to export both the action creator and the reducer
export const { setActiveRoute } = routeSlice.actions;  // Export actions
export default routeSlice.reducer;  // Export reducer