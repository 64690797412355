// src/routes/AppRoutes.js
import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useSelector } from "react-redux";
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import TrainerPortal from "../pages/TrainerPortal";
import AdvertiserPortal from "../pages/AdvertiserPortal";
import AccountSettings from '../pages/AccountSettings';
import LoadingSpinner from "./LoadingSpinner";

const AppRoutes = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const AuthenticatedRoute = ({ children }) => {
    if (!user) return <Navigate to="/" />;
    return children;
  };

  // Trainer portal routes
  const trainerRoutes = [
    {
      path: "/trainer",
      element: <TrainerPortal />
    },
    {
      path: "/trainer/clients",
      element: <TrainerPortal view="clients" />
    },
    {
      path: "/trainer/schedule",
      element: <TrainerPortal view="schedule" />
    },
    {
      path: "/trainer/analytics",
      element: <TrainerPortal view="analytics" />
    },
    {
      path: "/trainer/programs",
      element: <TrainerPortal view="programs" />
    }
  ];

  // Advertiser portal routes
  const advertiserRoutes = [
    {
      path: "/advertiser",
      element: <AdvertiserPortal />
    },
    {
      path: "/advertiser/campaigns",
      element: <AdvertiserPortal view="campaigns" />
    },
    {
      path: "/advertiser/analytics",
      element: <AdvertiserPortal view="analytics" />
    }
  ];

  return (
    <Routes>
      <Route path="/" element={user ? <Navigate to="/dashboard" /> : <Home />} />
      <Route
        path="/dashboard/*"  // Added wildcard to handle hash routes
        element={
          <AuthenticatedRoute>
            <Dashboard />
          </AuthenticatedRoute>
        }
      />

      {/* Trainer Portal Routes */}
      {trainerRoutes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthenticatedRoute>
              {route.element}
            </AuthenticatedRoute>
          }
        />
      ))}

      {/* Advertiser Portal Routes */}
      {advertiserRoutes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <AuthenticatedRoute>
              {route.element}
            </AuthenticatedRoute>
          }
        />
      ))}


      <Route
        path="/account"
        element={
          <AuthenticatedRoute>
            <AccountSettings />
          </AuthenticatedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;