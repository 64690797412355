import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import EmailSignUp from "./EmailSignUp";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";

const ModalOverlay = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #1e2130;
  margin: auto;
  padding: 40px 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: animateTop 0.4s;
  text-align: center;
  position: relative;

  @keyframes animateTop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
`;

const CloseButton = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;

  &:hover,
  &:focus {
    color: #ffffff;
    text-decoration: none;
  }
`;

const ToggleText = styled.p`
  color: #aaa;
  margin-top: 20px;
  font-size: 14px;

  span {
    color: #4285f4;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
`;





const BackLink = styled.div`
  color: #aaa;
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: "←";
    margin-right: 8px;
  }

  &:hover {
    color: #4285f4;
  }
`;

const AuthModal = ({ show, onClose, initialMode = 'signup' }) => {
    const [mode, setMode] = useState(initialMode);
    const [previousMode, setPreviousMode] = useState(null); // Track previous mode for back navigation
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const showSignup = params.get('showSignup');

    useEffect(() => {
        if (show) {
            setMode(initialMode);
            setPreviousMode(null);
        }
    }, [show, initialMode]);

    if (!show && !showSignup) {
        return null;
    }

    const handleModeChange = (newMode) => {
        setPreviousMode(mode);
        setMode(newMode);
    };

    const handleBack = () => {
        setMode(previousMode || 'login');
        setPreviousMode(null);
    };

    const handleClose = () => {
        onClose();
        setMode(initialMode);
        setPreviousMode(null);
    };

    const renderContent = () => {
        switch (mode) {
            case 'signup':
                return (
                    <>
                        <EmailSignUp />
                        <ToggleText>
                            Already have an account?{' '}
                            <span onClick={() => handleModeChange('login')}>Login here</span>
                        </ToggleText>
                    </>
                );
            case 'login':
                return (
                    <>
                        <LoginForm
                            onClose={handleClose}
                            onForgotPassword={() => handleModeChange('reset-password')}
                        />
                        <ToggleText>
                            Don't have an account?{' '}
                            <span onClick={() => handleModeChange('signup')}>Sign up here</span>
                        </ToggleText>
                    </>
                );
            case 'reset-password':
                return (
                    <>
                        <ResetPassword
                            onClose={handleClose}
                            onBack={() => handleModeChange('login')} // Pass the function to go back to login
                        />
                        <BackLink onClick={handleBack}>
                            Back to Login
                        </BackLink>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <ModalOverlay onClick={handleClose}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
                <CloseButton onClick={handleClose}>&times;</CloseButton>
                {renderContent()}
            </ModalContent>
        </ModalOverlay>
    );
};

export default AuthModal;