import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// Configure axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Reuse interceptor configuration
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API Error:', {
            status: error.response?.status,
            message: error.response?.data?.message,
            error: error
        });

        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return Promise.reject(error.response?.data?.message || error.message);
    }
);

// Fetch exercises
export const fetchExercises = createAsyncThunk(
    "exercises/fetchExercises",
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/api/exercises');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Search exercises
export const searchExercises = createAsyncThunk(
    "exercises/searchExercises",
    async ({ query, mode }, { rejectWithValue }) => {
        try {
            const response = await api.get(`/api/exercises/search`, {
                params: { query, mode }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Create exercise
export const createExercise = createAsyncThunk(
    "exercises/createExercise",
    async (exerciseData, { rejectWithValue }) => {
        try {
            const response = await api.post('/api/exercises', exerciseData);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Update exercise
export const updateExercise = createAsyncThunk(
    "exercises/updateExercise",
    async ({ exerciseId, exerciseData }, { rejectWithValue }) => {
        try {
            const response = await api.patch(
                `/api/exercises/${exerciseId}`,
                exerciseData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Delete exercise
export const deleteExercise = createAsyncThunk(
    "exercises/deleteExercise",
    async (exerciseId, { rejectWithValue }) => {
        try {
            await api.delete(`/api/exercises/${exerciseId}`);
            return exerciseId;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

const exercisesSlice = createSlice({
    name: "exercises",
    initialState: {
        byId: {},
        allIds: [],
        byMode: {
            cardio: [],
            calisthenics: [],
            weight: []
        },
        searchResults: [],
        loading: false,
        error: null,
        filters: {
            mode: null,
            searchTerm: ''
        }
    },
    reducers: {
        setFilter: (state, action) => {
            state.filters = {
                ...state.filters,
                ...action.payload
            };
        },
        clearFilters: (state) => {
            state.filters = {
                mode: null,
                searchTerm: ''
            };
        },
        clearSearchResults: (state) => {
            state.searchResults = [];
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch exercises
            .addCase(fetchExercises.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchExercises.fulfilled, (state, action) => {
                state.loading = false;
                state.byId = {};
                state.allIds = [];
                state.byMode = {
                    cardio: [],
                    calisthenics: [],
                    weight: []
                };
                
                action.payload.forEach(exercise => {
                    state.byId[exercise.id] = exercise;
                    state.allIds.push(exercise.id);
                    state.byMode[exercise.mode].push(exercise.id);
                });
            })
            .addCase(fetchExercises.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            
            // Search exercises
            .addCase(searchExercises.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchExercises.fulfilled, (state, action) => {
                state.loading = false;
                state.searchResults = action.payload;
            })
            .addCase(searchExercises.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            
            // Create exercise
            .addCase(createExercise.fulfilled, (state, action) => {
                const exercise = action.payload;
                state.byId[exercise.id] = exercise;
                state.allIds.push(exercise.id);
                state.byMode[exercise.mode].push(exercise.id);
            })
            
            // Update exercise
            .addCase(updateExercise.fulfilled, (state, action) => {
                const exercise = action.payload;
                // Remove from old mode array if mode changed
                const oldExercise = state.byId[exercise.id];
                if (oldExercise && oldExercise.mode !== exercise.mode) {
                    state.byMode[oldExercise.mode] = state.byMode[oldExercise.mode]
                        .filter(id => id !== exercise.id);
                    state.byMode[exercise.mode].push(exercise.id);
                }
                state.byId[exercise.id] = exercise;
            })
            
            // Delete exercise
            .addCase(deleteExercise.fulfilled, (state, action) => {
                const exerciseId = action.payload;
                const exercise = state.byId[exerciseId];
                if (exercise) {
                    state.byMode[exercise.mode] = state.byMode[exercise.mode]
                        .filter(id => id !== exerciseId);
                    state.allIds = state.allIds.filter(id => id !== exerciseId);
                    delete state.byId[exerciseId];
                }
            });
    }
});

// Selectors
export const selectAllExercises = (state) => state.exercises.allIds.map(id => state.exercises.byId[id]);
export const selectExerciseById = (state, exerciseId) => state.exercises.byId[exerciseId];
export const selectExercisesByMode = (state, mode) => state.exercises.byMode[mode].map(id => state.exercises.byId[id]);
export const selectSearchResults = (state) => state.exercises.searchResults;
export const selectExercisesLoading = (state) => state.exercises.loading;
export const selectExercisesError = (state) => state.exercises.error;
export const selectExerciseFilters = (state) => state.exercises.filters;

export const { setFilter, clearFilters, clearSearchResults } = exercisesSlice.actions;

export default exercisesSlice.reducer;