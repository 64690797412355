


// src/pages/Dashboard.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/ui/Layout";
import styled from "styled-components";
import { FaCreditCard, FaCheck, FaKey, FaUsers, FaGift, FaChartLine, FaBell, FaCrown, FaArrowRight, FaHeart } from "react-icons/fa";
import Billing from "../components/dashboard/Billing";
import Subscription from "../components/dashboard/Subscription";

const DashboardWrapper = styled.div`
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const WelcomeSection = styled.div`
  background: linear-gradient(135deg, #ff4800 0%, #ff6b00 100%);
  border-radius: 16px;
  padding: 2rem;
  color: white;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  p {
    opacity: 0.9;
    max-width: 600px;
  }

  .background-pattern {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40%;
    background: url('/pattern.svg') no-repeat center right;
    opacity: 0.1;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const QuickActions = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
`;

const ActionCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .icon {
    color: #ff4800;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    margin-bottom: 0.5rem;
  }

  p {
    color: #666;
    font-size: 0.875rem;
  }
`;

const SubscriptionCard = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;

    .plan {
      color: #ff4800;
      font-weight: 600;
    }
  }

  .next-payment {
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 0.875rem;
  }
`;

const UpgradePrompt = styled.div`
  background: linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%);
  border-radius: 12px;
  padding: 1.5rem;
  color: white;
  margin-bottom: 1.5rem;

  h3 {
    color: #ff4800;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .features {
    margin: 1rem 0;
    
    .feature {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 0.5rem;
      color: #e5e5e5;
    }
  }

  button {
    background: #ff4800;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s;

    &:hover {
      background: #e64100;
    }
  }
`;

const ReferralSection = styled.div`
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  h3 {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .referral-link {
    background: #f5f5f5;
    padding: 0.75rem;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;

    input {
      border: none;
      background: none;
      flex: 1;
      padding-right: 1rem;
    }

    button {
      background: #ff4800;
      color: white;
      border: none;
      padding: 0.5rem 1rem;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .rewards {
    color: #666;
    font-size: 0.875rem;
  }
`;

const NotificationsSection = styled.div`
  .notification {
    padding: 1rem;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    gap: 1rem;

    .icon {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${props => {
        switch(props.type) {
          case 'warning': return '#fff3e0';
          case 'success': return '#e8f5e9';
          default: return '#e3f2fd';
        }
      }};
      color: ${props => {
        switch(props.type) {
          case 'warning': return '#f57c00';
          case 'success': return '#43a047';
          default: return '#1976d2';
        }
      }};
    }

    .content {
      flex: 1;
      
      .timestamp {
        color: #666;
        font-size: 0.75rem;
      }
    }
  }
`;

const Dashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = { 
    name: "John Doe",
    subscription: {
      status: "active",
      plan: "Basic",
      nextPayment: "2024-02-15",
      amount: "$29.99"
    }
  };

  const renderDashboardContent = () => {
    const hash = location.hash.replace('#', '');
    
    switch (hash) {
      case 'billing':
        return <Billing />;
      case 'subscription':
        return <Subscription />;
      default:
        return (
          <DashboardWrapper>
            <WelcomeSection>
              <div className="background-pattern" />
              <h1>Welcome back, {user.name}!</h1>
              <p>Track your progress, manage your subscriptions, and explore new features to enhance your fitness journey.</p>
            </WelcomeSection>

            <QuickActions>
              <ActionCard onClick={() => navigate('/trainer')}>
                <FaUsers className="icon" />
                <h3>Trainer Portal</h3>
                <p>Manage your clients and programs</p>
              </ActionCard>
              <ActionCard onClick={() => navigate('/advertiser')}>
                <FaChartLine className="icon" />
                <h3>Advertiser Portal</h3>
                <p>Boost your visibility</p>
              </ActionCard>
              <ActionCard onClick={() => navigate('/dashboard#billing')}>
                <FaCreditCard className="icon" />
                <h3>Billing</h3>
                <p>Manage your payments</p>
              </ActionCard>
            </QuickActions>

            <GridContainer>
              <div>
                <SubscriptionCard>
                  <div className="status">
                    <div>
                      <h3>Current Plan</h3>
                      <span className="plan">{user.subscription.plan}</span>
                    </div>
                    <FaCrown size={24} color="#ff4800" />
                  </div>
                  <div className="next-payment">
                    <span>Next payment</span>
                    <span>{user.subscription.amount} on {user.subscription.nextPayment}</span>
                  </div>
                </SubscriptionCard>

                <UpgradePrompt>
                  <h3><FaCrown /> Upgrade to Pro</h3>
                  <p>Take your fitness business to the next level</p>
                  <div className="features">
                    <div className="feature">
                      <FaCheck /> Unlimited clients
                    </div>
                    <div className="feature">
                      <FaCheck /> Advanced analytics
                    </div>
                    <div className="feature">
                      <FaCheck /> Priority support
                    </div>
                  </div>
                  <button onClick={() => navigate('/upgrade')}>
                    Upgrade Now <FaArrowRight />
                  </button>
                </UpgradePrompt>

                <NotificationsSection>
                  <h3>Recent Activity</h3>
                  {/* Add notifications here */}
                </NotificationsSection>
              </div>

              <div>
                <ReferralSection>
                  <h3><FaGift /> Refer a Friend</h3>
                  <p>Share the benefits and earn rewards</p>
                  <div className="referral-link">
                    <input 
                      type="text" 
                      value="https://app.example.com/ref/johndoe" 
                      readOnly 
                    />
                    <button>Copy</button>
                  </div>
                  <div className="rewards">
                    Earn 1 month free for each friend who subscribes!
                  </div>
                </ReferralSection>
                
                {/* Add more sidebar components */}
              </div>
            </GridContainer>
          </DashboardWrapper>
        );
    }
  };

  return (
    <Layout user={user} links={[]}>
      {renderDashboardContent()}
    </Layout>
  );
};

export default Dashboard;