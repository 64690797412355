

import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight, FaAngleRight, FaHome, FaDumbbell, FaAd, FaCog, FaSignOutAlt, FaCrown, FaArrowRight, FaUsers, FaCalendar, FaChartBar, FaClipboardList, FaCreditCard, FaKey } from 'react-icons/fa';
import { ReactComponent as Logo } from "../../assets/Logo.svg";
import { logout } from '../../store/features/auth/authSlice';

const SidebarWrapper = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  width: ${props => props.collapsed ? "80px" : "250px"};
  background-color: #1a1a1a;
  color: white;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 100;

  @media (max-width: 768px) {
    position: fixed;
    width: 70vw;
    transform: translateX(${props => props.collapsed ? "-100%" : "0"});
  }
`;

const SidebarContent = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #1a1a1a;
  }

  &::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 3px;
  }

  @media (max-width: 768px) {
    /* Mobile optimizations */
    font-size: 0.9em;
  }
`;

const Header = styled.div`
  height: 60px;
  background-color: #1a1a1a;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: ${props => props.collapsed ? "flex-start" : "flex-start"};
  padding: 0 ${props => props.collapsed ? "0rem" : "0rem"};
  font-size: 1.2rem;
  font-weight: bold;
  border-bottom: 1px solid #333;
  cursor: pointer;

  &:hover {
    background-color: #222;
  }

  svg {
    width: 50px;
    height: 50px;
    fill: #ff4800;
  }
`;

const MenuSection = styled.div`
  padding: 0.5rem;
  border-bottom: 1px solid #333;

  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`;

const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  color: ${props => props.active ? "#ff4800" : "#666"};
  font-size: 0.75rem;
  text-transform: uppercase;
  transition: all 0.3s ease;

  .title-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
    cursor: pointer;

    &:hover {
      color: #ffffff;
    }
  }

  .arrow-wrapper {
    cursor: pointer;
    padding: 0.5rem;
    margin: -0.5rem;
    
    &:hover {
      color: #ffffff;
    }
  }

  .arrow {
    transition: transform 0.3s ease;
    transform: ${props => props.isExpanded ? 'rotate(90deg)' : 'rotate(0)'};
  }
`;

const MenuItems = styled.div`
  margin-top: 0.5rem;
  max-height: ${props => props.isExpanded ? "500px" : "0"};
  opacity: ${props => props.isExpanded ? "1" : "0"};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  font-size: .8em;
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  padding-left: 2.5rem;
  color: ${props => props.active ? "#e5e5e5" : "#696868"};
  background: ${props => props.active ? "#333" : "transparent"};
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: #ffffff;
  }

  .icon {
    margin-right: 0.75rem;
    width: 20px;
  }

  .label {
    display: ${props => props.collapsed ? "none" : "block"};
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
    padding-left: 2rem;
    
    .icon {
      margin-right: 0.5rem;
      width: 16px;
    }
  }
`;

const Footer = styled.div`
  margin-top: auto;
  border-top: 1px solid #333;
`;

const UserProfile = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
    background: #333;
  }

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #333;
  }

  .user-info {
    display: ${props => props.collapsed ? "none" : "flex"};
    flex-direction: column;
  }

  .user-name {
    font-weight: 600;
    color: #fff;
  }

  .user-email {
    font-size: 0.75rem;
    color: #888;
  }
`;

const ProfileMenu = styled.div`
  background: #262626;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: ${props => props.isOpen ? "500px" : "0"};
`;

const UpgradeContainer = styled.div`
  margin-top: auto;
  display: ${props => props.collapsed ? "none" : "flex"}; // Hide when collapsed
`;

const UpgradeCard = styled.div`
  background: linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%);
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    border-color: #66bb6a;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ff4800;
    margin-bottom: 0.75rem;
  }

  .title {
    font-weight: 600;
    display: ${props => props.collapsed ? "none" : "block"};
  }

  .description {
    color: #888;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    display: ${props => props.collapsed ? "none" : "block"};
  }

  .cta {
    display: ${props => props.collapsed ? "none" : "flex"};
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    background: ff4800;
  }
`;

const Sidebar = ({ collapsed, onFooterClick, onSidebarCollapse }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [expandedSection, setExpandedSection] = useState('dashboard');
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const footerRef = useRef(null);

    const activeRoute = useSelector(state => state.route?.activeRoute) || '/';
    const user = useSelector(state => state.auth?.user) || {
        name: 'John Doe',
        email: 'm@example.com'
    };

    //   useEffects section #######################################

    useEffect(() => {
        if (collapsed) {
            setExpandedSection(null);
        } else {
            // Optionally restore the last expanded section when sidebar expands
            if (location.pathname.startsWith('/trainer')) {
                setExpandedSection('trainer');
            } else if (location.pathname.startsWith('/advertiser')) {
                setExpandedSection('advertiser');
            } else if (location.pathname.startsWith('/dashboard')) {
                setExpandedSection('dashboard');
            }
        }
    }, [collapsed, location.pathname]);

    const handleSectionClick = (key, path) => {
        navigate(path);
        if (window.innerWidth <= 768) {
            onSidebarCollapse(true); // Close sidebar on mobile
        }
    };

    const handleMenuItemClick = (path, hash) => {
        navigate(`${path}${hash}`);
        if (window.innerWidth <= 768) {
            onSidebarCollapse(true); // Close sidebar on mobile
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (footerRef.current && !footerRef.current.contains(event.target)) {
                setProfileMenuOpen(false);
            }
        };

        // Add event listener when menu is open
        if (profileMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [profileMenuOpen]);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
    };

    const sections = {
        dashboard: {
            title: 'Dashboard',
            icon: FaHome,
            path: '/dashboard', // Main route
            items: [
                { icon: FaCreditCard, label: 'Billing', component: 'billing' },
                { icon: FaKey, label: 'Subscriptions', component: 'subscription' },
            ]
        },
        trainer: {
            title: 'Trainer Portal',
            icon: FaDumbbell,
            path: '/trainer',
            items: [
                { icon: FaUsers, label: 'My Clients', component: 'clients' },
                { icon: FaClipboardList, label: 'Program Builder', component: 'program-builder' },
                { icon: FaChartBar, label: 'Sales Metrics', component: 'metrics' }
            ]
        },
        advertiser: {
            title: 'Advertiser Portal',
            icon: FaAd,
            path: '/advertiser',
            items: [
                { icon: FaAd, label: 'Campaigns', component: 'campaigns' },        // matches /advertiser#campaigns
                { icon: FaChartBar, label: 'Analytics', component: 'analytics' }   // matches /advertiser#analytics
            ]
        }
    };

    return (
        <SidebarWrapper collapsed={collapsed}>
            <Header collapsed={collapsed} onClick={() => navigate('/dashboard')}>
                <Logo />
            </Header>

            <SidebarContent>
            {Object.entries(sections).map(([key, section]) => (
                <MenuSection key={key}>
                    <SectionTitle
                        isExpanded={expandedSection === key}
                        active={location.pathname.startsWith(section.path)}
                    >
                        <div
                            className="title-content"
                            onClick={() => handleSectionClick(key, section.path)}
                        >
                            <section.icon />
                            {!collapsed && section.title}
                        </div>
                        {!collapsed && (
                            <div
                                className="arrow-wrapper"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setExpandedSection(expandedSection === key ? null : key);
                                }}
                            >
                                <FaAngleRight className="arrow" />
                            </div>
                        )}
                    </SectionTitle>

                    <MenuItems isExpanded={expandedSection === key}>
                        {section.items.map((item, index) => (
                            <MenuItem
                                key={index}
                                collapsed={collapsed}
                                active={location.pathname.startsWith(section.path) && location.hash === `#${item.component}`}
                                onClick={() => handleMenuItemClick(section.path, `#${item.component}`)}
                            >
                                <item.icon className="icon" />
                                <span className="label">{item.label}</span>
                            </MenuItem>
                        ))}
                    </MenuItems>
                </MenuSection>
            ))}

            <UpgradeContainer collapsed={collapsed}>
                <UpgradeCard collapsed={collapsed} onClick={() => navigate('/upgrade')}>
                    <div className="header">
                        <FaCrown />
                        <span className="title">Upgrade to Pro</span>
                    </div>
                    <div className="description">
                        Get access to premium features and unlock your full potential
                    </div>
                    <div className="cta">
                        <span>Upgrade Now</span>
                        <FaArrowRight />
                    </div>
                </UpgradeCard>
            </UpgradeContainer>
            </SidebarContent>

            <Footer ref={footerRef}> {/* Add ref to Footer */}
                <UserProfile
                    collapsed={collapsed}
                    onClick={() => !collapsed && setProfileMenuOpen(!profileMenuOpen)}
                >
                    <div className="avatar" />
                    <div className="user-info">
                        <span className="user-name">{user.name}</span>
                        <span className="user-email">{user.email}</span>
                    </div>
                </UserProfile>

                <ProfileMenu isOpen={!collapsed && profileMenuOpen}>
                    <MenuItem
                        collapsed={collapsed}
                        onClick={() => {
                            navigate('/account');
                            if (window.innerWidth <= 768) {
                                onSidebarCollapse(true); // Close sidebar on mobile
                            }
                            setProfileMenuOpen(false); // Close the profile menu
                        }}
                    >
                        <FaCog className="icon" />
                        <span className="label">Account Settings</span>
                    </MenuItem>
                    <MenuItem
                        collapsed={collapsed}
                        onClick={handleLogout}
                    >
                        <FaSignOutAlt className="icon" />
                        <span className="label">Logout</span>
                    </MenuItem>
                </ProfileMenu>
            </Footer>
        </SidebarWrapper>
    );
};

export default Sidebar;