// WorkoutCategorySelector.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const CategorySelectorContainer = styled.div`
  margin: 20px 0;
  position: relative;
`;

const AvailableCategoriesButton = styled.button`
  background: #3a3a3a;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.2s ease;

  &:hover {
    background: #4a4a4a;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const CategoriesDropdown = styled.div`
  position: absolute;
  background: #2a2a2a;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 10;
  width: 100%;
  margin-top: 4px;
`;

const CategoryOption = styled.button`
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  color: white;
  text-align: left;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const WorkoutCategorySelector = ({ currentCategories, onAddCategory }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const buttonRef = useRef(null);
    
    const availableCategories = [
        { id: 'corrective', label: 'Corrective' },
        { id: 'activation', label: 'Activation' },
        { id: 'exercises', label: 'Exercises' },
        { id: 'esd', label: 'ESD' }
    ];

    const unusedCategories = availableCategories.filter(
        category => !currentCategories.includes(category.id)
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current && 
                !dropdownRef.current.contains(event.target) &&
                buttonRef.current &&
                !buttonRef.current.contains(event.target)
            ) {
                setShowDropdown(false);
            }
        };

        if (showDropdown) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showDropdown]);

    const handleAddCategory = (categoryId) => {
        onAddCategory(categoryId);
        setShowDropdown(false);
    };

    if (unusedCategories.length === 0) {
        return null;
    }

    return (
        <CategorySelectorContainer>
            <AvailableCategoriesButton
                ref={buttonRef}
                onClick={() => setShowDropdown(!showDropdown)}
            >
                + Category
            </AvailableCategoriesButton>

            {showDropdown && (
                <CategoriesDropdown ref={dropdownRef}>
                    {unusedCategories.map(category => (
                        <CategoryOption
                            key={category.id}
                            onClick={() => handleAddCategory(category.id)}
                        >
                            {category.label}
                        </CategoryOption>
                    ))}
                </CategoriesDropdown>
            )}
        </CategorySelectorContainer>
    );
};

export default WorkoutCategorySelector;