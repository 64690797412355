// src/components/AdvertiserPortal/Analytics.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaCalendar, FaDownload, FaChartLine, FaUsers, 
         FaDollarSign, FaBullseye, FaFilter } from 'react-icons/fa';
import { ResponsiveContainer, LineChart, Line, BarChart, Bar, 
         XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const AnalyticsContainer = styled.div`
  padding: 1.5rem;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .actions {
    display: flex;
    gap: 1rem;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s;
  
  ${props => props.primary ? `
    background: #4caf50;
    color: white;
    border: none;
    
    &:hover {
      background: #43a047;
    }
  ` : `
    background: white;
    color: #333;
    border: 1px solid #ddd;
    
    &:hover {
      background: #f5f5f5;
    }
  `}
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const MetricCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .metric-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
    margin-bottom: 1rem;
  }

  .metric-value {
    font-size: 1.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .metric-comparison {
    font-size: 0.875rem;
    color: ${props => props.trend === 'up' ? '#4caf50' : '#f44336'};
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .metric-period {
    font-size: 0.75rem;
    color: #666;
  }
`;

const ChartContainer = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;

  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .chart-area {
    height: 400px;
  }
`;

const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;

const Tab = styled.button`
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  font-size: 0.875rem;
  color: ${props => props.active ? '#4caf50' : '#666'};
  border-bottom: 2px solid ${props => props.active ? '#4caf50' : 'transparent'};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: #4caf50;
  }
`;

const DataTable = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;

  table {
    width: 100%;
    border-collapse: collapse;

    th, td {
      padding: 1rem;
      text-align: left;
      border-bottom: 1px solid #eee;
    }

    th {
      background: #f8f9fa;
      font-weight: 500;
      color: #666;
    }

    td {
      font-size: 0.875rem;
    }

    .positive {
      color: #4caf50;
    }

    .negative {
      color: #f44336;
    }
  }
`;

const Analytics = () => {
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedPeriod, setSelectedPeriod] = useState('30days');

  const performanceData = [
    { date: '2024-01', impressions: 65000, clicks: 4200, conversions: 210 },
    { date: '2024-02', impressions: 78000, clicks: 5100, conversions: 255 },
    { date: '2024-03', impressions: 95000, clicks: 6300, conversions: 315 },
    // Add more data points...
  ];

  const campaignData = [
    {
      name: 'Summer Fitness Challenge',
      impressions: '28.5K',
      clicks: '1.2K',
      ctr: '4.2%',
      conversions: 45,
      cost: '$1,250',
      roas: '2.8x'
    },
    // Add more campaigns...
  ];

  return (
    <AnalyticsContainer>
      <Header>
        <h2>Analytics</h2>
        <div className="actions">
          <Button>
            <FaCalendar />
            Last 30 Days
          </Button>
          <Button>
            <FaFilter />
            Filter
          </Button>
          <Button primary>
            <FaDownload />
            Export
          </Button>
        </div>
      </Header>

      <MetricsGrid>
        <MetricCard trend="up">
          <div className="metric-header">
            <span>Total Impressions</span>
            <FaChartLine />
          </div>
          <div className="metric-value">238,456</div>
          <div className="metric-comparison">↑ 12.5%</div>
          <div className="metric-period">vs previous period</div>
        </MetricCard>

        <MetricCard trend="up">
          <div className="metric-header">
            <span>Click-through Rate</span>
            <FaUsers />
          </div>
          <div className="metric-value">3.2%</div>
          <div className="metric-comparison">↑ 0.8%</div>
          <div className="metric-period">vs previous period</div>
        </MetricCard>

        <MetricCard trend="down">
          <div className="metric-header">
            <span>Cost per Conversion</span>
            <FaDollarSign />
          </div>
          <div className="metric-value">$28.50</div>
          <div className="metric-comparison">↓ $2.30</div>
          <div className="metric-period">vs previous period</div>
        </MetricCard>

        <MetricCard trend="up">
          <div className="metric-header">
            <span>ROAS</span>
            <FaBullseye />
          </div>
          <div className="metric-value">2.8x</div>
          <div className="metric-comparison">↑ 0.3x</div>
          <div className="metric-period">vs previous period</div>
        </MetricCard>
      </MetricsGrid>

      <TabsContainer>
        <Tab 
          active={activeTab === 'overview'} 
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </Tab>
        <Tab 
          active={activeTab === 'campaigns'} 
          onClick={() => setActiveTab('campaigns')}
        >
          Campaigns
        </Tab>
        <Tab 
          active={activeTab === 'conversions'} 
          onClick={() => setActiveTab('conversions')}
        >
          Conversions
        </Tab>
      </TabsContainer>

      <ChartContainer>
        <div className="chart-header">
          <h3>Performance Trends</h3>
          <div className="actions">
            <Button>
              <FaFilter />
              Metrics
            </Button>
          </div>
        </div>
        <div className="chart-area">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={performanceData}>
              <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="impressions" stroke="#2196f3" />
              <Line type="monotone" dataKey="clicks" stroke="#4caf50" />
              <Line type="monotone" dataKey="conversions" stroke="#ff9800" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </ChartContainer>

      <DataTable>
        <table>
          <thead>
            <tr>
              <th>Campaign</th>
              <th>Impressions</th>
              <th>Clicks</th>
              <th>CTR</th>
              <th>Conversions</th>
              <th>Cost</th>
              <th>ROAS</th>
            </tr>
          </thead>
          <tbody>
            {campaignData.map((campaign, index) => (
              <tr key={index}>
                <td>{campaign.name}</td>
                <td>{campaign.impressions}</td>
                <td>{campaign.clicks}</td>
                <td>{campaign.ctr}</td>
                <td>{campaign.conversions}</td>
                <td>{campaign.cost}</td>
                <td className="positive">{campaign.roas}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </DataTable>
    </AnalyticsContainer>
  );
};

export default Analytics;