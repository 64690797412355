import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// Create configured axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Reuse the same interceptor configuration from authSlice
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (process.env.NODE_ENV === 'development') {
        config.headers['Access-Control-Allow-Credentials'] = true;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return Promise.reject(
            error.response?.data?.message ||
            error.message ||
            'An unexpected error occurred'
        );
    }
);

// Program Types matching backend
export const ProgramEnvironment = {
    ONLINE: 'online',
    IN_PERSON: 'in_person',
    HYBRID: 'hybrid'
};

export const ProgramType = {
    SIMPLE: 'simple',
    PROGRESSIVE: 'progressive',
    BIG_PICTURE: 'big_picture'
};

export const ProgramDuration = {
    FIXED: 'fixed',
    ONGOING: 'ongoing'
};

export const ProgramStatus = {
    DRAFT: 'draft',
    ACTIVE: 'active',
    PAUSED: 'paused',
    COMPLETED: 'completed',
    ARCHIVED: 'archived'
};



// Create program
export const createProgram = createAsyncThunk(
    "programs/createProgram",
    async (programData, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;

            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            const formattedData = {
                name: programData.name,
                environment: programData.environment,
                type: programData.type,
                durationType: programData.durationType,
                defaultCycleLengthDays: programData.defaultCycleLengthDays,
                description: programData.description || undefined,
                price: programData.price || undefined,
                isTemplate: false,
                metadata: {
                    difficulty: programData.metadata.difficulty,
                    targetAudience: programData.metadata.targetAudience || [],
                    experienceRequirements: programData.metadata.experienceRequirements || [],
                    timeCommitment: {
                        sessionsPerWeekMin: programData.metadata.timeCommitment.sessionsPerWeekMin,
                        sessionsPerWeekMax: programData.metadata.timeCommitment.sessionsPerWeekMax,
                        minutesPerSessionMin: programData.metadata.timeCommitment.minutesPerSessionMin,
                        minutesPerSessionMax: programData.metadata.timeCommitment.minutesPerSessionMax,
                    },
                    equipment: programData.metadata.equipment || [],
                    programFocus: programData.metadata.programFocus || [],
                },
                themeColors: programData.themeColors || {
                    primaryGradient: 'linear-gradient(135deg, #4CAF50 0%, #2196F3 100%)',
                    secondaryGradient: 'linear-gradient(135deg, #FF9800 0%, #FF5722 100%)'
                }
            };

            console.log('[Program Creation] Request payload:', {
                endpoint: `/api/users/${userId}/programs`,
                userId,
                formattedData
            });

            try {
                const response = await api.post(`/api/users/${userId}/programs`, formattedData);
                console.log('[Program Creation] Success response:', response);
                return response.data;
            } catch (apiError) {
                console.error('[Program Creation] API Error details:', {
                    status: apiError.response?.status,
                    statusText: apiError.response?.statusText,
                    data: apiError.response?.data,
                    message: apiError.message,
                    config: apiError.config,
                    headers: apiError.response?.headers
                });
                throw apiError;
            }
        } catch (error) {
            const errorDetails = {
                message: error.response?.data?.message || error.message,
                details: error.response?.data || error.toString(),
                status: error.response?.status,
                requestData: error.config?.data
            };

            console.error('[Program Creation] Detailed error:', errorDetails);
            return rejectWithValue(errorDetails);
        }
    }
);

// Fetch user's programs
export const fetchUserPrograms = createAsyncThunk(
    "programs/fetchUserPrograms",
    async (_, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;

            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            const response = await api.get(`/api/users/${userId}/programs`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Update program
export const updateProgram = createAsyncThunk(
    "programs/updateProgram",
    async ({ programId, updates }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;

            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            const formattedUpdates = { ...updates };
            if (updates.durationType) {
                formattedUpdates.totalCycles = updates.durationType === 'fixed'
                    ? updates.totalCycles
                    : null;
            }

            const response = await api.patch(`/api/users/${userId}/programs/${programId}`, formattedUpdates);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Delete program
export const deleteProgram = createAsyncThunk(
    "programs/deleteProgram",
    async (programId, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;

            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            await api.delete(`/api/users/${userId}/programs/${programId}`);
            return programId;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);




export const addWeekCycle = createAsyncThunk(
    "programs/addWeekCycle",
    async ({ programId, weekCycleData }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }

            const response = await api.post(
                `/api/users/${userId}/programs/${programId}/week-cycles`,
                weekCycleData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);



export const addBigPictureWeekCycle = createAsyncThunk(
    "programs/addBigPictureWeekCycle",
    async ({ programId, phaseId, weekCycleData }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }

            // Note the exact route structure
            const response = await api.post(
                `/api/users/${userId}/programs/${programId}/phases/${phaseId}/week-cycles`,
                weekCycleData
            );
            return response.data;
        } catch (error) {
            console.error('Add week cycle error:', error);
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);







export const updateProgramWeeks = createAsyncThunk(
    "programs/updateProgramWeeks",
    async ({ programId, totalCycles }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;

            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            const response = await api.patch(
                `/api/users/${userId}/programs/${programId}`,
                { totalCycles }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);








// export const updatePhaseWeeks = createAsyncThunk(
//     "programs/updatePhaseWeeks",
//     async ({ programId, phaseId, weekCycles }, { rejectWithValue, getState }) => {
//         try {
//             const userId = getState().auth.user?.id;

//             if (!userId) {
//                 throw new Error('User ID not found. Please ensure you are logged in.');
//             }

//             const response = await api.patch(
//                 `/api/users/${userId}/programs/${programId}/phases/weeks`,
//                 { phaseId, weekCycles }
//             );
//             return response.data;
//         } catch (error) {
//             return rejectWithValue(error.response?.data?.message || error.message);
//         }
//     }
// );



// Actions for programCycles#########################

export const fetchProgramPhases = createAsyncThunk(
    "programs/fetchProgramPhases",
    async (programId, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }
            const response = await api.get(`/api/users/${userId}/programs/${programId}/phases`);
            return { programId, phases: response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Create a new program phase
export const createProgramPhase = createAsyncThunk(
    "programs/createProgramPhase",
    async ({ programId, phaseData }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }
            const response = await api.post(
                `/api/users/${userId}/programs/${programId}/phases`,
                phaseData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// Add setCurrentPhase action
export const setCurrentPhase = createAsyncThunk(
    "programs/setCurrentPhase",
    async ({ programId, phaseId }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }
            const response = await api.patch(
                `/api/users/${userId}/programs/${programId}/phases/${phaseId}/current`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);



// Delete week cycles

export const deleteWeekCycle = createAsyncThunk(
    "programs/deleteWeekCycle",
    async ({ programId, cycleId }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }

            const response = await api.delete(
                `/api/users/${userId}/programs/${programId}/week-cycles/${cycleId}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

export const deleteBigPictureWeekCycle = createAsyncThunk(
    "programs/deleteBigPictureWeekCycle",
    async ({ programId, phaseId, cycleId }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found. Please ensure you are logged in.');
            }

            const response = await api.delete(
                `/api/users/${userId}/programs/${programId}/phases/${phaseId}/week-cycles/${cycleId}`
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);



export const reorderProgramPhases = createAsyncThunk(
    "programs/reorderProgramPhases",
    async ({ programId, phaseIds }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) {
                throw new Error('User ID not found');
            }

            const response = await api.patch(
                `/api/users/${userId}/programs/${programId}/phases/reorder`,
                { phaseIds }
            );
            return { programId, ...response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || error.message);
        }
    }
);

// ###########################################################################

const programsSlice = createSlice({
    name: "programs",
    initialState: {
        programs: [],
        currentProgram: null,
        programPhases: {},
        loading: false,
        phasesLoading: false,
        error: null,
        isUpdating: false,
    },
    reducers: {
        setCurrentProgram: (state, action) => {
            state.currentProgram = action.payload;
        },
        clearError: (state) => {
            state.error = null;
        },
        updateDraftProgram: (state, action) => {
            const updates = action.payload;

            // Handle totalCycles in draft updates
            if (updates.durationType === 'ongoing') {
                updates.totalCycles = null;
            }

            state.currentProgram = {
                ...state.currentProgram,
                ...updates
            };
        },

    },
    extraReducers: (builder) => {
        builder
            // Fetch Programs cases
            .addCase(fetchUserPrograms.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserPrograms.fulfilled, (state, action) => {
                state.loading = false;
                state.programs = action.payload;
                state.error = null;
            })
            .addCase(fetchUserPrograms.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Create Program cases
            .addCase(createProgram.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(createProgram.fulfilled, (state, action) => {
                state.isUpdating = false;
                state.programs.push(action.payload);
                state.currentProgram = null;
                state.error = null;
            })
            .addCase(createProgram.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })
            // Update and Delete cases remain the same
            .addCase(updateProgram.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(updateProgram.fulfilled, (state, action) => {
                state.isUpdating = false;
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                if (index !== -1) {
                    state.programs[index] = action.payload;
                }
                if (state.currentProgram?.id === action.payload.id) {
                    state.currentProgram = action.payload;
                }
                state.error = null;
            })
            .addCase(updateProgram.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })
            .addCase(deleteProgram.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(deleteProgram.fulfilled, (state, action) => {
                state.isUpdating = false;
                state.programs = state.programs.filter((p) => p.id !== action.payload);
                if (state.currentProgram?.id === action.payload) {
                    state.currentProgram = null;
                }
                state.error = null;
            })
            .addCase(deleteProgram.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })

            // delete week cycles

            // Delete Simple Week Cycle
            .addCase(deleteWeekCycle.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(deleteWeekCycle.fulfilled, (state, action) => {
                state.isUpdating = false;
                // Update program in state with new data
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                if (index !== -1) {
                    state.programs[index] = {
                        ...state.programs[index],
                        ...action.payload,
                        creator: state.programs[index].creator
                    };
                }
                // Update currentProgram if it matches
                if (state.currentProgram?.id === action.payload.id) {
                    state.currentProgram = {
                        ...state.currentProgram,
                        ...action.payload,
                        creator: state.currentProgram.creator
                    };
                }
                state.error = null;
            })
            .addCase(deleteWeekCycle.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })



            .addCase(deleteBigPictureWeekCycle.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(deleteBigPictureWeekCycle.fulfilled, (state, action) => {
                state.isUpdating = false;
                // Update program in state with new data
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                if (index !== -1) {
                    state.programs[index] = {
                        ...state.programs[index],
                        ...action.payload,
                        creator: state.programs[index].creator
                    };
                }
                // Update currentProgram if it matches
                if (state.currentProgram?.id === action.payload.id) {
                    state.currentProgram = {
                        ...state.currentProgram,
                        ...action.payload,
                        creator: state.currentProgram.creator
                    };
                }
                state.error = null;
            })
            .addCase(deleteBigPictureWeekCycle.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })

            // update program weeks via totalCycles
            .addCase(updateProgramWeeks.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(updateProgramWeeks.fulfilled, (state, action) => {
                state.isUpdating = false;
                // Update in programs array while preserving creator data
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                if (index !== -1) {
                    state.programs[index] = {
                        ...state.programs[index],          // Keep existing data including creator
                        ...action.payload,                 // Update with new data
                        creator: state.programs[index].creator  // Explicitly preserve creator
                    };
                }
                // Update currentProgram if it matches
                if (state.currentProgram?.id === action.payload.id) {
                    state.currentProgram = {
                        ...state.currentProgram,           // Keep existing data including creator
                        ...action.payload,                 // Update with new data
                        creator: state.currentProgram.creator  // Explicitly preserve creator
                    };
                }
                state.error = null;
            })
            .addCase(updateProgramWeeks.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })

            // update phase weeks via totalCycles
            .addCase(addBigPictureWeekCycle.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(addBigPictureWeekCycle.fulfilled, (state, action) => {
                state.isUpdating = false;
                // Update in programs array while preserving creator data
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                if (index !== -1) {
                    state.programs[index] = {
                        ...state.programs[index],          // Keep existing data including creator
                        ...action.payload,                 // Update with new data
                        creator: state.programs[index].creator  // Explicitly preserve creator
                    };
                }
                // Update currentProgram if it matches
                if (state.currentProgram?.id === action.payload.id) {
                    state.currentProgram = {
                        ...state.currentProgram,           // Keep existing data including creator
                        ...action.payload,                 // Update with new data
                        creator: state.currentProgram.creator  // Explicitly preserve creator
                    };
                }
                state.error = null;
            })
            .addCase(addBigPictureWeekCycle.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })



            .addCase(fetchProgramPhases.pending, (state) => {
                state.phasesLoading = true;
                state.phasesError = null;
            })
            .addCase(fetchProgramPhases.fulfilled, (state, action) => {
                state.phasesLoading = false;
                // Update programPhases collection
                state.programPhases[action.payload.programId] = action.payload.phases;

                // Also update the phases in the program object
                const program = state.programs.find(p => p.id === action.payload.programId);
                if (program) {
                    program.phases = action.payload.phases;
                }

                state.phasesError = null;
            })
            .addCase(createProgramPhase.fulfilled, (state, action) => {
                state.isUpdating = false;
                const programId = action.payload.program.id;
                if (!state.programPhases[programId]) {
                    state.programPhases[programId] = [];
                }
                state.programPhases[programId].push(action.payload);
            })
            .addCase(createProgramPhase.rejected, (state, action) => {
                state.isUpdating = false;
                state.phasesError = action.payload;
            })
            .addCase(setCurrentPhase.fulfilled, (state, action) => {
                const program = state.programs.find(p => p.id === action.payload.id);
                if (program) {
                    program.currentPhaseId = action.payload.currentPhaseId;
                }
            })
            .addCase(setCurrentPhase.rejected, (state, action) => {
                state.isUpdating = false;
                state.phasesError = action.payload;
            })


            .addCase(addWeekCycle.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(addWeekCycle.fulfilled, (state, action) => {
                state.isUpdating = false;
                const index = state.programs.findIndex((p) => p.id === action.payload.id);
                
                if (index !== -1) {
                    // Make sure we're getting the full program with weekCycles from the API
                    state.programs[index] = {
                        ...state.programs[index],
                        ...action.payload,
                        weekCycles: action.payload.weekCycles || state.programs[index].weekCycles,
                        creator: state.programs[index].creator
                    };
            
                    if (state.currentProgram?.id === action.payload.id) {
                        state.currentProgram = {
                            ...state.currentProgram,
                            ...action.payload,
                            weekCycles: action.payload.weekCycles || state.currentProgram.weekCycles,
                            creator: state.currentProgram.creator
                        };
                    }
                }
                state.error = null;
            })
            .addCase(addWeekCycle.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })



            // reorder phases 
            .addCase(reorderProgramPhases.pending, (state) => {
                state.isUpdating = true;
                state.error = null;
            })
            .addCase(reorderProgramPhases.fulfilled, (state, action) => {
                state.isUpdating = false;
                
                // Update the phases in programPhases collection
                state.programPhases[action.payload.programId] = action.payload.phases;
                
                // Update phases in the program object
                const program = state.programs.find(p => p.id === action.payload.programId);
                if (program) {
                    program.phases = action.payload.phases;
                }
                
                state.error = null;
            })
            .addCase(reorderProgramPhases.rejected, (state, action) => {
                state.isUpdating = false;
                state.error = action.payload;
            })
    },
});

export const selectAllPrograms = (state) => state.programs.programs;
export const selectProgramById = (state, programId) =>
    state.programs.programs.find(p => p.id === programId);
export const selectCurrentProgram = (state) => state.programs.currentProgram;


export const selectProgramPhases = (state, programId) =>
    state.programs.programPhases[programId] || [];

export const selectCurrentPhase = (state, programId) => {
    const program = state.programs.programs.find(p => p.id === programId);
    return program?.currentPhaseId;
};

export const selectPhasesLoading = (state) => state.programs.phasesLoading;
export const selectPhasesError = (state) => state.programs.phasesError;


export const { setCurrentProgram, clearError, updateDraftProgram, clearPhasesError } = programsSlice.actions;

export default programsSlice.reducer;