import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

// Configure axios instance
const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

// Reuse interceptor configuration
api.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {

        console.error('API Error:', {
            status: error.response?.status,
            message: error.response?.data?.message,
            error: error
        });

        if (error.response?.status === 401) {
            localStorage.removeItem('token');
        }
        return Promise.reject(error.response?.data?.message || error.message);
    }
);

// Enums
export const WorkoutStatus = {
    PLANNED: 'planned',
    IN_PROGRESS: 'in_progress',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled'
};

export const WorkoutType = {
    STRENGTH: 'strength',
    CARDIO: 'cardio',
    MOBILITY: 'mobility',
    RECOVERY: 'recovery',
    ASSESSMENT: 'assessment'
};

// Fetch workouts for a program
export const fetchWorkouts = createAsyncThunk(
    "workouts/fetchWorkouts",
    async (programId, { rejectWithValue, getState }) => {
        try {
            if (!programId || typeof programId !== 'string') {
                throw new Error('Invalid program ID');
            }

            const userId = getState().auth.user?.id;
            if (!userId) throw new Error('User ID not found');

            const response = await api.get(
                `/api/users/${userId}/programs/${programId}/workouts`
            );
            return response.data;
        } catch (error) {
            console.error('Fetch workouts error:', error);
            return rejectWithValue(error.toString());
        }
    }
);

// Create workout
export const createWorkout = createAsyncThunk(
    "workouts/createWorkout",
    async ({ programId, weekCycleId, workout }, { rejectWithValue, getState }) => {
        try {
            if (!programId || !weekCycleId || !workout) {
                throw new Error('Missing required parameters');
            }

            const userId = getState().auth.user?.id;
            if (!userId) throw new Error('User ID not found');

            console.log('Creating workout:', { programId, weekCycleId, workout });
            
            const response = await api.post(
                `/api/users/${userId}/programs/${programId}/week-cycles/${weekCycleId}/workouts`,
                workout
            );

            console.log('Workout created:', response.data);

            return {
                workout: response.data,
                weekCycleId,
                programId
            };
        } catch (error) {
            console.error('Create workout error:', error);
            return rejectWithValue(error.toString());
        }
    }
);

// Update workout
export const updateWorkout = createAsyncThunk(
    "workouts/updateWorkout",
    async ({ programId, workoutId, workoutData }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) throw new Error('User ID not found');

            const response = await api.patch(
                `/api/users/${userId}/programs/${programId}/workouts/${workoutId}`,
                workoutData
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Delete workout
export const deleteWorkout = createAsyncThunk(
    "workouts/deleteWorkout",
    async ({ programId, workoutId }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) throw new Error('User ID not found');

            await api.delete(
                `/api/users/${userId}/programs/${programId}/workouts/${workoutId}`
            );
            return workoutId;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Duplicate workout
export const duplicateWorkout = createAsyncThunk(
    "workouts/duplicateWorkout",
    async ({ programId, workoutId, targetWeekCycleId }, { rejectWithValue, getState }) => {
        try {
            const userId = getState().auth.user?.id;
            if (!userId) throw new Error('User ID not found');

            const response = await api.post(
                `/api/users/${userId}/programs/${programId}/workouts/${workoutId}/duplicate`,
                { targetWeekCycleId }
            );
            return response.data;
        } catch (error) {
            return rejectWithValue(error.toString());
        }
    }
);

// Slice definition
const workoutsSlice = createSlice({
    name: "workouts",
    initialState: {
        byId: {},
        byWeekCycle: {},
        loading: false,
        error: null,
        currentWorkout: null
    },
    reducers: {
        setCurrentWorkout: (state, action) => {
            state.currentWorkout = action.payload;
        },
        clearWorkoutError: (state) => {
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // Fetch workouts
            .addCase(fetchWorkouts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchWorkouts.fulfilled, (state, action) => {
                state.loading = false;
                state.byId = {};
                state.byWeekCycle = {};
                
                action.payload.forEach(workout => {
                    const weekCycleId = workout.weekCycle?.id || workout.weekCycleId;
                    
                    // Store in byId
                    state.byId[workout.id] = workout;
                    
                    // Maintain week cycle relationship
                    if (!state.byWeekCycle[weekCycleId]) {
                        state.byWeekCycle[weekCycleId] = [];
                    }
                    state.byWeekCycle[weekCycleId].push(workout.id);
                });
            })
            .addCase(fetchWorkouts.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Create workout
            .addCase(createWorkout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createWorkout.fulfilled, (state, action) => {
                state.loading = false;
                const weekCycleId = action.payload.weekCycle?.id || action.payload.weekCycleId;
                
                // Store in byId
                state.byId[action.payload.id] = {
                    ...action.payload,
                    weekCycleId
                };
                
                // Maintain week cycle relationship
                if (!state.byWeekCycle[weekCycleId]) {
                    state.byWeekCycle[weekCycleId] = [];
                }
                state.byWeekCycle[weekCycleId].push(action.payload.id);
            })
            .addCase(createWorkout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Update workout
            .addCase(updateWorkout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateWorkout.fulfilled, (state, action) => {
                state.loading = false;
                state.byId[action.payload.id] = {
                    ...state.byId[action.payload.id],
                    ...action.payload
                };
                if (state.currentWorkout?.id === action.payload.id) {
                    state.currentWorkout = action.payload;
                }
            })
            .addCase(updateWorkout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete workout
            .addCase(deleteWorkout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteWorkout.fulfilled, (state, action) => {
                state.loading = false;
                delete state.byId[action.payload];
                if (state.currentWorkout?.id === action.payload) {
                    state.currentWorkout = null;
                }
            })
            .addCase(deleteWorkout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Duplicate workout
            .addCase(duplicateWorkout.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(duplicateWorkout.fulfilled, (state, action) => {
                state.loading = false;
                state.byId[action.payload.id] = action.payload;
            })
            .addCase(duplicateWorkout.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }
});

// Selectors
export const selectWorkoutById = (state, workoutId) => state.workouts.byId[workoutId];
export const selectWorkoutsByProgramId = (state, programId) => {
    return Object.values(state.workouts.byId)
        .filter(workout => workout.programId === programId)
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder);
};
export const selectWorkoutsByWeekCycle = (state, weekCycleId) => {
    if (!weekCycleId || typeof weekCycleId !== 'string') {
        console.warn('Invalid weekCycleId provided to selectWorkoutsByWeekCycle');
        return [];
    }

    if (!state?.workouts?.byWeekCycle || !state?.workouts?.byId) {
        return [];
    }

    const workoutIds = state.workouts.byWeekCycle[weekCycleId] || [];
    
    return workoutIds
        .map(id => {
            const workout = state.workouts.byId[id];
            if (!workout) {
                console.warn(`Workout ${id} not found in byId store`);
                return null;
            }
            return workout;
        })
        .filter(Boolean)
        .sort((a, b) => a.sequenceOrder - b.sequenceOrder);
};
export const selectCurrentWorkout = (state) => state.workouts.currentWorkout;
export const selectWorkoutsLoading = (state) => state.workouts.loading;
export const selectWorkoutsError = (state) => state.workouts.error;

export const { setCurrentWorkout, clearWorkoutError } = workoutsSlice.actions;

export default workoutsSlice.reducer;



// ##############################################################



