import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FaEllipsisV } from 'react-icons/fa';
import { fetchUserProfile, selectUserProfile } from '../../../store/features/user/userSlice';


const Card = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 24px;
  overflow: hidden;
  aspect-ratio: 1;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 
    0 10px 20px rgba(0, 0, 0, 0.15),
    0 3px 6px rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.1);

  &:hover {
    transform: translateY(-4px);
    box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.2),
      0 5px 15px rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  }
`;

const CardBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.gradient || `
    linear-gradient(
      135deg,
      rgba(255, 140, 255, 0.9) 0%,
      rgba(147, 51, 234, 0.85) 50%,
      rgba(24, 24, 27, 0.8) 100%
    )
  `};
  
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      transparent 0%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
`;

const CardContent = styled.div`
  position: relative;
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  z-index: 1;
`;

const ProgramName = styled.h3`
  font-size: 1.75rem;
  font-weight: 600;
  margin: 0 0 2rem 0;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const AuthorSection = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProfileImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${props => props.imageUrl ?
    `url(${props.imageUrl}) center/cover no-repeat` :
    'linear-gradient(45deg, #e0e0e0, #f5f5f5)'};
  border: 2px solid rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:after {
    content: '${props => !props.imageUrl ? props.initials : ''}';
    color: #757575;
    font-size: 16px;
    font-weight: 500;
  }
`;

const AuthorName = styled.span`
  font-size: 1rem;
  font-weight: 500;
  color: white;
`;

const OptionsContainer = styled.div`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
`;

const OptionsButton = styled.button`
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: linear-gradient(135deg, #2e3745 0%, #1a1a1a 100%);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  min-width: 180px;
  z-index: 10;
  overflow: hidden;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

const MenuItem = styled.button`
  width: 100%;
  padding: 1rem;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  color: ${props => props.danger ? '#dc3545' : 'white'};
  font-size: 0.875rem;
  transition: background-color 0.2s;

  &:hover {
    // background: #f8f9fa;
    font-size: 0.95rem;
  }
`;

const ProgramCard = ({ program, onEdit, onDelete, onView, onClick }) => {
  const dispatch = useDispatch();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const optionsRef = useRef(null);
  const creator = program.creator || {};
  const { username, profilePicture } = creator;
  const token = localStorage.getItem('token');
  const user = useSelector(state => state.auth.user);

  const creatorId = program.creatorId;
  console.log('Program data:', {
    programId: program.id,
    creatorId: program.creatorId,
    programName: program.name
  });

  const userProfile = useSelector(state => {
    console.log('Current Redux state:', state);
    return selectUserProfile(state, creatorId);
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
      }
    };

    if (isOptionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOptionsOpen]);

  useEffect(() => {
    console.log('ProgramCard useEffect triggered:', {
      creatorId,
      hasUserProfile: !!userProfile
    });

    if (creatorId && !userProfile) {
      console.log('Dispatching fetchUserProfile for:', creatorId);
      dispatch(fetchUserProfile(creatorId));
    }
  }, [creatorId, dispatch, userProfile]);

  console.log('Creator data:', {
    username,
    profilePicture,
    fullCreator: creator
  });

  const handleOptionClick = (action) => {
    setIsOptionsOpen(false);
    action();
  };

  const handleCardClick = (e) => {
    if (e.target.closest('[data-options]')) return;
    
    if (!token || !user) {
      console.error('Authentication required');
      // Handle re-auth here
      return;
    }
    
    onClick(program);
  };

  const getInitials = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const initials = getInitials(username);

  return (
    <Card onClick={handleCardClick}>
      <CardBackground gradient={program.themeColors?.primaryGradient} />
      <CardContent>
        <ProgramName>{program.name}</ProgramName>
        <AuthorSection>
          <ProfileImage
            imageUrl={profilePicture}
            initials={initials}
          />
          <AuthorName>{username || 'Anonymous'}</AuthorName>
        </AuthorSection>

        <OptionsContainer ref={optionsRef}>
          <OptionsButton onClick={(e) => {
            e.stopPropagation();
            setIsOptionsOpen(!isOptionsOpen);
          }}>
            <FaEllipsisV />
          </OptionsButton>
          <OptionsMenu isOpen={isOptionsOpen}>
          <MenuItem 
              onClick={(e) => {
                e.stopPropagation();
                setIsOptionsOpen(false);
                onView(program);
              }}
            >
              View Details
            </MenuItem>
            <MenuItem 
              onClick={(e) => {
                e.stopPropagation();
                setIsOptionsOpen(false);
                onEdit(program);
              }}
            >
              Edit Program
            </MenuItem>
            <MenuItem
              danger
              onClick={(e) => {
                e.stopPropagation();
                setIsOptionsOpen(false);
                onDelete(program);
              }}
            >
              Delete Program
            </MenuItem>
          </OptionsMenu>
        </OptionsContainer>
      </CardContent>
    </Card>
  );
};

export default ProgramCard;