import React, { useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";

const DrawerContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpen",
})`
  position: fixed;
  // display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  max-width: 800px;
  // background-color: #1c1c1e;
  // box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 100;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px 0px 6px;
  background-color: #333;
  margin: 16px;
  width: 6%;
  border-radius: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    // background-color: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const DrawerContent = styled.div`
  flex: 1;
  overflow-y: auto;
  height: 100%;
  // display: flex;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== "isOpen",
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
`;

const ProgramWorkoutDrawer = ({ isOpen, onClose, children, disableBackdropClick }) => {
  const drawerRef = useRef();
  const [isInteracting, setIsInteracting] = useState(false);

  const handleClose = (event) => {
    if (disableBackdropClick || isInteracting) {
      return;
    }
    
    if (event && event.target === event.currentTarget) {
      event.stopPropagation();
      onClose();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (disableBackdropClick || isInteracting) {
        return;
      }

      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        handleClose(event);
      }
    };

    const handleDrawerInteraction = () => {
      setIsInteracting(true);
    };

    const handleDrawerInteractionEnd = () => {
      setIsInteracting(false);
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      if (drawerRef.current) {
        drawerRef.current.addEventListener("mousedown", handleDrawerInteraction);
        drawerRef.current.addEventListener("mouseleave", handleDrawerInteractionEnd);
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (drawerRef.current) {
        drawerRef.current.removeEventListener("mousedown", handleDrawerInteraction);
        drawerRef.current.removeEventListener("mouseleave", handleDrawerInteractionEnd);
      }
    };
  }, [isOpen, disableBackdropClick, onClose, isInteracting]);

  const drawerContent = (
    <>
      <Overlay 
        isOpen={isOpen} 
        onClick={disableBackdropClick || isInteracting ? null : handleClose} 
      />
      <DrawerContainer 
        ref={drawerRef} 
        isOpen={isOpen}
        onMouseDown={(e) => e.stopPropagation()}
      >
       <DrawerHeader>
          <CloseButton onClick={onClose}>
            <FaChevronRight />
          </CloseButton>
        </DrawerHeader>
        <DrawerContent>
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { 
              onClose: handleClose,
              setIsInteracting
            })
          )}
        </DrawerContent>
      </DrawerContainer>
    </>
  );

  return ReactDOM.createPortal(drawerContent, document.body);
};

export default ProgramWorkoutDrawer;