// data/sampleClients.js

export const sampleClients = [
    {
      id: 1,
      username: 'john_doe',
      email: 'john@example.com',
      fullName: 'John Doe',
      avatarUrl: 'https://via.placeholder.com/150',
      status: 'Active',
      role: 'Admin',
      contactInfo: {
        phone: '(123) 456-7890',
        secondaryPhone: '(098) 765-4321',
        address: {
          street: '123 Main St',
          city: 'Cityville',
          state: 'ST',
          zipCode: '12345',
          country: 'USA',
        },
        socialMedia: {
          twitter: 'https://twitter.com/johndoe',
          linkedin: 'https://linkedin.com/in/johndoe',
        },
      },
      accountDetails: {
        accountId: 'ACC123456',
        createdAt: '2022-01-15T08:30:00Z',
        lastLogin: '2024-11-25T14:20:00Z',
        subscriptionType: 'Premium',
        subscriptionStatus: 'Active',
        billingInfo: {
          billingCycle: 'Monthly',
          paymentMethod: 'Credit Card',
          nextBillingDate: '2024-12-15T00:00:00Z',
        },
      },
      preferences: {
        language: 'English',
        timezone: 'UTC-5',
        communication: {
          email: true,
          sms: false,
          pushNotifications: true,
        },
      },
      activityLogs: [
        {
          date: '2024-11-25T14:20:00Z',
          activity: 'Logged in',
          details: 'User logged in from IP 192.168.1.1',
        },
        {
          date: '2024-11-20T10:15:00Z',
          activity: 'Placed Order',
          details: 'Order #456 placed for $250.00',
        },
        {
          date: '2024-11-18T09:00:00Z',
          activity: 'Updated Profile',
          details: 'Changed email to john@example.com',
        },
      ],
      notes: [
        {
          createdAt: '2024-11-20T10:30:00Z',
          author: 'Admin',
          content: 'Client requested a demo of new features.',
        },
        {
          createdAt: '2024-11-18T09:30:00Z',
          author: 'Support',
          content: 'Resolved issue with account access.',
        },
      ],
      tags: ['VIP', 'Beta Tester'],
      lastOrderDate: '2024-11-20T10:15:00Z',
      totalOrders: 15,
      totalSpent: 1250.0,
      customFields: {
        loyaltyPoints: 500,
        referralSource: 'Friend',
      },
    },
    {
      id: 2,
      username: 'jane_smith',
      email: 'jane@example.com',
      fullName: 'Jane Smith',
      avatarUrl: 'https://via.placeholder.com/150',
      status: 'Inactive',
      role: 'User',
      contactInfo: {
        phone: '(321) 654-0987',
        address: {
          street: '456 Elm St',
          city: 'Townsville',
          state: 'TS',
          zipCode: '67890',
          country: 'USA',
        },
        socialMedia: {
          facebook: 'https://facebook.com/janesmith',
        },
      },
      accountDetails: {
        accountId: 'ACC654321',
        createdAt: '2023-03-22T11:00:00Z',
        lastLogin: '2024-11-20T16:45:00Z',
        subscriptionType: 'Basic',
        subscriptionStatus: 'Expired',
        billingInfo: {
          billingCycle: 'Yearly',
          paymentMethod: 'PayPal',
          nextBillingDate: '2024-12-01T00:00:00Z',
        },
      },
      preferences: {
        language: 'Spanish',
        timezone: 'UTC-6',
        communication: {
          email: false,
          sms: true,
          pushNotifications: false,
        },
      },
      activityLogs: [
        {
          date: '2024-11-20T16:45:00Z',
          activity: 'Logged in',
          details: 'User logged in from IP 192.168.1.2',
        },
        {
          date: '2024-11-18T14:00:00Z',
          activity: 'Cancelled Subscription',
          details: 'Subscription cancelled by user.',
        },
      ],
      notes: [
        {
          createdAt: '2024-11-18T14:15:00Z',
          author: 'Admin',
          content: 'User cancelled subscription due to pricing concerns.',
        },
      ],
      tags: ['Churned'],
      lastOrderDate: '2023-12-05T12:00:00Z',
      totalOrders: 5,
      totalSpent: 300.0,
      customFields: {
        loyaltyPoints: 150,
        referralSource: 'Online Ad',
      },
    },
    // Add more sample clients as needed
  ];
  