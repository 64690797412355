// AddPhaseButton.js
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { createProgramPhase } from '../../../store/features/programs/programsSlice';

const AddCard = styled.div`
  min-width: 140px;
  background-color: #3a3a3a;
  padding: 16px;
  border-radius: 8px;
  border: 2px dashed #aaa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #aaa;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
    color: #fff;
  }
`;

const AddPhaseText = styled.h3`
  margin: 8px 0 0 0;
  font-size: 16px;
  color: inherit;
`;

const AddPhaseButton = ({ programId, program, programPhases }) => {
  const dispatch = useDispatch();

  const handleAddPhase = async () => {
    try {
      if (!program || !programId) {
        console.error('[Phase Creation] Missing required data:', { program, programId });
        return;
      }

      const nextPhaseNumber = (programPhases?.length || 0) + 1;
      
      // Only include required fields
      const phaseData = {
        name: `Phase ${nextPhaseNumber}`,
        phaseNumber: nextPhaseNumber
      };

      console.log('[Phase Creation] Sending request:', {
        programId,
        phaseData
      });

      const result = await dispatch(createProgramPhase({
        programId,
        phaseData
      })).unwrap();

      console.log('[Phase Creation] Success:', {
        phaseId: result.id,
        phaseName: result.name,
        phaseNumber: result.phaseNumber
      });
      
    } catch (error) {
      console.error('[Phase Creation] Failed:', {
        error,
        message: error.message,
        details: error.response?.data
      });
    }
  };

  return (
    <AddCard onClick={handleAddPhase}>
      <AddPhaseText>+ Add Phase</AddPhaseText>
    </AddCard>
  );
};

export default AddPhaseButton;