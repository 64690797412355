

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import SideDrawer from '../../SideDrawer';
// import ProgramDescriptionEditor from '../program/ProgramDescriptionEditor';
import AddPhaseButton from './AddPhaseButton'; // Importing AddPhaseButton
import {
  selectCurrentProgram,
  selectProgramPhases,  // Changed from selectProgramCycles
  fetchProgramPhases,   // Changed from fetchProgramCycles
  setCurrentPhase,      // New import
  ProgramType,
  updateProgram,
  fetchUserPrograms
} from '../../../store/features/programs/programsSlice';
import PhaseCard from './PhaseCard';


// Styled Components for Modal
// const ModalOverlay = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0,0,0,0.9); // Darker background
//   display: ${(props) => (props.isOpen ? 'block' : 'none')};
//   z-index: 1000;
//   overflow: hidden; // Prevent scrolling when modal is open
// `;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;
  overflow: hidden;
  pointer-events: auto; // Ensure overlay captures pointer events
  isolation: isolate; // Create a new stacking context
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 12px;
  right: 16px;
  background: transparent;
  color: #fff;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
`;

// Styled Components from CarouselMeso.js
const Carousel = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  // touch-action: pan-y;
  // touch-action: pan-x; 
  touch-action: none;


  @media (max-width: 768px) {
    height: auto;
    min-height: 100vh;
  }
`;

const CarouselItem = styled.div`
  --width: clamp(150px, 30vw, 300px);
  --height: clamp(200px, 40vw, 400px);
  --x: calc(var(--active) * 100%);
  --y: calc(var(--active) * 20%);
  --rot: calc(var(--active) * 12deg);
  --opacity: calc(var(--zIndex) / ${({ itemCount }) => itemCount} * 3 - 2);
  position: absolute;
  z-index: var(--zIndex);
  width: var(--width);
  height: var(--height);
  margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
  border-radius: 10px;
  top: 40%;
  left: 50%;
  user-select: none;
  transform-origin: 0% 100%;
  box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
  background: black;
  pointer-events: all;
  transform: translate(var(--x), var(--y)) rotate(var(--rot));
  transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);

  @media (max-width: 768px) {
    --width: clamp(200px, 30vw, 300px);
    --height: clamp(250px, 40vw, 400px);
  }
`;

const CarouselBox = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  opacity: var(--opacity);
  font-family: 'Orelo-sw-db', serif;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0) 30%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.5)
    );
  }

  @media (max-width: 768px) {
    opacity: 1;
  }
`;

const Title = styled.div`
  position: absolute;
  z-index: 1;
  color: #fff;
  bottom: 20px;
  left: 20px;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  font-size: clamp(20px, 3vw, 30px);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
`;

const Num = styled.div`
  position: absolute;
  z-index: 1;
  color: #fff;
  top: 10px;
  left: 20px;
  transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
  font-size: clamp(20px, 10vw, 80px);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;

const Cursor = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  --size: 40px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  margin: calc(var(--size) * -0.5) 0 0 calc(var(--size) * -0.5);
  transition: transform 0.85s cubic-bezier(0, 0.02, 0, 1);
  display: none;
  pointer-events: none;

  @media (pointer: fine) {
    display: block;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Cursor2 = styled(Cursor)`
  --size: 2px;
  transition-duration: 0.7s;
`;

const RemoveButton = styled.button`
  background-color: #ff4136;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #e60000;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const GoToProgramButton = styled.button`
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AddPhaseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;

  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
  }
`;

const AddButtonContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3a3a3a; /* Match the carousel item background */
  border-radius: 10px;
`;


const ProgramPhaseCarousel = ({ isOpen, onClose, onTabChange, program, programId  }) => {
  const dispatch = useDispatch();
  const programPhases = useSelector(state => selectProgramPhases(state, programId)); // Changed from programCycles


  // State management
  const [progress, setProgress] = useState(50);
  const [active, setActive] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [verticalProgress, setVerticalProgress] = useState(50);
  const [activeY, setActiveY] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  // Refs
  const carouselRef = useRef(null);
  const itemsRef = useRef([]);
  const cursorsRef = useRef([]);

  // Constants
  const speedWheel = 0.1;
  const speedDrag = -0.1;
  const speedDragY = -0.1;

  // Fetch program cycles on mount
  useEffect(() => {
    if (programId && program?.type === ProgramType.BIG_PICTURE) {
      dispatch(fetchProgramPhases(programId));
    }
  }, [dispatch, programId, program?.type]);

  const renderAddPhaseButton = () => (
    <AddPhaseButton
    programId={program?.id}
    program={program}
    programCycles={programPhases}
    />
  );

  // Create items list
  const phaseList = useMemo(() => {
    if (!program || program.type !== ProgramType.BIG_PICTURE) return [];
    return programPhases.map(phase => ({
      id: phase.id,
      title: `Phase ${phase.phaseNumber}`,
      type: 'programPhase',
      img: '/api/placeholder/300/400',
      phaseNumber: phase.phaseNumber,
      status: phase.status,
      plannedStartDate: phase.plannedStartDate,
      actualStartDate: phase.actualStartDate,
      completionDate: phase.completionDate,
      cycleLengthDays: phase.cycleLengthDays,
      isCurrent: program.currentPhaseId === phase.id
    }));
  }, [program, programPhases]);


  // Handle cycle selection
  const handlePhaseSelect = useCallback(async (phase) => {
    if (!program?.id || !phase?.id) return;
  
    try {
      await dispatch(setCurrentPhase({ 
        programId: program.id, 
        phaseId: phase.id 
      })).unwrap();
  
      onClose();
      if (onTabChange) {
        // Pass both the tab name and the phase data
        onTabChange('phase', {
          id: phase.id,
          phaseNumber: phase.phaseNumber,
          name: phase.name,
          status: phase.status,
          currentPhaseId: phase.id
        });
      }
    } catch (error) {
      console.error('Failed to update current phase:', error);
    }
  }, [dispatch, program?.id, onClose, onTabChange]);

  // Create final items list including add button
  const items = useMemo(() => {
    const addButtonItem = {
      type: 'addButton',
      id: 'add-button'
    };

    return [...phaseList, addButtonItem];
  }, [phaseList]);

  // Update refs when items change
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, items.length);
  }, [items.length]);

  // Touch device detection
  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  // Z-index calculation
  const getZindex = useCallback(
    (array, index) => array.map((_, i) =>
      index === i ? array.length : array.length - Math.abs(index - i)
    ),
    []
  );

  // Display items with both horizontal and vertical positioning
  const displayItems = useCallback(
    (item, index, active) => {
      const zIndex = getZindex([...itemsRef.current], active)[index];
      item.style.setProperty('--zIndex', zIndex);
      item.style.setProperty('--active', index - active);
      item.style.setProperty('--activeY', index - activeY);
    },
    [getZindex, activeY]
  );

  // Animation
  const animate = useCallback(() => {
    setProgress((prev) => {
      const newProgress = Math.max(0, Math.min(prev, 100));
      const activeIndex = (items.length - 1) * (newProgress / 100);
      setActive(activeIndex);
      return newProgress;
    });

    setVerticalProgress((prev) => {
      const newVerticalProgress = Math.max(0, Math.min(prev, 100));
      setActiveY(newVerticalProgress);
      return newVerticalProgress;
    });

    itemsRef.current.forEach((item, index) => {
      if (item) {
        displayItems(item, index, active);
      }
    });
  }, [items.length, active, displayItems]);

  useEffect(() => {
    animate();
  }, [progress, animate]);

  // Event handlers
  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const wheelProgress = e.deltaY * speedWheel;
    setProgress((prev) => prev + wheelProgress);
  }, []);








  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };


  const handleClose = useCallback((e) => {
    if (e) {
      e.stopPropagation();
    }
    onClose();
  }, [onClose]);

  const handlePointerMove = useCallback(
    (e) => {
      if (!isDown) return;
      e.preventDefault();

      if (e.type === 'mousemove' && !isTouchDevice) {
        cursorsRef.current.forEach((cursor) => {
          cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
        });
      }

      const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
      const y = e.clientY || (e.touches && e.touches[0].clientY) || 0;

      const deltaX = x - startX;
      const deltaY = y - startY;

      setProgress((prev) => prev + deltaX * speedDrag);
      setVerticalProgress((prev) => prev + deltaY * speedDragY);

      setStartX(x);
      setStartY(y);
    },
    [isDown, startX, startY, isTouchDevice]
  );

  const handlePointerDown = useCallback((e) => {
    e.preventDefault();
    setIsDown(true);
    setStartX(e.clientX || (e.touches && e.touches[0].clientX) || 0);
    setStartY(e.clientY || (e.touches && e.touches[0].clientY) || 0);
  }, []);

  const handlePointerUp = useCallback(() => {
    setIsDown(false);
  }, []);

  const handleItemClick = useCallback(
    (item, index) => {
      if (item.type === 'programPhase') {
        setProgress((index / (items.length - 1)) * 100);
        setSelectedItem(item);
        setIsDrawerOpen(true);
      }
    },
    [items.length]
  );

//   const handleRemovePhase = useCallback(
//     async () => {
//         if (selectedItem && window.confirm('Are you sure you want to remove this phase?')) {
//             try {
//                 // Need to implement deleteProgramPhase in the slice
//                 await dispatch(deleteProgramPhase({
//                     programId: program.id,
//                     phaseId: selectedItem.id
//                 })).unwrap();
//                 setIsDrawerOpen(false);
//             } catch (error) {
//                 console.error('Error removing program phase:', error);
//                 alert('Failed to remove phase. Please try again.');
//             }
//         }
//     },
//     [selectedItem, program?.id, dispatch]
// );

  const handleGoToPhase = useCallback(() => {
    if (selectedItem) {
        onTabChange('phase');
        setIsDrawerOpen(false);
        onClose();
    }
}, [selectedItem, onTabChange, onClose]);

  // Event listeners
  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;

    if (!isTouchDevice) {
      carouselElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    carouselElement.addEventListener('pointerdown', handlePointerDown);
    carouselElement.addEventListener('pointermove', handlePointerMove);
    carouselElement.addEventListener('pointerup', handlePointerUp);

    return () => {
      if (!isTouchDevice) {
        carouselElement.removeEventListener('wheel', handleWheel);
      }
      carouselElement.removeEventListener('pointerdown', handlePointerDown);
      carouselElement.removeEventListener('pointermove', handlePointerMove);
      carouselElement.removeEventListener('pointerup', handlePointerUp);
    };
  }, [handleWheel, handlePointerDown, handlePointerMove, handlePointerUp, isTouchDevice]);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <CloseButton
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}
      >
        &times;
      </CloseButton>

      {items.length === 0 ? (
        <AddPhaseButtonContainer>
          {renderAddPhaseButton()}
        </AddPhaseButtonContainer>
      ) : (
        <>
          <Carousel ref={carouselRef}>
            {items.map((item, index) => (
              <CarouselItem
                key={item.id}
                ref={(el) => (itemsRef.current[index] = el)}
                onClick={() => item.type === 'programPhase' ? handlePhaseSelect(item) : null}
                itemCount={items.length}
              >
                {item.type === 'programPhase' ? (
                  <PhaseCard
                  phase={{
                    id: item.id,
                    phaseNumber: item.phaseNumber,
                    name: item.title,
                    status: item.status,
                    isCurrent: item.isCurrent
                  }}
                />
                ) : (
                  <AddButtonContainer>
                    {renderAddPhaseButton()}
                  </AddButtonContainer>
                )}
              </CarouselItem>
            ))}
          </Carousel>

          {!isTouchDevice && (
            <>
              <Cursor ref={(el) => (cursorsRef.current[0] = el)} />
              <Cursor2 ref={(el) => (cursorsRef.current[1] = el)} />
            </>
          )}
        </>
      )}
    </ModalOverlay>
  );
};

export default ProgramPhaseCarousel;