import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../store/features/auth/authSlice';


const PopupMenuWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: ${(props) => (props.collapsed ? '80px' : '250px')};
  background: #1a1a1a;
  color: #e5e5e5;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 2000;
  width: 200px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 0.5rem 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        color: #4caf50;
      }

      svg {
        font-size: 1.2rem;
      }
    }
  }
`;

// const PopupMenuComponent = ({ collapsed, setShowPopup }) => {
//   const popupRef = useRef(null);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (popupRef.current && !popupRef.current.contains(event.target)) {
//         setShowPopup(false);
//       }
//     };

//     // Attach the listener to detect outside clicks
//     document.addEventListener("mousedown", handleClickOutside);

//     // Cleanup listener on component unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [setShowPopup]);

//   return (
//     <PopupMenuWrapper ref={popupRef} collapsed={collapsed}>
//       <ul>
//         <li>Upgrade to Pro</li>
//         <li>Account</li>
//         <li>Billing</li>
//         <li>Notifications</li>
//         <li>Log out</li>
//       </ul>
//     </PopupMenuWrapper>
//   );
// };

// export default PopupMenuComponent;


const PopupMenuComponent = ({ collapsed, setShowPopup }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const popupRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
          setShowPopup(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [setShowPopup]);
  
    const handleLogout = () => {
      dispatch(logout());
      setShowPopup(false);
      navigate('/');  // Navigate to home page after logout
    };
  
    return (
      <PopupMenuWrapper ref={popupRef} collapsed={collapsed}>
        <ul>
          <li>Upgrade to Pro</li>
          <li>Account</li>
          <li>Billing</li>
          <li>Notifications</li>
          <li onClick={handleLogout}>Log out</li>
        </ul>
      </PopupMenuWrapper>
    );
  };
  
  export default PopupMenuComponent;