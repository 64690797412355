



import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FaArrowRight, FaCopy, FaTimes, FaEllipsisV } from "react-icons/fa";
import { IoChevronBackOutline, IoChevronForwardOutline } from "react-icons/io5";
import ProgramBackground from "./ProgramBackground";
import PhaseFooter from './PhaseFooter';

import WorkoutForm from "../../workouts/WorkoutForm";
import AddWorkoutButton from "../../workouts/AddWorkoutButton";
import WorkoutItem from "../../workouts/WorkoutItem";
import ProgramWorkoutDrawer from "../../program/ProgramWorkoutDrawer";
import { useSelector, useDispatch } from "react-redux";
import {
  createWorkout,
  updateWorkout,
  deleteWorkout,
  duplicateWorkout,
  fetchWorkouts,
  selectWorkoutsByProgramId,
  WorkoutStatus,
  WorkoutType,
  selectWorkoutsByWeekCycle
} from "../../../store/features/workouts/workoutsSlice";
import {
  updateProgram,
  updateProgramWeeks,
  selectProgramById,
  addBigPictureWeekCycle,
  ProgramType,
  selectProgramPhase,
  selectProgramPhases,
  fetchProgramPhases,
  deleteBigPictureWeekCycle,
  addWeekCycle,
  deleteWeekCycle,
  setCurrentPhase,
  reorderProgramPhases  
} from "../../../store/features/programs/programsSlice";

// Styled components definitions
const WeekContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 12px;
  justify-content: flex-start;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  height: ${props => props.hasPhasesFooter 
    ? 'calc(100vh - 170px)' // Account for header + footer
    : 'calc(100vh - 100px)'}; // Just account for header
  
  margin-bottom: ${props => props.hasPhasesFooter ? '56px' : '0'};
  
  transition: height 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;

  scroll-behavior: smooth;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    opacity: ${props => props.isTransitioning ? 0 : 1};
    transform: translateX(${props => props.isTransitioning ? '-100%' : '0'})

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    height: 8px;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.2) transparent;

  /* For IE and Edge */
  -ms-overflow-style: -ms-autohiding-scrollbar;

  /* Ensure the container takes full width */
  box-sizing: border-box;

  /* Add some padding to the bottom to ensure scrollbar visibility */
  padding-bottom: 16px;
`;

const WeekColumn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2c2c2c;
  border-radius: 8px;
  padding: 10px;
  min-width: 12em;
  max-width: 12em;
  height: calc(80% - 10px); // Account for padding
  overflow-y: auto;

  /* Hide default scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    &::-webkit-scrollbar {
      display: block;
    }
  }
`;

const WeekTitle = styled.h3`
  color: #fff;
`;

const AddWeekSection = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 200px;
  overflow: hidden;
`;

const AddWeekHeader = styled.button`
  background-color: #3a3a3a;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  width: 100%;

  &:hover {
    background-color: #525252;
  }
`;

const AddWeekSkeleton = styled.div`
  height: 100%;
  margin-top: 35px;
  background: linear-gradient(180deg, #2c2c2c, #3a3a3a, 0) 100%);
`;

const DropOptionsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DropOptionsCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 12px;
  padding: 24px;
  width: 320px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const DropOptionsTitle = styled.h3`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const DropOptionsText = styled.p`
  color: #b0b0b0;
  font-size: 14px;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ActionButton = styled.button`
  background-color: ${(props) => (props.primary ? "#f02a5b" : "#3a3a3a")};
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) => (props.primary ? "#f02a5b" : "#4a4a4a")};
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  svg {
    margin-right: 8px;
  }
`;

const ScrollArea = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: all 0.2s ease;
  opacity: 0;

  &:hover {
    opacity: 1;
  }

  ${props => props.direction === 'left' ? `
    left: 0;
    background: linear-gradient(to right, rgba(26, 26, 26, 0.8), transparent);
  ` : `
    right: 0;
    background: linear-gradient(to left, rgba(26, 26, 26, 0.8), transparent);
  `}

  svg {
    color: white;
    opacity: 0.7;
    position: absolute;
  }

  &:disabled {
    display: none;
  }
`;


const ScrollControlContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(26, 26, 26, 0.8);
  border: none;
  width: 40px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 10;

  &:hover {
    opacity: 1;
  }

  ${props => props.direction === 'left' ? `
    left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  ` : `
    right: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  `}

  svg {
    color: white;
    opacity: 0.7;
  }

  &:disabled {
    display: none;
  }
`;


const ScrollProgress = styled.div`
  position: fixed;
  bottom: 35px;
  left: 0;
  width: 100%;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  opacity: 0;
  transition: opacity 0.3s ease;

  &.visible {
    opacity: 1;
  }

  .progress-bar {
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    transition: width 0.1s ease-out, transform 0.1s ease-out;
  }
`;

const WeekHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const WeekOptionsButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: white;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const WeekOptionsMenu = styled.div`
  position: absolute;
  top: 45px;
  right: 10px;
  background: #2a2a2a;
  border-radius: 8px;
  min-width: 160px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 10;
`;

const WeekMenuItem = styled.button`
  width: 100%;
  padding: 12px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  color: ${props => props.danger ? '#dc3545' : 'white'};
  font-size: 14px;
  transition: background-color 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

const DropOptionsPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};



const WorkoutDropOptions = ({ onMove, onDuplicate, onCancel }) => {
  return (
    <DropOptionsPortal>
      <DropOptionsOverlay>
        <DropOptionsCard>
          <DropOptionsTitle>Workout Action</DropOptionsTitle>
          <DropOptionsText>Choose an action for the dropped workout:</DropOptionsText>
          <ButtonContainer>
            <ActionButton primary onClick={onMove}>
              <FaArrowRight /> Move
            </ActionButton>
            <ActionButton onClick={onDuplicate}>
              <FaCopy /> Duplicate
            </ActionButton>
            <ActionButton onClick={onCancel}>
              <FaTimes /> Cancel
            </ActionButton>
          </ButtonContainer>
        </DropOptionsCard>
      </DropOptionsOverlay>
    </DropOptionsPortal>
  );
};

const WeekColumnDropTarget = ({ week, onDropWorkout, onDeleteWeek, children }) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const optionsRef = useRef(null);
  const [, dropRef] = useDrop({
    accept: "WORKOUT",
    drop: (item) => onDropWorkout(item.id, week.weekNumber),
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
      }
    };

    if (isOptionsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOptionsOpen]);

  return (
    <WeekColumn ref={dropRef}>
      <WeekHeader>
        <WeekTitle>Week {week.weekNumber}</WeekTitle>
        <div ref={optionsRef} style={{ position: 'relative' }}>
          <WeekOptionsButton
            onClick={(e) => {
              e.stopPropagation();
              setIsOptionsOpen(!isOptionsOpen);
            }}
          >
            <FaEllipsisV />
          </WeekOptionsButton>
          {isOptionsOpen && (
            <WeekOptionsMenu>
              <WeekMenuItem
                danger
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOptionsOpen(false);
                  onDeleteWeek(week.weekNumber);
                }}
              >
                Delete Week
              </WeekMenuItem>
            </WeekOptionsMenu>
          )}
        </div>
      </WeekHeader>
      {children}
    </WeekColumn>
  );
};




const useScrollHandler = (weekContainerRef) => {
  const [scrollState, setScrollState] = useState({
    canScrollLeft: false,
    canScrollRight: false,
    isScrolling: false
  });
  const scrollIntervalRef = useRef(null);
  const scrollTimeout = useRef(null);

  const updateScrollState = useCallback(() => {
    if (!weekContainerRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = weekContainerRef.current;

    setScrollState({
      canScrollLeft: scrollLeft > 0,
      canScrollRight: scrollLeft < scrollWidth - clientWidth - 1,
      isScrolling: true
    });

    if (scrollTimeout.current) {
      clearTimeout(scrollTimeout.current);
    }

    scrollTimeout.current = setTimeout(() => {
      setScrollState(prev => ({ ...prev, isScrolling: false }));
    }, 1000);
  }, []);

  const startScrolling = useCallback((direction) => {
    if (scrollIntervalRef.current) return;

    const scroll = () => {
      if (!weekContainerRef.current) return;

      const scrollAmount = 15;
      const { scrollLeft, scrollWidth, clientWidth } = weekContainerRef.current;

      if (direction === 'left' && scrollLeft > 0) {
        weekContainerRef.current.scrollLeft -= scrollAmount;
      } else if (direction === 'right' && scrollLeft < scrollWidth - clientWidth) {
        weekContainerRef.current.scrollLeft += scrollAmount;
      }

      updateScrollState();
    };

    setScrollState(prev => ({ ...prev, isScrolling: true }));
    scrollIntervalRef.current = setInterval(scroll, 16);
  }, [updateScrollState]);

  const stopScrolling = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    const container = weekContainerRef.current;
    if (!container) return;

    container.addEventListener('scroll', updateScrollState);
    window.addEventListener('resize', updateScrollState);
    updateScrollState();

    return () => {
      container.removeEventListener('scroll', updateScrollState);
      window.removeEventListener('resize', updateScrollState);
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current);
      }
    };
  }, [updateScrollState]);

  return {
    ...scrollState,
    startScrolling,
    stopScrolling
  };
};







// ###############################

const ProgramInterface = ({ programId }) => {
  const dispatch = useDispatch();

  const program = useSelector(state => selectProgramById(state, programId));
  const workoutsByWeekCycle = useSelector(state => {
    if (!program?.weekCycles) {
      return {};
    }

    return program.weekCycles.reduce((acc, cycle) => {
      // Ensure we're using the selector safely
      acc[cycle.id] = selectWorkoutsByWeekCycle(state, cycle.id);
      return acc;
    }, {});
  });
  const user = useSelector((state) => state.auth.user);
  const workouts = useSelector(state =>
    selectWorkoutsByProgramId(state, program.id, program.type)
  );


  const isLoading = useSelector(state => state.workouts.loading);
  const [selectedWorkout, setSelectedWorkout] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [transitionDirection, setTransitionDirection] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isFormActive, setIsFormActive] = useState(false);
  const weekCycles = program?.weekCycles || [];
  const sortedWeekCycles = [...weekCycles].sort((a, b) => a.cycleNumber - b.cycleNumber);
  const weekCycleIds = sortedWeekCycles.map(cycle => cycle.id);



  useEffect(() => {
    let timeoutId;
    if (program?.currentPhaseId) {
      setIsTransitioning(true);
      timeoutId = setTimeout(() => {
        setIsTransitioning(false);
      }, 300); // Duration of our transition
    }
    return () => clearTimeout(timeoutId);
  }, [program?.currentPhaseId]);


  useEffect(() => {
    if (program?.id && program?.type) {
      dispatch(fetchWorkouts(program.id))  // Just pass the ID
        .unwrap()
        .then(response => {
          console.log("Workouts fetched successfully:", response);
        })
        .catch(error => {
          console.error("Failed to fetch workouts:", error);
        });
    }
  }, [program?.id, program?.type, program?.currentPhaseId, dispatch]);








  // const weeks = useMemo(() => {
  //   if (!program?.weekCycles) {
  //     return [];
  //   }

  //   return [...program.weekCycles]
  //     .sort((a, b) => a.cycleNumber - b.cycleNumber)
  //     .map(cycle => ({
  //       weekNumber: cycle.cycleNumber,
  //       weekCycleId: cycle.id,
  //       workouts: workoutsByWeekCycle[cycle.id] || []
  //     }));
  // }, [program?.weekCycles, workoutsByWeekCycle]);

  const weeks = useMemo(() => {
    if (!program?.weekCycles) {
      return [];
    }
  
    const currentPhaseWeeks = program.type === ProgramType.BIG_PICTURE
      ? program.weekCycles.filter(cycle => cycle.programPhaseId === program.currentPhaseId)
      : program.weekCycles;
  
    return [...currentPhaseWeeks]
      .sort((a, b) => a.cycleNumber - b.cycleNumber)
      .map(cycle => ({
        weekNumber: cycle.cycleNumber,
        weekCycleId: cycle.id,
        workouts: workoutsByWeekCycle[cycle.id] || []
      }));
  }, [program?.weekCycles, workoutsByWeekCycle, program?.currentPhaseId, program?.type]);



  // Add this after the useMemo
  useEffect(() => {
    console.log("Current weeks state:", weeks);
  }, [weeks]);




  const [dropInfo, setDropInfo] = useState(null);
  // const [showIncompleteWorkoutDialog, setShowIncompleteWorkoutDialog] = useState(false);

  const weekContainerRef = useRef(null);
  const {
    canScrollLeft,
    canScrollRight,
    startScrolling,
    stopScrolling
  } = useScrollHandler(weekContainerRef);






  const phases = useSelector(state => selectProgramPhases(state, program?.id));


  useEffect(() => {
    if (!program?.id || !user?.id || program?.type !== ProgramType.BIG_PICTURE) return;

    // First ensure we have the program phases
    dispatch(fetchProgramPhases(program.id))
      .unwrap()
      .then(() => {
        console.log("Phases loaded:", {
          phases: program.phases,
          weekCycles: program.weekCycles
        });
      })
      .catch(error => {
        console.error('Failed to fetch program phases:', error);
      });

  }, [program?.id, program?.type, user?.id, dispatch]);





  const handleAddWeek = async () => {
    if (!program?.id) return;

    try {
      if (program.type === ProgramType.BIG_PICTURE) {
        const currentPhase = phases.find(phase => phase.id === program.currentPhaseId);
        console.log('Debug Big Picture Program:', {
          program,
          phases,
          currentPhaseId: program.currentPhaseId,
          hasPhases: Boolean(phases?.length),
          currentPhase
        });

        if (!currentPhase) {
          console.error("No current phase found for Big Picture program");
          return;
        }

        const currentPhaseWeeks = program.weekCycles?.filter(
          cycle => cycle.programPhaseId === currentPhase.id
        ).length || 0;

        const newWeekNumber = currentPhaseWeeks + 1;

        const weekCycleData = {
          cycleNumber: newWeekNumber,
          name: `Week ${newWeekNumber}`,
          cycleLengthDays: program.defaultCycleLengthDays,
          status: 'pending',
          schedule: {
            cycleType: 'fixed',
            startDay: 0,
            adjustmentRules: {
              allowStartDayAdjustment: true,
              minimumCycleDuration: program.defaultCycleLengthDays,
              maximumCycleDuration: program.defaultCycleLengthDays
            }
          }
        };

        await dispatch(addBigPictureWeekCycle({
          programId: program.id,
          phaseId: currentPhase.id,
          weekCycleData
        })).unwrap();
      } else {
        const currentWeekCount = program.weekCycles?.length || 0;
        const newWeekNumber = currentWeekCount + 1;

        const weekCycleData = {
          cycleNumber: newWeekNumber,
          name: `Week ${newWeekNumber}`,
          cycleLengthDays: program.defaultCycleLengthDays,
          status: 'pending',
          schedule: {
            cycleType: 'fixed',
            startDay: 0,
            adjustmentRules: {
              allowStartDayAdjustment: true,
              minimumCycleDuration: program.defaultCycleLengthDays,
              maximumCycleDuration: program.defaultCycleLengthDays
            }
          }
        };

        await dispatch(addWeekCycle({
          programId: program.id,
          weekCycleData
        })).unwrap();
      }
    } catch (error) {
      console.error("Error adding new week:", error);
    }
  };





  const handleDeleteWeek = async (weekNumber) => {
    if (!program?.id) return;

    try {

      console.log("Delete Week Debug:", {
        programType: program.type,
        weekNumber,
        programId: program.id
      });


      const weekCycleToDelete = program.weekCycles?.find(
        cycle => cycle.cycleNumber === weekNumber
      );

      if (!weekCycleToDelete) {
        console.error("Week cycle not found for deletion");
        return;
      }


      console.log("Week Cycle to Delete:", {
        cycleId: weekCycleToDelete.id,
        phaseId: weekCycleToDelete.programPhaseId || weekCycleToDelete.programPhase?.id,
        cycleNumber: weekCycleToDelete.cycleNumber
      });

      if (program.type === ProgramType.BIG_PICTURE) {
        // For Big Picture programs, we need the phase ID
        const phaseId = weekCycleToDelete.programPhaseId || weekCycleToDelete.programPhase?.id;

        if (!phaseId) {
          console.error("Phase ID not found for week cycle");
          return;
        }


        console.log("Dispatching deleteBigPictureWeekCycle with:", {
          programId: program.id,
          phaseId,
          cycleId: weekCycleToDelete.id
        });


        await dispatch(deleteBigPictureWeekCycle({
          programId: program.id,
          phaseId: phaseId,
          cycleId: weekCycleToDelete.id
        })).unwrap();
      } else {


        console.log("Dispatching deleteWeekCycle with:", {
          programId: program.id,
          cycleId: weekCycleToDelete.id
        });
        // For Simple and Progressive programs
        await dispatch(deleteWeekCycle({
          programId: program.id,
          cycleId: weekCycleToDelete.id
        })).unwrap();
      }

    } catch (error) {
      console.error("Error deleting week:", error, {
        stack: error.stack,
        response: error.response?.data
      });
    }
  };




  // **Handle adding a workout to a week**
  const handleAddWorkoutToWeek = async (weekNumber) => {
    if (!program?.id || !weekNumber) {
      console.error("Missing required data:", { programId: program?.id, weekNumber });
      return;
    }

    const weekCycle = program.weekCycles.find(
      cycle => cycle.cycleNumber === weekNumber
    );

    if (!weekCycle) {
      console.error("Week cycle not found for number:", weekNumber);
      return;
    }

    const weekWorkouts = workoutsByWeekCycle[weekCycle.id] || [];
    const newWorkoutOrder = weekWorkouts.length + 1;

    const newWorkout = {
      name: `Week ${weekNumber} Workout ${newWorkoutOrder}`,
      status: WorkoutStatus.PLANNED,
      sequenceOrder: newWorkoutOrder,
      duration: 60,
      sections: [],
      categories: {
        corrective: [],
        activation: [],
        exercises: [],
        esd: []
      }
    };

    try {
      const resultAction = await dispatch(createWorkout({
        programId: program.id,
        weekCycleId: weekCycle.id,
        workout: newWorkout
      }));

      if (createWorkout.fulfilled.match(resultAction)) {
        setSelectedWorkout(resultAction.payload);
        setDrawerOpen(true);
      }
    } catch (error) {
      console.error("Error creating workout:", error);
    }
  };


  // **Handle selecting a workout**
  const handleSelectWorkout = (workout) => {
    // Since workouts are already in state, we can find it directly
    const selected = workouts.find((w) => w.id === workout.id);
    if (selected) {
      setSelectedWorkout(selected);
      setDrawerOpen(true);
    } else {
      console.error("Workout not found");
    }
  };

  // **Handle workout deletion**
  const handleWorkoutDeleted = (workoutId) => {
    if (selectedWorkout?.id === workoutId) {
      setSelectedWorkout(null);
      setDrawerOpen(false);
    }
  };

  // **Handle dropping a workout onto a week**
  const handleDropWorkout = (workoutId, targetWeekNumber) => {
    const sourceWeek = weeks.find((week) => week.workouts.some((w) => w.id === workoutId));
    const targetWeek = weeks.find((week) => week.weekNumber === targetWeekNumber);

    if (sourceWeek && targetWeek) {
      const workoutToMove = sourceWeek.workouts.find((w) => w.id === workoutId);
      setDropInfo({ workoutId, sourceWeek, targetWeek, workoutToMove });
    }
  };

  // **Handle moving a workout to another week**
  const handleMoveWorkout = async () => {
    if (dropInfo && program?.id) {
      const { workoutToMove, targetWeek } = dropInfo;

      const updatedWorkout = {
        ...workoutToMove,
        week: targetWeek.weekNumber,
      };

      try {
        // Dispatch action to update workout
        await dispatch(updateWorkout({ programId: program.id, workoutId: updatedWorkout.id, workoutData: updatedWorkout }));
        setDropInfo(null);
      } catch (error) {
        console.error("Error moving workout:", error);
        // Optionally, display error to user
      }
    }
  };

  // **Handle duplicating a workout**
  const handleDuplicateWorkout = async () => {
    if (dropInfo && program?.id) {
      const { workoutToMove, targetWeek } = dropInfo;
      try {
        // Dispatch action to duplicate workout
        const resultAction = await dispatch(duplicateWorkout({ programId: program.id, workoutId: workoutToMove.id, targetWeek: targetWeek.weekNumber }));
        if (duplicateWorkout.fulfilled.match(resultAction)) {
          setDropInfo(null);
        } else {
          console.error("Error duplicating workout:", resultAction.payload || resultAction.error);
        }
      } catch (error) {
        console.error("Error duplicating workout:", error);
        // Optionally, display error to user
      }
    }
  };


  


  const handlePhaseReorder = async (reorderedPhaseIds) => {
    try {
      await dispatch(reorderProgramPhases({
        programId: program.id,
        phaseIds: reorderedPhaseIds
      })).unwrap();
  
      await dispatch(fetchProgramPhases(program.id));
    } catch (error) {
      console.error('Error reordering phases:', error);
    }
  };

  const handlePhaseSelect = async (selectedPhase) => {
    try {
      await dispatch(setCurrentPhase({
        programId: program.id,
        phaseId: selectedPhase.id
      })).unwrap();

      await dispatch(fetchProgramPhases(program.id));
    } catch (error) {
      console.error('Error changing phase:', error);
    }
  };

  // **Cancel the drop action**
  const handleCancelDrop = () => {
    setDropInfo(null);
  };

  // **Check if a workout is complete**
  const isWorkoutComplete = useCallback((workout) => {
    return workout?.mainMovement?.trim() && workout?.intensity?.trim();
  }, []);


  const handleDrawerClose = useCallback(() => {
    setSelectedWorkout(null);
    setDrawerOpen(false);
  }, []);



  // **Render component**
  if (!program?.id || !user) {
    return null;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <ProgramBackground />
      <div>
        <ScrollControlContainer>
          <ScrollArea
            direction="left"
            onMouseEnter={() => startScrolling('left')}
            onMouseLeave={stopScrolling}
            style={{ display: canScrollLeft ? 'flex' : 'none' }}
          >
            <IoChevronBackOutline size={24} />
          </ScrollArea>

          <WeekContainer
            ref={weekContainerRef}
            isTransitioning={isTransitioning}
            hasPhasesFooter={program?.type === 'big_picture'}
          >

            {weeks.map((week) => (

              <WeekColumnDropTarget
                key={week.weekNumber}
                week={week}
                onDropWorkout={handleDropWorkout}
                onDeleteWeek={handleDeleteWeek}
              >
                {week.workouts.map((workout) => (
                  <WorkoutItem
                    key={workout.id}
                    workout={workout}
                    onSelectWorkout={handleSelectWorkout}
                  />
                ))}
                <AddWorkoutButton
                  weekNumber={week.weekNumber} // Pass weekNumber prop
                  onAddWorkout={handleAddWorkoutToWeek}
                />
              </WeekColumnDropTarget>
            ))}
            <AddWeekSection>
              <AddWeekHeader onClick={handleAddWeek}>+ Add Week</AddWeekHeader>
              <AddWeekSkeleton />
            </AddWeekSection>
          </WeekContainer>

          <ScrollArea
            direction="right"
            onMouseEnter={() => startScrolling('right')}
            onMouseLeave={stopScrolling}
            style={{ display: canScrollRight ? 'flex' : 'none' }}
          >
            <IoChevronForwardOutline size={24} />
          </ScrollArea>



        </ScrollControlContainer>

        <ProgramWorkoutDrawer
          isOpen={drawerOpen}
          onClose={handleDrawerClose}
          disableClickOutside={isFormActive}
        >
          {selectedWorkout && (
            <WorkoutForm
              workout={selectedWorkout}
              programId={program.id}
              onDelete={handleWorkoutDeleted}
              onClose={handleDrawerClose}
              onUpdate={(updatedWorkout) => setSelectedWorkout(updatedWorkout)}
              onFormActive={setIsFormActive}
            />
          )}
        </ProgramWorkoutDrawer>

        {dropInfo && (
          <WorkoutDropOptions
            onMove={handleMoveWorkout}
            onDuplicate={handleDuplicateWorkout}
            onCancel={handleCancelDrop}
          />
        )}

        {program?.type === 'big_picture' && (
          <PhaseFooter
            program={program}
            onPhaseSelect={handlePhaseSelect}
            onPhaseReorder={handlePhaseReorder}
          />
        )}


      </div>
    </DndProvider>
  );
};

export default ProgramInterface;