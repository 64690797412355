// src/components/ui/FilterBar.js

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { IoChevronDownOutline, IoChevronUpOutline, IoSearchOutline } from 'react-icons/io5';

const DropdownContainer = styled.div`
  position: relative;
  width: 250px;
  margin-bottom: 20px;
`;

const DropdownHeader = styled.div`
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;

  &:hover {
    border-color: #007BFF;
    box-shadow: 0 2px 8px rgba(0, 123, 255, 0.2);
  }
`;

const DropdownListContainer = styled.div`
  position: absolute;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-top: none;
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  z-index: 1000;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 8px 15px;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  font-size: 0.9rem;

  &::placeholder {
    color: #999;
  }
`;

const DropdownList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ListItem = styled.li`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #333;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e6f7ff;
  }

  input {
    margin-right: 10px;
  }
`;

const FilterBar = ({ filters, setFilters, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef();

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (value) => {
    if (filters.includes(value)) {
      setFilters(filters.filter((f) => f !== value));
    } else {
      setFilters([...filters, value]);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const filteredOptions = options.filter(option =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedOptions = options.filter(option => filters.includes(option.value));

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownHeader onClick={toggleDropdown} aria-haspopup="listbox" aria-expanded={isOpen}>
        <span>
          {selectedOptions.length > 0 ? selectedOptions.map(opt => opt.label).join(', ') : 'Filter by Status/Role'}
        </span>
        {isOpen ? <IoChevronUpOutline size={20} /> : <IoChevronDownOutline size={20} />}
      </DropdownHeader>
      {isOpen && (
        <DropdownListContainer role="listbox">
          <SearchInput
            type="text"
            placeholder="Search filters..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            aria-label="Search Filters"
          />
          <DropdownList>
            {filteredOptions.map((option) => (
              <ListItem
                key={option.value}
                onClick={() => handleOptionClick(option.value)}
                selected={filters.includes(option.value)}
                role="option"
                aria-selected={filters.includes(option.value)}
              >
                <input
                  type="checkbox"
                  checked={filters.includes(option.value)}
                  readOnly
                />
                {option.label}
              </ListItem>
            ))}
          </DropdownList>
        </DropdownListContainer>
      )}
    </DropdownContainer>
  );
};

export default FilterBar;

