// src/redux/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import programsReducer from "../store/features/programs/programsSlice";
import paymentsReducer from './features/payments/paymentsSlice';
import exercisesReducer from "./features/exercises/exercisesSlice";
import workoutReducer from "./features/workouts/workoutsSlice";
import userReducer from "./features/user/userSlice";
import routeReducer from "./features/routeSlice";
import subscriptionsReducer from './features/subscriptions/subscriptionSlice';
import usersReducer from './features/user/userSlice';


const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  programs: programsReducer, // Make sure to use consistent naming
  user: userReducer,
  route: routeReducer,
  payments: paymentsReducer,
  subscriptions: subscriptionsReducer,
  // exercises: exercisesReducer,
  workouts: workoutReducer,
});

export default rootReducer;
