// src/components/trainer/SalesMetrics.js
import React, { useState } from 'react';
import styled from 'styled-components';
import { FaChartLine, FaUsers, FaDollarSign, FaCalendar, FaArrowUp, FaArrowDown } from 'react-icons/fa';

const MetricsContainer = styled.div`
  padding: 1.5rem;
`;

const Header = styled.div`
  margin-bottom: 2rem;
  
  h2 {
    margin-bottom: 0.5rem;
  }
  
  p {
    color: #666;
  }
`;

const TimeframeSelector = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-bottom: 2rem;
`;

const TimeButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid ${props => props.active ? '#4caf50' : '#ddd'};
  background: ${props => props.active ? '#4caf50' : 'white'};
  color: ${props => props.active ? 'white' : '#333'};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.active ? '#43a047' : '#f5f5f5'};
  }
`;

const MetricsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
`;

const MetricCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .metric-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #666;
    margin-bottom: 1rem;
  }

  .metric-value {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .metric-comparison {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
  }

  .trend {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    color: ${props => props.trend === 'up' ? '#4caf50' : '#f44336'};
  }
`;

const DetailedMetricsSection = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1.5rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ChartCard = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  h3 {
    margin-bottom: 1rem;
  }
`;

const ClientsList = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  h3 {
    margin-bottom: 1rem;
  }

  .client-item {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: none;
    }
  }

  .revenue {
    color: #4caf50;
    font-weight: 500;
  }
`;

const SalesMetrics = () => {
  const [timeframe, setTimeframe] = useState('30days');

  const metrics = {
    monthlyRevenue: {
      value: '$5,280',
      change: '+12%',
      trend: 'up'
    },
    activeClients: {
      value: '48',
      change: '+8%',
      trend: 'up'
    },
    averageValue: {
      value: '$110',
      change: '+5%',
      trend: 'up'
    },
    retention: {
      value: '92%',
      change: '-2%',
      trend: 'down'
    }
  };

  const topClients = [
    { name: 'John Doe', revenue: '$450' },
    { name: 'Sarah Smith', revenue: '$380' },
    { name: 'Mike Johnson', revenue: '$350' },
    { name: 'Emma Wilson', revenue: '$320' },
  ];

  return (
    <MetricsContainer>
      <Header>
        <h2>Sales & Performance</h2>
        <p>Track your business metrics and growth</p>
      </Header>

      <TimeframeSelector>
        <TimeButton 
          active={timeframe === '7days'} 
          onClick={() => setTimeframe('7days')}
        >
          7 Days
        </TimeButton>
        <TimeButton 
          active={timeframe === '30days'} 
          onClick={() => setTimeframe('30days')}
        >
          30 Days
        </TimeButton>
        <TimeButton 
          active={timeframe === '90days'} 
          onClick={() => setTimeframe('90days')}
        >
          90 Days
        </TimeButton>
        <TimeButton 
          active={timeframe === 'year'} 
          onClick={() => setTimeframe('year')}
        >
          Year
        </TimeButton>
      </TimeframeSelector>

      <MetricsGrid>
        <MetricCard trend={metrics.monthlyRevenue.trend}>
          <div className="metric-header">
            <FaDollarSign />
            <span>Monthly Revenue</span>
          </div>
          <div className="metric-value">{metrics.monthlyRevenue.value}</div>
          <div className="metric-comparison">
            <div className="trend">
              {metrics.monthlyRevenue.trend === 'up' ? <FaArrowUp /> : <FaArrowDown />}
              {metrics.monthlyRevenue.change}
            </div>
            <span>vs last period</span>
          </div>
        </MetricCard>

        <MetricCard trend={metrics.activeClients.trend}>
          <div className="metric-header">
            <FaUsers />
            <span>Active Clients</span>
          </div>
          <div className="metric-value">{metrics.activeClients.value}</div>
          <div className="metric-comparison">
            <div className="trend">
              {metrics.activeClients.trend === 'up' ? <FaArrowUp /> : <FaArrowDown />}
              {metrics.activeClients.change}
            </div>
            <span>vs last period</span>
          </div>
        </MetricCard>

        <MetricCard trend={metrics.averageValue.trend}>
          <div className="metric-header">
            <FaChartLine />
            <span>Average Client Value</span>
          </div>
          <div className="metric-value">{metrics.averageValue.value}</div>
          <div className="metric-comparison">
            <div className="trend">
              {metrics.averageValue.trend === 'up' ? <FaArrowUp /> : <FaArrowDown />}
              {metrics.averageValue.change}
            </div>
            <span>vs last period</span>
          </div>
        </MetricCard>

        <MetricCard trend={metrics.retention.trend}>
          <div className="metric-header">
            <FaUsers />
            <span>Client Retention</span>
          </div>
          <div className="metric-value">{metrics.retention.value}</div>
          <div className="metric-comparison">
            <div className="trend">
              {metrics.retention.trend === 'up' ? <FaArrowUp /> : <FaArrowDown />}
              {metrics.retention.change}
            </div>
            <span>vs last period</span>
          </div>
        </MetricCard>
      </MetricsGrid>

      <DetailedMetricsSection>
        <ChartCard>
          <h3>Revenue Overview</h3>
          {/* Add chart component here */}
          <div style={{ height: '300px', background: '#f5f5f5', borderRadius: '4px' }}>
            {/* Placeholder for chart */}
          </div>
        </ChartCard>

        <ClientsList>
          <h3>Top Clients by Revenue</h3>
          {topClients.map((client, index) => (
            <div key={index} className="client-item">
              <span>{client.name}</span>
              <span className="revenue">{client.revenue}</span>
            </div>
          ))}
        </ClientsList>
      </DetailedMetricsSection>
    </MetricsContainer>
  );
};

export default SalesMetrics;