import React, { useEffect, useRef, useState, memo } from "react";
import styled from "styled-components";
import AuthModal from "../components/HomePage/auth/AuthModal";
import gsap from "gsap";
import dopeRoshi from "../assets/doperoshi.png";

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
  color: white;

  /* Background with gradient overlay */
  background: url(${dopeRoshi}) no-repeat center center;
  background-size: cover;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0)
    );
    z-index: 1;
  }
`;


const HeroSection = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;


const HeroContent = styled.div`
  z-index: 2;
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

const HeroHeadline = styled.h1`
  font-size: 4.5rem;
  margin-bottom: 20px;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;

const HeroSubheadline = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 30px;
  color: #fff;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
`;

const CTAButtons = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
    display: flex;
    align-items: center;
  }
`;

const CTAButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  }

  &.primary {
    background-color: #ff4800;
    color: #fff;
  }

  &.secondary {
    background-color: transparent;
    color: #ff4800;
    border: 2px solid #ff4800;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 768px) {
    width: 50%;
  }
`;






const Home = () => {
  const heroRef = useRef(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [authMode, setAuthMode] = useState('signup'); // 'signup' or 'login'

  useEffect(() => {
    const mm = gsap.matchMedia();

    mm.add("(max-width: 768px)", () => {
      gsap.from(heroRef.current, {
        opacity: 0,
        y: -30,
        duration: 1,
        ease: "power2.out",
      });
    });

    mm.add("(min-width: 769px)", () => {
      gsap.from(heroRef.current, {
        opacity: 0,
        y: -50,
        duration: 1.2,
        ease: "power3.out",
      });
    });

    return () => mm.revert();
  }, []);

  const handleAuthClick = (mode) => {
    setAuthMode(mode);
    setShowAuthModal(true);
  };

  return (
    <HomeContainer>
      {/* Hero Section */}
      <HeroSection ref={heroRef}>
        <HeroContent>
          <HeroHeadline>Roshi Health</HeroHeadline>
          <HeroSubheadline>Build. Track. Train.</HeroSubheadline>
          <CTAButtons>
            <CTAButton
              className="primary"
              onClick={() => handleAuthClick('signup')}
            >
              Sign Up
            </CTAButton>
            <CTAButton
              className="secondary"
              onClick={() => handleAuthClick('login')}
            >
              Login
            </CTAButton>
          </CTAButtons>
        </HeroContent>

      </HeroSection>
      <AuthModal
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        initialMode={authMode}
      />
    </HomeContainer>
  );
};

export default memo(Home);