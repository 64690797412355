import React, { useState } from 'react';
import styled from 'styled-components';
import withStepValidation from './withStepValidation';

const StepContainer = styled.div`
  padding: 1rem 0;
`;

const Title = styled.h2`
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  color: #333;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: #666;
    font-weight: 500;
  }

  input, select, textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid ${props => props.error ? '#d32f2f' : '#ddd'};
    border-radius: 4px;
    font-size: 1rem;

    &:focus {
      outline: none;
      border-color: ${props => props.error ? '#d32f2f' : '#4caf50'};
    }
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }
`;

const ErrorMessage = styled.div`
  color: #d32f2f;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const TagInput = styled.div`
  input {
    margin-bottom: 0.5rem;
  }
`;

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const Tag = styled.span`
  background: #e8f5e9;
  color: #2e7d32;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    background: none;
    border: none;
    color: #2e7d32;
    cursor: pointer;
    padding: 0;
    font-size: 1rem;
  }
`;

const TimeCommitmentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const RequirementList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ProgramMetadataStep = ({ data, onNext, errors, updateData, setIsDirty }) => {
    const [metadata, setMetadata] = useState({
        name: data.name || '',
        description: data.description || null,
        difficulty: data.metadata?.difficulty || null,
        targetAudience: data.metadata?.targetAudience || null,
        prerequisites: data.metadata?.prerequisites || null,
        experienceRequirements: data.metadata?.experienceRequirements || null,
        timeCommitment: data.metadata?.timeCommitment || null,
        equipment: data.metadata?.equipment || null,
        programFocus: data.metadata?.programFocus || null,
        price: data.price || null
    });

    const [tagInputs, setTagInputs] = useState({
        targetAudience: '',
        equipment: '',
        programFocus: '',
        experienceRequirements: ''
    });
    const [activeInputField, setActiveInputField] = useState('');

    const handleAddTag = (field, value) => {
        if (value.trim()) {
            const updatedMetadata = {
                ...metadata,
                [field]: [...metadata[field], value.trim()]
            };
            setMetadata(updatedMetadata);
            updateData({
                ...updatedMetadata,
                metadata: {
                    difficulty: metadata.difficulty,
                    targetAudience: metadata.targetAudience,
                    prerequisites: metadata.prerequisites,
                    experienceRequirements: metadata.experienceRequirements,
                    timeCommitment: metadata.timeCommitment,
                    equipment: metadata.equipment,
                    experienceRequirements: updatedMetadata.experienceRequirements,
                    programFocus: metadata.programFocus
                }
            });
            setTagInputs(prev => ({
                ...prev,
                [field]: ''
            }));
        }
    };

    const handleRemoveTag = (field, index) => {
        const updatedMetadata = {
            ...metadata,
            [field]: metadata[field].filter((_, i) => i !== index)
        };
        setMetadata(updatedMetadata);
        updateData({
            ...updatedMetadata,
            metadata: {
                difficulty: updatedMetadata.difficulty,
                targetAudience: updatedMetadata.targetAudience,
                prerequisites: updatedMetadata.prerequisites,
                equipment: updatedMetadata.equipment
            }
        });
    };

    const handleChange = (field, value) => {
        setIsDirty(true);
        const updatedMetadata = { ...metadata, [field]: value };
        setMetadata(updatedMetadata);
        
        const metadataUpdate = {
            difficulty: updatedMetadata.difficulty,
            targetAudience: updatedMetadata.targetAudience || [],
            prerequisites: updatedMetadata.prerequisites || [],
            equipment: updatedMetadata.equipment || [],
            timeCommitment: updatedMetadata.timeCommitment,
            experienceRequirements: updatedMetadata.experienceRequirements || [],
            programFocus: updatedMetadata.programFocus || []
        };
    
        // Only include non-null values
        Object.keys(metadataUpdate).forEach(key => {
            if (metadataUpdate[key] === null) {
                delete metadataUpdate[key];
            }
        });
    
        updateData({
            ...updatedMetadata,
            metadata: metadataUpdate
        });
    };

    const handleTimeCommitmentChange = (field, value) => {
        setIsDirty(true);
        const updatedMetadata = {
            ...metadata,
            timeCommitment: {
                ...metadata.timeCommitment,
                [field]: parseInt(value, 10)
            }
        };
        setMetadata(updatedMetadata);
        updateData({
            ...updatedMetadata,
            metadata: {
                ...updatedMetadata.metadata,
                timeCommitment: updatedMetadata.timeCommitment
            }
        });
    };

    return (
        <StepContainer>
            <Title>Program Details</Title>

            <FormGroup error={errors.name}>
                <label>Program Name*</label>
                <input
                    type="text"
                    value={metadata.name}
                    onChange={(e) => handleChange('name', e.target.value)}
                    placeholder="e.g., 12-Week Strength Builder"
                    error={errors.name}
                />
                {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
            </FormGroup>

            <FormGroup>
                <label>Description</label>
                <textarea
                    value={metadata.description}
                    onChange={(e) => handleChange('description', e.target.value)}
                    placeholder="Describe your program..."
                />
            </FormGroup>

            <FormGroup>
                <label>Difficulty Level</label>
                <select
                    value={metadata.difficulty}
                    onChange={(e) => handleChange('difficulty', e.target.value)}
                >
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                </select>
            </FormGroup>

            <FormGroup>
                <label>Time Commitment Ranges</label>
                <TimeCommitmentGrid>
                    <div>
                        <label>Sessions per Week Range</label>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <input
                                type="number"
                                min="1"
                                max="7"
                                value={metadata.timeCommitment.sessionsPerWeekMin}
                                onChange={(e) => handleTimeCommitmentChange('sessionsPerWeekMin', e.target.value)}
                                placeholder="Min"
                            />
                            <span>to</span>
                            <input
                                type="number"
                                min="1"
                                max="7"
                                value={metadata.timeCommitment.sessionsPerWeekMax}
                                onChange={(e) => handleTimeCommitmentChange('sessionsPerWeekMax', e.target.value)}
                                placeholder="Max"
                            />
                        </div>
                    </div>
                    <div>
                        <label>Minutes per Session Range</label>
                        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                            <input
                                type="number"
                                min="15"
                                step="15"
                                value={metadata.timeCommitment.minutesPerSessionMin}
                                onChange={(e) => handleTimeCommitmentChange('minutesPerSessionMin', e.target.value)}
                                placeholder="Min"
                            />
                            <span>to</span>
                            <input
                                type="number"
                                min="15"
                                step="15"
                                value={metadata.timeCommitment.minutesPerSessionMax}
                                onChange={(e) => handleTimeCommitmentChange('minutesPerSessionMax', e.target.value)}
                                placeholder="Max"
                            />
                        </div>
                    </div>
                </TimeCommitmentGrid>
            </FormGroup>

            <FormGroup>
                <label>Experience Requirements (Press Enter to add tags)</label>
                <TagInput>
                    <input
                        type="text"
                        value={tagInputs.experienceRequirements}
                        onChange={(e) => setTagInputs(prev => ({
                            ...prev,
                            experienceRequirements: e.target.value
                        }))}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTag('experienceRequirements', tagInputs.experienceRequirements);
                            }
                        }}
                        placeholder="e.g., Must perform 5 push-ups, Can hold plank for 30 seconds"
                    />
                </TagInput>
                <TagList>
                    {metadata.experienceRequirements.map((requirement, index) => (
                        <Tag key={index}>
                            {requirement}
                            <button onClick={() => handleRemoveTag('experienceRequirements', index)}>×</button>
                        </Tag>
                    ))}
                </TagList>
            </FormGroup>

            <FormGroup>
                <label>Equipment Required (Press Enter to add tags)</label>
                <TagInput>
                    <input
                        type="text"
                        value={tagInputs.equipment}
                        onChange={(e) => setTagInputs(prev => ({
                            ...prev,
                            equipment: e.target.value
                        }))}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTag('equipment', tagInputs.equipment);
                            }
                        }}
                        placeholder="e.g., Barbell, Power Rack, Resistance Bands"
                    />
                </TagInput>
                <TagList>
                    {metadata.equipment.map((item, index) => (
                        <Tag key={index}>
                            {item}
                            <button onClick={() => handleRemoveTag('equipment', index)}>×</button>
                        </Tag>
                    ))}
                </TagList>
            </FormGroup>





            <FormGroup>
                <label>Program Focus (Press Enter to add multiple focus areas tags)</label>
                <TagInput>
                    <input
                        type="text"
                        value={tagInputs.programFocus}
                        onChange={(e) => setTagInputs(prev => ({
                            ...prev,
                            programFocus: e.target.value
                        }))}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTag('programFocus', tagInputs.programFocus);
                            }
                        }}
                        placeholder="e.g., Strength, Weight Loss, Muscle Gain"
                    />
                </TagInput>
                <TagList>
                    {metadata.programFocus.map((focus, index) => (
                        <Tag key={index}>
                            {focus}
                            <button onClick={() => handleRemoveTag('programFocus', index)}>×</button>
                        </Tag>
                    ))}
                </TagList>
            </FormGroup>



            <FormGroup>
                <label>Target Audience (Press Enter to add tags)</label>
                <TagInput>
                    <input
                        type="text"
                        value={tagInputs.targetAudience}
                        onChange={(e) => setTagInputs(prev => ({
                            ...prev,
                            targetAudience: e.target.value
                        }))}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTag('targetAudience', tagInputs.targetAudience);
                            }
                        }}
                        placeholder="e.g., Beginners, Athletes, Seniors"
                    />
                </TagInput>
                <TagList>
                    {metadata.targetAudience.map((tag, index) => (
                        <Tag key={index}>
                            {tag}
                            <button onClick={() => handleRemoveTag('targetAudience', index)}>×</button>
                        </Tag>
                    ))}
                </TagList>
            </FormGroup>
        </StepContainer>
    );
};

export default withStepValidation(ProgramMetadataStep);