// src/components/TrainerPortal/ProgramCreationWizard.js
import React, { useState } from 'react';
import styled from 'styled-components';
import ProgramEnvironmentStep from './wizard-steps/ProgramEnvironmentStep';
import ProgramTypeStep from './wizard-steps/ProgramTypeStep';
import ProgramLengthStep from './wizard-steps/ProgramLengthStep';
import ProgramMetadataStep from './wizard-steps/ProgramMetadataStep';
import ReviewStep from './wizard-steps/ReviewStep';

const WizardOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const WizardContainer = styled.div`
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 2rem;
  position: relative;
`;

const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: #eee;
    z-index: 1;
  }
`;

const Step = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${props => props.active ? '#4caf50' : props.completed ? '#81c784' : '#eee'};
  color: ${props => props.active || props.completed ? 'white' : '#666'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
`;

const Button = styled.button`
  background: ${props => props.primary ? '#4caf50' : '#f5f5f5'};
  color: ${props => props.primary ? 'white' : '#333'};
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: ${props => props.primary ? '#388e3c' : '#e0e0e0'};
  }
`;



const steps = [
  'Environment',
  'Length',
  'Program Type',
  'Details',
  'Review'
];


const ProgramCreationWizard = ({ open, onClose, onSubmit }) => {
  const initialProgramData = {
    environment: '',
    type: '',
    durationType: '',
    totalCycles: 0,
    defaultCycleLengthDays: 7,
    name: '',
    description: '',
    price: 0,
    metadata: {
        difficulty: '',
        targetAudience: [],
        experienceRequirements: [], // Changed from prerequisites
        timeCommitment: {
            sessionsPerWeekMin: 1,
            sessionsPerWeekMax: 1,
            minutesPerSessionMin: 30,
            minutesPerSessionMax: 60
        },
        equipment: [],
        programFocus: []
    },
    status: 'draft' // Adding default status
};

const [activeStep, setActiveStep] = useState(0);

const [programData, setProgramData] = useState(initialProgramData);


  if (!open) return null;

  const handleNext = () => {
    if (canProceed()) {
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleDataUpdate = (data) => {
    setProgramData(prev => ({ ...prev, ...data }));
  };

  const resetWizard = () => {
    setActiveStep(0);
    setProgramData(initialProgramData);
  };

  const handleFinalSubmit = async () => {
    try {
      await onSubmit(programData);
      resetWizard();
      onClose();
    } catch (error) {
      console.error('Failed to create program:', error);
      // Handle error case if needed
    }
  };



  const canProceed = () => {
    switch (activeStep) {
      case 0: // Environment
        return Boolean(programData.environment);
      case 1: // Program Length
        if (!programData.durationType) return false;
        if (programData.durationType === 'fixed' &&
          (!programData.totalCycles || programData.totalCycles <= 0)) {
          return false;
        }
        return true;
      case 2: // Program Type
        return Boolean(programData.type);
      case 3: // Program Details
        return Boolean(programData.name && programData.name.trim());
      case 4: // Review
        return true;
      default:
        return false;
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ProgramEnvironmentStep
            data={programData}
            onNext={handleDataUpdate}
            updateData={handleDataUpdate}
          />
        );
      case 1:
        return (
          <ProgramLengthStep 
            data={programData} 
            onNext={handleDataUpdate}
            updateData={handleDataUpdate}
          />
        );
      case 2:
        return (
          <ProgramTypeStep
            data={programData}
            onNext={handleDataUpdate}
            updateData={handleDataUpdate}
          />
        );
      case 3:
        return (
          <ProgramMetadataStep 
            data={programData} 
            onNext={handleDataUpdate}
            updateData={handleDataUpdate}
          />
        );
        case 4:
          return (
            <ReviewStep
              data={programData}
              onNext={handleFinalSubmit}
            />
          );
      default:
        return 'Unknown step';
    }
  };

  return (
    <WizardOverlay onClick={onClose}>
      <WizardContainer onClick={e => e.stopPropagation()}>
        <StepIndicator>
          {steps.map((label, index) => (
            <Step
              key={label}
              active={index === activeStep}
              completed={index < activeStep}
            >
              {index + 1}
            </Step>
          ))}
        </StepIndicator>

        {getStepContent()}

        <ButtonContainer>
          <Button
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
          {activeStep === steps.length - 1 ? (
            <Button primary onClick={handleFinalSubmit}>
              Create Program
            </Button>
          ) : (
            <Button
              primary
              disabled={!canProceed()}
              onClick={handleNext}
            >
              Next
            </Button>
          )}
        </ButtonContainer>
      </WizardContainer>
    </WizardOverlay>
  );
};

export default ProgramCreationWizard;