// src/components/ui/SearchBar.js

import React from 'react';
import styled from 'styled-components';
import { IoSearchOutline } from 'react-icons/io5';

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f1f3f5;
  padding: 8px 12px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  outline: none;
  font-size: 16px;
`;

const SearchBar = ({ value, onChange, placeholder = "Search clients..." }) => {
  return (
    <SearchContainer>
      <IoSearchOutline size={20} color="#6c757d" />
      <SearchInput
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        aria-label="Search Clients"
      />
    </SearchContainer>
  );
};

export default SearchBar;

