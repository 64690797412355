// src/components/ui/ClientRow.js

import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import Badge from './Badge';

// Keyframes for icon rotation
const rotateIcon = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
`;

// Define two different clip-paths for alternating rows
const clipPathStyle = css`
  ${({ isAlternate }) =>
    isAlternate
      ? `
    clip-path: polygon(
      10px 0,
      100% 0,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0 100%,
      0 10px
    );
  `
      : `
    clip-path: polygon(
      0 0,
      calc(100% - 25px) 0,
      100% 20px,
      100% 90%,
      15px 100%,
      0 calc(100% - 15px)
    );
  `}
`;

// Container with alternating slanted corners using clip-path
const RowContainer = styled.div`
  background: linear-gradient(135deg, #bebebe 0%, #ffffff 100%); /* Grey to White Gradient */
  color: #333333;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s, background 0.3s;

  /* Apply alternating clip-path styles */
  ${clipPathStyle}

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);
    background: linear-gradient(135deg, #c0c0c0 0%, #f0f0f0 100%); /* Slightly darker on hover */
  }
`;

const RowHeader = styled.div.attrs(() => ({
  role: 'button',
  tabIndex: 0,
}))`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: transparent;
  position: relative;
  z-index: 1; /* Ensure content is above pseudo-elements */

  transition: background-color 0.3s;

  &:hover {
    // background-color: rgba(0, 0, 0, 0.05); /* Subtle hover effect */
  }

  &:focus {
    // outline: 2px solid #00d1b2;
  }
`;

const Avatar = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin-right: 12px;
  object-fit: cover;
  border: 2px solid #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const Username = styled.span`
  font-size: 0.95rem;
  font-weight: 700;
  color: #ff4800;
  cursor: pointer;
  letter-spacing: 0.09rem;
  margin-right: auto;
  transition: color 0.3s, text-shadow 0.3s;

  &:hover {
    color: #ff4800
    text-shadow: 0 0 5px #007bff;
  }
`;

const Info = styled.span`
  font-size: 0.8rem;
  margin-right: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #666666; /* Subtle color for less emphasis */
`;

const IconWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
  transition: transform 0.3s;

  ${({ isExpanded }) =>
    isExpanded &&
    css`
      animation: ${rotateIcon} 0.3s forwards;
    `}
`;

const RowContent = styled.div`
  background-color: #f9f9f9; /* Light background for expanded content */
  padding: 12px 16px;
  position: relative;
  z-index: 1;
  ${clipPathStyle} /* Apply the same clip-path as the container */
  transition: max-height 0.3s ease-in-out;
`;

const DetailsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Detail = styled.div`
  flex: 1 1 45%;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #333333;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #e0e0e0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;

  &:hover {
    background-color: #d5d5d5;
  }
`;

const DetailIcon = styled.span`
  margin-right: 6px;
  display: flex;
  align-items: center;
  color: #007bff; /* Highlighted color */
`;

const DetailText = styled.span`
  color: #333333;
`;

const ClientRow = React.memo(
  ({ client, isExpanded, onExpand, onUsernameClick, isAlternate }) => {
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onExpand(client.id);
      }
    };

    return (
      <RowContainer isAlternate={isAlternate}>
        <RowHeader
          onClick={() => onExpand(client.id)}
          onKeyPress={handleKeyPress}
          aria-expanded={isExpanded}
          aria-controls={`client-details-${client.id}`}
        >
          <Avatar src={client.avatarUrl} alt={`${client.fullName}'s avatar`} />
          <Username
            onClick={(e) => {
              e.stopPropagation();
              onUsernameClick(client);
            }}
          >
            {client.username}
          </Username>
          <Info>{client.email}</Info>
          <Badge text={client.status} color={getStatusColor(client.status)} />
          <IconWrapper isExpanded={isExpanded}>
            {isExpanded ? (
              <IoChevronUpOutline size={20} />
            ) : (
              <IoChevronDownOutline size={20} />
            )}
          </IconWrapper>
        </RowHeader>
        {isExpanded && (
          <RowContent id={`client-details-${client.id}`} isAlternate={isAlternate}>
            <DetailsGrid>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Full Name:</strong> {client.fullName}
                </DetailText>
              </Detail>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Role:</strong> {client.role}
                </DetailText>
              </Detail>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Last Login:</strong>{' '}
                  {new Date(client.accountDetails.lastLogin).toLocaleString()}
                </DetailText>
              </Detail>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Subscription:</strong> {client.accountDetails.subscriptionType} (
                  {client.accountDetails.subscriptionStatus})
                </DetailText>
              </Detail>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Total Orders:</strong> {client.totalOrders}
                </DetailText>
              </Detail>
              <Detail>
                <DetailIcon>
                  <IoChevronDownOutline size={16} />
                </DetailIcon>
                <DetailText>
                  <strong>Total Spent:</strong> ${client.totalSpent.toFixed(2)}
                </DetailText>
              </Detail>
              {/* Add more details as needed */}
            </DetailsGrid>
          </RowContent>
        )}
      </RowContainer>
    );
  }
);

// Helper function to get badge color based on status
const getStatusColor = (status) => {
  switch (status) {
    case 'Active':
      return '#28a745'; // Green
    case 'Inactive':
      return '#6c757d'; // Gray
    case 'Pending':
      return '#ffc107'; // Amber
    case 'Suspended':
      return '#dc3545'; // Red
    default:
      return '#6c757d';
  }
};

export default ClientRow;



// version above looks great.  Iterating below################################################



// // src/components/ui/ClientRow.js

// import React from 'react';
// import styled, { keyframes, css } from 'styled-components';
// import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
// import Badge from './Badge';

// // Keyframes for icon rotation
// const rotateIcon = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(180deg);
//   }
// `;

// // Helper function to get clip-path based on isAlternate and isExpanded
// const getClipPath = (isAlternate, isExpanded) => {
//   if (isExpanded) {
//     return 'polygon(0 0, 100% 0, 100% 100%, 0 100%)'; // Flat bottom
//   }
//   if (isAlternate) {
//     // Slant from right to left with bottom left corner at 45 degrees
//     return 'polygon(0 0, 100% 0, 100% 85%, 100% 100%, 45% 100%, 0 100%)';
//   } else {
//     // Slant from left to right with bottom left corner at 45 degrees
//     return 'polygon(0 0, 100% 0, 100% 100%, 100% 85%, 45% 100%, 0 100%)';
//   }
// };

// // Container with dynamic slanted corners using clip-path
// const RowContainer = styled.div`
//   background: linear-gradient(135deg, #d3d3d3 0%, #ffffff 100%); /* Grey to White Gradient */
//   color: #333333;
//   margin-bottom: 15px;
//   position: relative;
//   overflow: hidden;
//   cursor: pointer;
//   border-radius: 10px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   transition: box-shadow 0.3s, transform 0.3s, background 0.3s, clip-path 0.3s;

//   /* Apply dynamic clip-path based on props */
//   clip-path: ${({ isAlternate, isExpanded }) => getClipPath(isAlternate, isExpanded)};

//   &:hover {
//     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
//     transform: translateY(-3px);
//     background: linear-gradient(135deg, #c0c0c0 0%, #f0f0f0 100%); /* Slightly darker on hover */
//   }
// `;

// const RowHeader = styled.div.attrs(() => ({
//   role: 'button',
//   tabIndex: 0,
// }))`
//   display: flex;
//   align-items: center;
//   padding: 10px 14px; /* Reduced padding for narrower rows */
//   background-color: transparent;
//   position: relative;
//   z-index: 1; /* Ensure content is above pseudo-elements */

//   transition: background-color 0.3s;

//   &:hover {
//     background-color: rgba(0, 0, 0, 0.05); /* Subtle hover effect */
//   }

//   &:focus {
//     outline: 2px solid #00d1b2;
//   }
// `;

// const Avatar = styled.img`
//   width: 40px; /* Reduced size for narrower rows */
//   height: 40px;
//   border-radius: 10px;
//   margin-right: 10px;
//   object-fit: cover;
//   border: 2px solid #ffffff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   transition: transform 0.3s;

//   &:hover {
//     transform: scale(1.05);
//   }
// `;

// const Username = styled.span`
//   font-size: 0.9rem; /* Slightly smaller font for narrower rows */
//   font-weight: 700;
//   color: #007bff; /* Highlighted color */
//   cursor: pointer;
//   margin-right: auto;
//   transition: color 0.3s, text-shadow 0.3s;

//   &:hover {
//     color: #0056b3;
//     text-shadow: 0 0 5px #007bff;
//     text-decoration: underline;
//   }
// `;

// const Info = styled.span`
//   font-size: 0.75rem; /* Smaller font for less emphasis */
//   margin-right: 8px;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   color: #666666; /* Subtle color for less emphasis */
// `;

// const IconWrapper = styled.div`
//   margin-left: 6px; /* Reduced margin for narrower rows */
//   display: flex;
//   align-items: center;
//   transition: transform 0.3s;

//   ${({ isExpanded }) =>
//     isExpanded &&
//     css`
//       animation: ${rotateIcon} 0.3s forwards;
//     `}
// `;

// const RowContent = styled.div`
//   background-color: #f9f9f9; /* Light background for expanded content */
//   padding: 10px 14px; /* Reduced padding for narrower rows */
//   position: relative;
//   z-index: 1;
//   clip-path: ${({ isAlternate, isExpanded }) => getClipPath(isAlternate, isExpanded)}; /* Apply the same clip-path as the container */
//   transition: max-height 0.3s ease-in-out, clip-path 0.3s ease-in-out;
// `;

// const DetailsGrid = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   gap: 8px;
// `;

// const Detail = styled.div`
//   flex: 1 1 45%;
//   display: flex;
//   align-items: center;
//   font-size: 0.75rem; /* Smaller font for narrower rows */
//   color: #333333;
//   padding: 6px 8px; /* Reduced padding for narrower rows */
//   border-radius: 8px;
//   background-color: #e0e0e0;
//   box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
//   transition: background-color 0.3s;

//   &:hover {
//     background-color: #d5d5d5;
//   }
// `;

// const DetailIcon = styled.span`
//   margin-right: 4px; /* Reduced margin for narrower rows */
//   display: flex;
//   align-items: center;
//   color: #007bff; /* Highlighted color */
// `;

// const DetailText = styled.span`
//   color: #333333;
// `;

// const ClientRow = React.memo(
//   ({ client, isExpanded, onExpand, onUsernameClick, isAlternate }) => {
//     const handleKeyPress = (e) => {
//       if (e.key === 'Enter' || e.key === ' ') {
//         onExpand(client.id);
//       }
//     };

//     return (
//       <RowContainer isAlternate={isAlternate} isExpanded={isExpanded}>
//         <RowHeader
//           onClick={() => onExpand(client.id)}
//           onKeyPress={handleKeyPress}
//           aria-expanded={isExpanded}
//           aria-controls={`client-details-${client.id}`}
//         >
//           <Avatar src={client.avatarUrl} alt={`${client.fullName}'s avatar`} />
//           <Username
//             onClick={(e) => {
//               e.stopPropagation();
//               onUsernameClick(client);
//             }}
//           >
//             {client.username}
//           </Username>
//           <Info>{client.email}</Info>
//           <Badge text={client.status} color={getStatusColor(client.status)} />
//           <IconWrapper isExpanded={isExpanded}>
//             {isExpanded ? (
//               <IoChevronUpOutline size={20} />
//             ) : (
//               <IoChevronDownOutline size={20} />
//             )}
//           </IconWrapper>
//         </RowHeader>
//         {isExpanded && (
//           <RowContent id={`client-details-${client.id}`} isAlternate={isAlternate} isExpanded={isExpanded}>
//             <DetailsGrid>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Full Name:</strong> {client.fullName}
//                 </DetailText>
//               </Detail>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Role:</strong> {client.role}
//                 </DetailText>
//               </Detail>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Last Login:</strong> {new Date(client.accountDetails.lastLogin).toLocaleString()}
//                 </DetailText>
//               </Detail>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Subscription:</strong> {client.accountDetails.subscriptionType} (
//                   {client.accountDetails.subscriptionStatus})
//                 </DetailText>
//               </Detail>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Total Orders:</strong> {client.totalOrders}
//                 </DetailText>
//               </Detail>
//               <Detail>
//                 <DetailIcon>
//                   <IoChevronDownOutline size={14} />
//                 </DetailIcon>
//                 <DetailText>
//                   <strong>Total Spent:</strong> ${client.totalSpent.toFixed(2)}
//                 </DetailText>
//               </Detail>
//               {/* Add more details as needed */}
//             </DetailsGrid>
//           </RowContent>
//         )}
//       </RowContainer>
//     );
//   }
// );

// // Helper function to get badge color based on status
// const getStatusColor = (status) => {
//   switch (status) {
//     case 'Active':
//       return '#28a745'; // Green
//     case 'Inactive':
//       return '#6c757d'; // Gray
//     case 'Pending':
//       return '#ffc107'; // Amber
//     case 'Suspended':
//       return '#dc3545'; // Red
//     default:
//       return '#6c757d';
//   }
// };

// export default ClientRow;
