import React from "react";
import styled from "styled-components";

const BannerWrapper = styled.div`
  padding: 2rem;
  background: linear-gradient(90deg, #43a047, #66bb6a);
  color: white;
  border-radius: 10px;
  font-size: 1.8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`;

const WelcomeBanner = ({ userName, message }) => {
  return (
    <BannerWrapper>
      Welcome, {userName}! 🎉 {message}
    </BannerWrapper>
  );
};

export default WelcomeBanner;
