// hooks/useAuth.js
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthStatus } from '../store/features/auth/authSlice';

export const useAuth = () => {
  const dispatch = useDispatch();
  const {
    user,
    isInitializing,
    isAuthenticating,
    loading,
    otpSent,
    otpVerified
  } = useSelector((state) => state.auth);

  // Handle initial auth check only once
  useEffect(() => {
    if (isInitializing && !isAuthenticating) {
      dispatch(checkAuthStatus());
    }
  }, [dispatch, isInitializing, isAuthenticating]);

  return {
    user,
    // Only show loading for initial check or authenticated operations
    // Never show loading during auth flow
    isLoading: (isInitializing || loading) && !isAuthenticating,
    isAuthenticated: !!user,
    isAuthenticating,
    otpSent,
    otpVerified
  };
};