// src/pages/TrainerPortal.js
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../components/ui/Layout";
import WelcomeBanner from "../components/ui/WelcomeBanner";
import { FaUsers, FaClipboardList, FaChartBar, FaUserPlus, FaDumbbell, 
         FaArrowRight, FaChartLine, FaCalendar, FaClock } from "react-icons/fa";
import styled from "styled-components";

// Import trainer portal components
import MyClients from "../components/TrainerPortal/ClientList";
import ProgramBuilder from "../components/TrainerPortal/program-builder/ProgramBuilder";
import ProgramsList from "../components/TrainerPortal/program-builder/ProgramsList";
import SalesMetrics from "../components/TrainerPortal/SalesMetrics";

const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1.5rem;
  margin-bottom: 2rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const QuickStatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 1.5rem;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StatCard = styled.div`
  background: white;
  padding: 1.25rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  .stat-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #666;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #333;
  }

  .stat-trend {
    font-size: 0.875rem;
    color: ${props => props.trend === 'up' ? '#4caf50' : '#f44336'};
  }
`;

const DashboardCard = styled.div`
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .card-header {
    padding: 1.5rem;
    background: ${props => props.headerBg || '#f8f9fa'};
    color: ${props => props.headerColor || '#333'};
  }

  .card-content {
    padding: 1.5rem;
  }

  .card-footer {
    padding: 1rem 1.5rem;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #666;

    &:hover {
      color: #4caf50;
      .arrow {
        transform: translateX(4px);
      }
    }

    .arrow {
      transition: transform 0.2s;
    }
  }
`;

const UpcomingSession = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #eee;

  &:last-child {
    border-bottom: none;
  }

  .time {
    color: #666;
    font-size: 0.875rem;
  }
`;

const TrainerPortal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = { name: "John Doe" };

  const trainerLinks = [
    { path: "/trainer#clients", label: "My Clients", icon: FaUsers },
    { path: "/trainer#programs", label: "Programs", icon: FaClipboardList },
    { path: "/trainer#program-builder", label: "Program Builder", icon: FaClipboardList },
    { path: "/trainer#metrics", label: "Sales Metrics", icon: FaChartBar },
  ];

  const renderDashboard = () => {
    return (
      <div>
        <WelcomeBanner
          userName={user.name}
          message="Welcome to your Trainer Portal"
        />
        
        <QuickStatsGrid>
          <StatCard trend="up">
            <div className="stat-header">
              <FaUsers />
              Active Clients
            </div>
            <div className="stat-value">48</div>
            <div className="stat-trend">↑ 12% this month</div>
          </StatCard>

          <StatCard trend="up">
            <div className="stat-header">
              <FaChartLine />
              Monthly Revenue
            </div>
            <div className="stat-value">$5,280</div>
            <div className="stat-trend">↑ 8% vs last month</div>
          </StatCard>

          <StatCard trend="down">
            <div className="stat-header">
              <FaClock />
              Completion Rate
            </div>
            <div className="stat-value">92%</div>
            <div className="stat-trend">↓ 3% this week</div>
          </StatCard>

          <StatCard trend="up">
            <div className="stat-header">
              <FaDumbbell />
              Active Programs
            </div>
            <div className="stat-value">12</div>
            <div className="stat-trend">↑ 2 new this month</div>
          </StatCard>
        </QuickStatsGrid>

        <DashboardGrid>
          <DashboardCard 
            headerBg="#4caf50" 
            headerColor="white"
            onClick={() => navigate('/trainer#clients')}
          >
            <div className="card-header">
              <h3>Client Overview</h3>
            </div>
            <div className="card-content">
              <p>Recently Active Clients</p>
              {/* Add client avatars or list here */}
            </div>
            <div className="card-footer">
              View All Clients
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>

          <DashboardCard onClick={() => navigate('/trainer#programs')}>
            <div className="card-header">
              <h3>Today's Sessions</h3>
            </div>
            <div className="card-content">
              <UpcomingSession>
                <FaCalendar />
                <div>
                  <div>Sarah Johnson</div>
                  <div className="time">10:00 AM - Strength Training</div>
                </div>
              </UpcomingSession>
              <UpcomingSession>
                <FaCalendar />
                <div>
                  <div>Mike Thompson</div>
                  <div className="time">2:00 PM - HIIT Session</div>
                </div>
              </UpcomingSession>
            </div>
            <div className="card-footer">
              View Schedule
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>

          <DashboardCard 
            headerBg="#2196f3" 
            headerColor="white"
            onClick={() => navigate('/trainer#metrics')}
          >
            <div className="card-header">
              <h3>Performance Metrics</h3>
            </div>
            <div className="card-content">
              {/* Add mini chart or key metrics here */}
            </div>
            <div className="card-footer">
              View Detailed Metrics
              <FaArrowRight className="arrow" />
            </div>
          </DashboardCard>
        </DashboardGrid>
      </div>
    );
  };

  const renderTrainerContent = () => {
    const hash = location.hash.replace('#', '');
    
    switch (hash) {
      case 'clients':
        return <MyClients />;
      case 'program-builder':
        return <ProgramBuilder />;
      case 'metrics':
        return <SalesMetrics />;
      default:
        return renderDashboard();
    }
  };

  return (
    <Layout user={user} links={trainerLinks}>
      {renderTrainerContent()}
    </Layout>
  );
};

export default TrainerPortal;


// ChatGPT Version:##########################################################

