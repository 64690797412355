// src/components/programs/phases/PhaseCard.js
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Card = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: ${props => props.isCurrent ? 
    'linear-gradient(135deg, #2c5282, #1a365d)' : 
    'linear-gradient(135deg, #2d3748, #1a202c)'};
`;

const Content = styled.div`
  padding: 20px;
  color: white;
  position: relative;
  z-index: 2;
`;

const PhaseNumber = styled.div`
  font-size: 48px;
  font-weight: bold;
  opacity: 0.5;
`;

const PhaseTitle = styled.h3`
  margin: 10px 0;
  font-size: 24px;
`;

const StatusBadge = styled.div`
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  background: ${props => {
    switch (props.status) {
      case 'in_progress': return '#48BB78';
      case 'completed': return '#4299E1';
      case 'paused': return '#F6AD55';
      default: return '#718096';
    }
  }};
  display: inline-block;
`;

const PhaseCard = ({ phase, onClick }) => {
  return (
    <Card isCurrent={phase.isCurrent} onClick={onClick}>
      <Content>
        <PhaseNumber>{String(phase.phaseNumber).padStart(2, '0')}</PhaseNumber>
        <PhaseTitle>{phase.name}</PhaseTitle>
        <StatusBadge status={phase.status}>
          {phase.isCurrent ? 'CURRENT' : phase.status.toUpperCase()}
        </StatusBadge>
      </Content>
    </Card>
  );
};

PhaseCard.propTypes = {
  phase: PropTypes.shape({
    id: PropTypes.string.isRequired,
    phaseNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['pending', 'in_progress', 'completed', 'paused']).isRequired,
    isCurrent: PropTypes.bool.isRequired
  }).isRequired,
  onClick: PropTypes.func
};

export default PhaseCard;