// store/features/auth/authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // withCredentials: true 
});

// Updated request interceptor
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  // Add CORS headers for development
  if (process.env.NODE_ENV === 'development') {
    config.headers['Access-Control-Allow-Credentials'] = true;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Enhanced response interceptor
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 429) {
      return Promise.reject(new Error('Please wait before trying again'));
    }

    if (error.response?.status === 401) {
      localStorage.removeItem('token');

    }

    // Handle CORS errors specifically
    if (error.message.includes('Network Error')) {
      console.error('CORS or Network Error:', error);
      return Promise.reject(new Error('Unable to connect to the server. Please try again later.'));
    }

    return Promise.reject(
      error.response?.data?.message ||
      error.message ||
      'An unexpected error occurred'
    );
  }
);

const cleanUserForRedux = (user) => {
  if (!user) return null;

  return {
    id: user.id,
    email: user.email,
    username: user.username,
    membershipTier: user.membershipTier,
    photoURL: user.profilePicture,
    avatarUrl: user.profilePicture,
  };
};

export const sendOTP = createAsyncThunk(
  "auth/sendOTP",
  async (email, { rejectWithValue, getState }) => {
    console.log('Auth State Before OTP:', getState().auth);
    try {
      const response = await api.post('/api/auth/send-otp', { email });
      console.log('OTP Response:', response.data);
      console.log('Auth State After OTP:', getState().auth);
      return {
        email,
        success: true,
        expiresAt: response.data.expiresAt
      };
    } catch (error) {
      console.error('OTP Error:', error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "auth/verifyOTP",
  async ({ email, code }, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/auth/verify-otp', {
        email,
        code // Updated to match backend DTO
      });

      return { success: true, verified: response.data.verified };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Verification failed");
    }
  }
);

export const checkUsername = createAsyncThunk(
  "auth/checkUsername",
  async (username, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/auth/check-username', { username });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || "Error checking username");
    }
  }
);



export const signupUser = createAsyncThunk(
  "auth/signup",
  async ({ email, password, username, verificationCode }, { rejectWithValue }) => {
    try {
      console.log('Starting signup request...');
      const response = await api.post('/api/auth/register', {
        email,
        password,
        username,
        verificationCode,
        provider: 'local'
      });

      console.log('Signup response:', response.data);  // Check the response
      console.log('Token received:', response.data.token); // Verify token exists

      localStorage.setItem('token', response.data.token);
      console.log('Token stored in localStorage:', localStorage.getItem('token')); // Verify storage

      const cleanedUser = cleanUserForRedux(response.data.user);
      console.log('Cleaned user data:', cleanedUser); // Check cleaned user data

      return cleanedUser;
    } catch (error) {
      console.error('Signup error:', error);
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);



export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await api.post('/api/auth/login', { email, password });
      console.log('Login response:', response.data); // Debug log

      if (!response.data.token) {
        throw new Error('No token received from server');
      }

      // Store token
      localStorage.setItem('token', response.data.token);

      return {
        user: response.data.user,
        token: response.data.token,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message ||
        error.message ||
        "Login failed"
      );
    }
  }
);




export const checkAuthStatus = createAsyncThunk(
  "auth/checkStatus",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return null;
      }

      const response = await api.get('/api/auth/profile');
      return cleanUserForRedux(response.data);
    } catch (error) {
      // Only remove token if it's an auth error
      if (error.response?.status === 401) {
        localStorage.removeItem('token');
      }
      return rejectWithValue(error.message);
    }
  }
);


// #############################################

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, code, newPassword }, { rejectWithValue }) => {
    try {
      // Debug log the exact payload
      console.log('Reset password request payload:', {
        email,
        verificationCode: code,
        newPassword
      });

      const response = await api.post('/api/auth/password/reset', {
        email,
        verificationCode: code,
        newPassword
      });

      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Reset password error details:', {
        response: error.response?.data,
        status: error.response?.status,
        message: error.message
      });
      return rejectWithValue(
        error.response?.data?.message || 
        error.message || 
        'Failed to reset password'
      );
    }
  }
);




const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isInitializing: true,
    isAuthenticating: false,
    loading: false,
    error: null,
    otpSent: false,
    otpVerified: false,
    usernameAvailable: null,
    otpExpiresAt: null,

  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    setUser: (state, action) => {
      console.log('setUser reducer called with:', action.payload); // Debug log
      state.user = action.payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.error = null;
    },
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.otpSent = false;
      state.otpVerified = false;
      state.usernameAvailable = null;
      state.otpExpiresAt = null;
      localStorage.removeItem('token');
    },
    resetUsernameCheck: (state) => {
      state.usernameAvailable = null;
    },
    resetState: (state) => {
      state.error = null;
      state.loading = false;
      state.otpSent = false;
      state.otpVerified = false;
      state.usernameAvailable = null;
    },
    startAuthFlow: (state) => {
      state.isAuthenticating = true;
      state.isInitializing = false;
    },
    endAuthFlow: (state) => {
      state.isAuthenticating = false;
    }
  },
  extraReducers: (builder) => {
    // Send OTP
    builder
      .addCase(sendOTP.pending, (state) => {
        state.isAuthenticating = true;
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.otpSent = true;
        state.otpExpiresAt = action.payload.expiresAt;
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Verify OTP
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOTP.fulfilled, (state) => {
        state.loading = false;
        state.otpVerified = true;
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });


    builder
      .addCase(checkUsername.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkUsername.fulfilled, (state, action) => {
        state.loading = false;
        state.usernameAvailable = action.payload.available;
        state.error = null;
      })
      .addCase(checkUsername.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.usernameAvailable = null;
      });

    // Signup
    builder
      .addCase(signupUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signupUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isAuthenticated = true;  // This should be set based on user presence
        state.loading = false;
        state.error = null;
      })
      .addCase(signupUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Login
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = cleanUserForRedux(action.payload.user);
        state.isAuthenticated = true;
        state.loading = false;
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(checkAuthStatus.pending, (state) => {
        state.isInitializing = true;
      })
      .addCase(checkAuthStatus.fulfilled, (state, action) => {
        state.isInitializing = false;
        state.user = action.payload;
      })
      .addCase(checkAuthStatus.rejected, (state) => {
        state.isInitializing = false;
      });

    builder
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.loading = false;
        // If the API returns user data and token, update the auth state
        if (action.payload.user) {
          state.user = cleanUserForRedux(action.payload.user);
          state.isAuthenticated = true;
        }
        state.otpVerified = false; // Reset OTP state
        state.otpSent = false;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

  },
});

export const {
  clearError,
  setUser,
  logout,
  resetUsernameCheck, // Add these new actions
  resetState
} = authSlice.actions;
export default authSlice.reducer;