import React from 'react';
import styled from 'styled-components';
import { FaChevronLeft, FaChevronRight, FaBars } from 'react-icons/fa';

const CollapseButtonWrapper = styled.button`
  position: relative;
  top: 15px;
  background: linear-gradient(135deg, #5b76a0 0%, #1a1a1a 100%);
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 1000;
  margin-left: -20px;

  &:hover {
    background-color: #0056b3;
  }

  svg {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 1rem;
    left: ${props => props.collapsed ? "1rem" : "calc(77% - 3rem)"};
    margin-left: 0;
  }
`;

const CollapseButton = ({ collapsed, onClick }) => {
  const isMobile = window.innerWidth <= 768;

  return (
    <CollapseButtonWrapper onClick={onClick} collapsed={collapsed}>
      {isMobile && collapsed ? (
        <FaBars />
      ) : (
        collapsed ? <FaChevronRight /> : <FaChevronLeft />
      )}
    </CollapseButtonWrapper>
  );
};

export default CollapseButton;