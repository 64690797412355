export const EXERCISE_TYPES = {
    SINGLE: 'single',
    SUPERSET: 'superset',
    CIRCUIT: 'circuit',
    PYRAMID: 'pyramid',
    DROP_SET: 'drop_set'
  };
  
  export const EXERCISE_MODES = {
    WEIGHTS: 'weights',
    CARDIO: 'cardio',
    CALISTHENICS: 'calisthenics'
  };
  
  export const exerciseData = {
    exercises: [
      {
        id: '1',
        name: 'Barbell Back Squat',
        mode: EXERCISE_MODES.WEIGHTS,
        category: 'legs',
        defaultAttributes: {
          weightUnit: 'lbs',
          repRangeUpper: 12,
          repRangeLower: 8
        }
      },
      {
        id: '2',
        name: 'Treadmill Run',
        mode: EXERCISE_MODES.CARDIO,
        category: 'cardio',
        defaultAttributes: {
          distanceUnit: 'miles',
          intensityRange: {
            lower: 60,
            upper: 80
          }
        }
      },
      {
        id: '3',
        name: 'Push-ups',
        mode: EXERCISE_MODES.CALISTHENICS,
        category: 'chest',
        defaultAttributes: {
          repRangeUpper: 20,
          repRangeLower: 10
        }
      }
    ],
  
    modeAttributes: {
      [EXERCISE_MODES.WEIGHTS]: {
        required: ['sets', 'reps', 'weight'],
        optional: ['tempo', 'restPeriod'],
        defaults: {
          sets: 3,
          weightUnit: 'lbs',
          restPeriod: 90 // seconds
        }
      },
      [EXERCISE_MODES.CARDIO]: {
        required: ['duration', 'intensity'],
        optional: ['distance', 'incline', 'resistance'],
        defaults: {
          sets: 1,
          intensityUnit: 'percentage',
          durationUnit: 'minutes'
        }
      },
      [EXERCISE_MODES.CALISTHENICS]: {
        required: ['sets', 'reps'],
        optional: ['duration', 'intensity'],
        defaults: {
          sets: 3,
          restPeriod: 60 // seconds
        }
      }
    },
  
    typeConfigurations: {
      [EXERCISE_TYPES.SINGLE]: {
        maxExercises: 1,
        allowedModes: [EXERCISE_MODES.WEIGHTS, EXERCISE_MODES.CARDIO, EXERCISE_MODES.CALISTHENICS]
      },
      [EXERCISE_TYPES.SUPERSET]: {
        maxExercises: 3,
        allowedModes: [EXERCISE_MODES.WEIGHTS, EXERCISE_MODES.CALISTHENICS],
        restPeriodBetweenExercises: 0,
        restPeriodBetweenRounds: 90
      },
      [EXERCISE_TYPES.CIRCUIT]: {
        maxExercises: 8,
        allowedModes: [EXERCISE_MODES.WEIGHTS, EXERCISE_MODES.CARDIO, EXERCISE_MODES.CALISTHENICS],
        restPeriodBetweenExercises: 30,
        restPeriodBetweenRounds: 120
      },
      [EXERCISE_TYPES.PYRAMID]: {
        maxExercises: 1,
        allowedModes: [EXERCISE_MODES.WEIGHTS],
        incrementType: ['weight', 'reps'],
        direction: ['ascending', 'descending']
      },
      [EXERCISE_TYPES.DROP_SET]: {
        maxExercises: 1,
        allowedModes: [EXERCISE_MODES.WEIGHTS],
        decrementPercentage: 20,
        maxDrops: 3
      }
    },
  
    workoutTemplates: [
      {
        id: '1',
        name: 'Basic Strength Workout',
        exercises: [
          {
            type: EXERCISE_TYPES.SINGLE,
            exerciseId: '1', // Barbell Back Squat
            sets: 3,
            attributes: {
              reps: 8,
              weight: 225,
              restPeriod: 120
            }
          }
        ]
      }
    ]
  };
  
  export const getExercisesByMode = (mode) => {
    return exerciseData.exercises.filter(exercise => exercise.mode === mode);
  };
  
  export const getExerciseById = (id) => {
    return exerciseData.exercises.find(exercise => exercise.id === id);
  };
  
  export const getModeAttributes = (mode) => {
    return exerciseData.modeAttributes[mode];
  };
  
  export const getTypeConfiguration = (type) => {
    return exerciseData.typeConfigurations[type];
  };