// App.js
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthStateListener } from "./components/auth/AuthStateListener";
import AppRoutes from "./components/AppRoutes";

const App = React.memo(() => {
  return (
    <Router>
      <AuthStateListener />
      <AppRoutes />
    </Router>
  );
});

export default App;
